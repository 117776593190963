import {ACTIVE_COMPANY, ACTIVE_PRODUCT, CLEAR_ROUTES, UPDATE_ROUTES} from "../actions/actionTypes";
import {isArray} from "pdfmake/src/helpers";

const INITIAL_STATE = {
    routes: [],
    activeProduct: '',
    activeCompany: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ROUTES:
            return Object.assign({}, state, {
                routes: isArray(action.payload) ? [...state.routes, ...action.payload] : [...state.routes, action.payload]
            });
        case CLEAR_ROUTES:
            return Object.assign({}, state, {
                routes: []
            });
        case ACTIVE_PRODUCT:
            return Object.assign({}, state, {
                activeProduct: action.payload
            });
        case ACTIVE_COMPANY:
            return Object.assign({}, state, {
                activeCompany: action.payload
            })
        default:
            return state;
    }
}
