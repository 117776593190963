import React from 'react';

export default class MapMarkers extends React.Component {
    render() {
        return (
            <div style={greatPlaceStyle} onClick={() => this.props.onClickMarker(this.props.companyId)}>
                {this.props.text}

                <img
                    style={{
                        position: "relative",
                        top: "0",
                        left: "0",
                        height: "48px"
                    }}
                    src={require("./../assets/icons/marker.svg")}
                    alt="..."
                />
            </div>
        );
    }
}

const K_WIDTH = 48;
const K_HEIGHT = 48;

const greatPlaceStyle = {
    // initially any map object has left top corner at lat lng coordinates
    // it's on you to set object origin to 0,0 coordinates
    position: 'absolute',
    // width: K_WIDTH,
    // height: K_HEIGHT,
    width: -K_WIDTH,
    height: -K_WIDTH,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    borderRadius: K_HEIGHT,
    backgroundColor: 'transparent',
    backgroundImage: "../assets/icons/marker.svg",
    backgroundSize: "contain",
    textAlign: 'center',
    color: '#3f51b5',
    fontWeight: 'bold',
    padding: 0
};
