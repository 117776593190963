import React from "react";
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {EditorState} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML}  from 'draft-js-export-html';
import {stateFromHTML} from "draft-js-import-html";
import {userLevelColors} from "../../services/globalFunctions";

class CompanyProfileDescriptionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editorState: EditorState.createEmpty()};
        this.onChangeEditor = this.onChangeEditor.bind(this);
    }

    onChangeEditor(editorState) {
        this.setState({editorState}, () => {
            let htmlDesc = stateToHTML(editorState.getCurrentContent());
            this.props.onChange(htmlDesc);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal !== prevProps.showModal && this.props.showModal) {
            const stateFromH = stateFromHTML(this.props.description);
            this.setState({editorState: EditorState.createWithContent(stateFromH)});
        }
    }

    render() {
        const options = {
            options: ['inline', 'blockType', 'list', 'history'],
            inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
            }
        };
        return (
            <Modal show={this.props.showModal} size={"lg"} onHide={this.props.onHide}>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>Company profile</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="description" style={{width: "100%", maxWidth: "100%", marginBottom: "30px"}}>
                            <p style={{marginBottom: "3px", fontSize: "16px", fontWeight: "bold", textAlign: "center"}}>What is important for the other members to know about your company?</p>
                            <p style={{maxWidth: "65%", fontSize: "14px", textAlign: "center", lineHeight: "20px", margin: "0 auto"}}>What about your history, markets, vision, mission or philosophy?<br />Here is the space to share these information with your fellow network partners.</p>
                        </label>
                        <Editor
                            editorState={this.state.editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onChangeEditor}
                            toolbar={options}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide} style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={this.props.onSubmit} style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}>
                        Save Description
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfileDescriptionModal);
