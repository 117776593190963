import React from "react";

export const DemoStep1 = (props) => {
    return (
        <div className="DemoStep1 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/step_1.png')} style={{height: '450px'}} alt={'...'} />
            <p className="mt-4">To get started, click on the "Edit Profile" button to start building your profile on the Worldring Marketplace.</p>
        </div>
    )
};
