import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react16-async-script-loader";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { base_url } from "../../services/const";
import customHistory from "../../services/history";
import { updateUserLevel } from "../../store/actions/loginAction";
import { connect } from "react-redux";
import PaypalModalNotification from "./PaypalModalNotification";
import { Link } from "react-router-dom";
import { urls } from "../../services/router/utils/urls";
import { levelByTitle } from "../../services/globalFunctions";
import { isResponseError } from "../../utils/axios";

const CLIENT = {
  sandbox:
      "AZCRDZES7sQ0TA_1NQb3nBuHbyY3u9yCGym5SNkzja0On0qDV3WNtBNut8ff2A6aOL65HNDKHeUzCdso",
  production:
      "AbqjhA6PIdwKGS3o3O7qL5QRep29udiJ3c6DRw02_eOw1cj4-_gZIRVWlJEpRD7QVlNlxPKVACsbfLvb",
};

const CLIENT_ID = CLIENT.production;

let PayPalButton = null;

class PayPalBtn extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      showModal: false,
      showOverlay: true,
      checkboxOverlay: true,
      checkboxVal: false,
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
    this.onClickOverlay = this.onClickOverlay.bind(this);
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
        this.props.level !== prevProps.level &&
        this.props.level === this.props.description
    ) {
      customHistory.push("/company-profile/user/" + this.props.companyIds[0]);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
        !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM,
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }

  // createOrder(data, actions) {
  //     return actions.order.create({
  //         purchase_units: [
  //             {
  //                 description: +this.props.description,
  //                 amount: {
  //                     currency_code: "EUR",
  //                     value: this.props.amount
  //                 }
  //             }
  //         ]
  //     });
  // };

  onApprove(data, actions) {
    actions.order.capture().then((details) => {
      const paymentData = {
        "payer_id": data.payerID,
        "order_id":  data.orderID,
        "user_level_id": levelByTitle(this.props.description),
        "is_payment": true,
        "payment_type": 'paypal',
      }

      axios.put(`users/${this.props.userId}`,paymentData)
          .then((ret) => {
            if (!isResponseError(ret,false)) {
              NotificationManager.success("Payment Successful", "Success");
              let userUpgrade = {
                fname: this.props.fName,
                lname: this.props.lName,
                level_id: this.props.description,
                firstLogin: true,
              };
              this.props.updateUserLevel(userUpgrade);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      this.setState({ showButtons: false, paid: true });
    });
  }

  onError(data, action) {
    console.log(data, action);
  }

  onCancel() {
    NotificationManager.error("Payment cancelled", "Error");
  }

  createSubscription(data, actions) {
    return actions.subscription.create({
      plan_id: `${this.props.amount}`,
    });
  }

  onClickOverlay(e) {
    this.formRef.current.reportValidity();
  }

  render() {
    console.log(this.state.checkboxVal);
    return (
        <div className="main position-relative">
          <div
              onClick={() => this.setState({ showModal: true, showOverlay: false })}
              className={
                  "overlay-paypal-btn position-absolute w-100 " +
                  (this.state.showOverlay ? "" : "d-none")
              }
              style={{ zIndex: 998, height: "130px" }}
          ></div>
          <div
              onClick={this.onClickOverlay}
              className={
                  "overlay-paypal-btn position-absolute w-100 " +
                  (this.state.checkboxOverlay ? "" : "d-none")
              }
              style={{ zIndex: 999, height: "130px" }}
          ></div>
          {this.props.isScriptLoaded ? (
              <PayPalButton
                  // createOrder={(data, actions) => this.createOrder(data, actions)}
                  onApprove={(data, actions) => this.onApprove(data, actions)}
                  onError={(data, actions) => console.log(data, actions)}
                  onCancel={() => this.onCancel()}
                  createSubscription={(data, actions) =>
                      this.createSubscription(data, actions)
                  }
              />
          ) : (
              void 0
          )}
          <form ref={this.formRef}>
            <div className="form-check">
              <input
                  required={true}
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  onClick={(e) =>
                      e.target.checked
                          ? this.setState({
                            checkboxOverlay: false,
                            checkboxVal: e.target.checked,
                          })
                          : this.setState({
                            checkboxVal: e.target.checked,
                            checkboxOverlay: true,
                          })
                  }
              />
              <label className="form-check-label" htmlFor="exampleCheck1">
                You hereby agree to our{" "}
                <Link to={urls.membership.agreement}>Terms</Link> and that you
                have read our <Link to={urls.privacyPolicy}>privacy policy</Link>,
                including our Cookie use.
              </label>
            </div>
          </form>
          <PaypalModalNotification
              level={this.props.level}
              show={this.state.showModal}
              onHide={() => this.setState({ showModal: false })}
          />
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  let userRole = state.login.group;
  let token = state.login.token;
  let agContact = state.login.ag_contact;
  let level = state.login.level;
  let companyIds = state.login.company_ids;
  let fName = state.login.fname;
  let lName = state.login.lname;
  let userId = state.login.user_id;

  return {
    userId,
    userRole,
    token,
    agContact,
    level,
    companyIds,
    fName,
    lName,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserLevel: (userLevel) => dispatch(updateUserLevel(userLevel)),
});

export default scriptLoader(
    `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=EUR&vault=true`
)(connect(mapStateToProps, mapDispatchToProps)(PayPalBtn));
