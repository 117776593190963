import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import Routes from "./services/router";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./scss/main.scss";
import { NotificationContainer } from "react-notifications";
import { AppProvider } from "./components/Providers/AppProvider";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import EchoListener from "./EchoListener";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticateUser,
  logoutUser,
  updateMatchesCount,
} from "./store/actions/loginAction";
import axios from "axios";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./components/Providers/queryClient";
import { isResponseError } from "./utils/axios";
import { haveHsCode } from "./store/actions/hsCodeAction";
import { useHistory } from "react-router";
import { checkLocalStorage } from "./services/globalFunctions";

const isSecure = window.location.protocol === "https:";

window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "wrl",
  cluster: "mt1",
  encrypted: false,
  forceTLS: isSecure,
  wsHost: process.env.REACT_APP_WS_HOST,
  wsPort: isSecure ? undefined : process.env.REACT_APP_WS_PORT,
  wssPort: isSecure ? process.env.REACT_APP_WS_PORT : undefined,
  enabledTransports: isSecure ? ["wss", "ws"] : ["ws"],
  disableStats: true,
});

console.log("Echo configuration:", {
  forceTLS: isSecure,
  wsHost: process.env.REACT_APP_WS_HOST,
  wsPort: isSecure ? undefined : process.env.REACT_APP_WS_PORT,
  wssPort: isSecure ? process.env.REACT_APP_WS_PORT : undefined,
  enabledTransports: isSecure ? ["wss", "ws"] : ["ws"],
});

const App = () => {
  const dispatch = useDispatch();
  const testUser = useSelector((state) => state.login);
  const history = useHistory();
  const [isFinal, setIsFinal] = useState(false);

  const isLoggedInLocalStorage = checkLocalStorage();

  axios.interceptors.response.use((response) => {
    if (
      response.data.error === "Unauthenticated." &&
      (!response.config.url.includes("/app/me") || isLoggedInLocalStorage)
    ) {
      console.log("TEST");
      localStorage.clear();
      window.location.href = "/";
    }
    return response;
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const myInfo = await axios.get("/api/v1/app/me");
        const isError = isResponseError(myInfo, true);
        if (!isError) {
          axios.defaults.baseURL = `/api/v1/app/${myInfo.data.role.code}`;

          const myCommodities = await axios.get("/commodities?my=true");
          if (!isResponseError(myCommodities, false)) {
            dispatch(haveHsCode(!!myCommodities.data.data.length));
          }
          dispatch(authenticateUser(myInfo.data));

          await axios
            .get("/matches", {
              params: { is_customer_seen: false, per_page: -1 },
            })
            .then((res) => {
              if (!isResponseError(res)) {
                dispatch(updateMatchesCount(res.data.total));
              }
            });

          setIsFinal(true);
        } else if (myInfo.data.error === "Unauthenticated.") {
          sessionStorage.setItem("wp-token", "");
          sessionStorage.setItem("wp-email", "");
          setIsFinal(true);
          //logoutUser();
          axios.defaults.baseURL = "/";
          //window.location.reload();
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [dispatch, history]);

  if (!isFinal) {
    return <span>Loading...</span>;
  }
 console.log('env:',process.env);
 console.log('REACT_APP_BACKEND_URL:',process.env.REACT_APP_BACKEND_URL);
  return (
    <div>
      {Number.isFinite(+testUser.user_id) ? (
        <EchoListener userId={testUser.user_id} />
      ) : null}
      <Routes />
      <NotificationContainer />
    </div>
  );
};

ReactDOM.render(
  <AppProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </AppProvider>,
  document.getElementById("root")
);
// ReactDOM.render(
//   <AppProvider>
//     <Routes />
//     <NotificationContainer />
//   </AppProvider>,
//   document.getElementById("root")
// );

serviceWorker.unregister();
