import React from "react";
import {connect} from "react-redux";
import SalesPage from "./SalesPage";

class TradingCompanySalesPage extends React.Component {

    render() {
        return (
            <div className="page-container MyCustomers">
                <div className={"page-header mb-5"}>
                </div>
                <div className="container-fluid">
                    <h4 className="font-weight-light mb-5">Sales List</h4>
                    <SalesPage />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;
    return {
        token, level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TradingCompanySalesPage);