import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../services/const";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-buttons-bs4";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-select";
import {
  reorderAdminPage,
  translateData,
  userLevelColors,
} from "../services/globalFunctions";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
require("pdfmake/build/pdfmake.js");
require("../assets/fonts/vfs_fonts.js");

class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      tableJs: null,
    };
    this.fetchData = this.fetchData.bind(this);
    this.initializeDT = this.initializeDT.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .request({
        method: "get",
        url: `${base_url}/admin/productlist/all/all/all`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.status === 200) {
          if (ret.data.data.length > 0) {
            let data = ret.data.data;
            let sortedData = reorderAdminPage(data);
            this.initializeDT(sortedData);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  initializeDT(ret) {
    let cols = ["ID"];
    let data = [];
    let arr = Array.from(new Set(ret.map(JSON.stringify))).map(JSON.parse);
    arr.map((item, j) => {
      let dArr = [];
      dArr.push(j);
      for (let i in item) {
        if (moment(item[i], "YYYY-MM-DD HH:mm:ss", true).isValid()) {
          let date = moment(item[i]).format("DD/MM/YYYY");
          dArr.push(date);
        } else {
          dArr.push(translateData(item[i]));
        }
      }
      data.push(dArr);
    });
    for (let i in arr[0]) {
      let k = i.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
        return str.toUpperCase();
      });
      if (k === "Name") {
        k = "Customer";
      }
      cols.push(k);
    }
    this.setState({ cols: cols });
    let level = this.props.level;
    $(document).ready(function () {
      let table = $("#table_id").DataTable({
        data: data,
        dom: "Bfrtip",
        select: {
          style: "multi",
        },
        buttons: [
          "copyHtml5",
          "csvHtml5",
          {
            extend: "pdfHtml5",
            orientation: "landscape",
          },
        ],
        columnDefs: [
          {
            targets: [0],
            visible: false,
            searchable: false,
          },
          { targets: 4, type: "date-euro" },
        ],
      });
      $("#table_id_wrapper input").addClass(userLevelColors(level).className);
      $("#table_id_wrapper label").css("display", "inline-flex");
      $("#table_id_filter label").css("display", "none");
      $(".dt-buttons").addClass("mb-3");
      $("#tableSearch").keyup(function (e) {
        table.search($(this).val()).draw();
      });
    });
  }

  render() {
    return (
      <div className="page-container AdminPageContainer">
        <div className="page-header"></div>
        <div className="container-fluid mt-5 position-relative">
          <div
            className="col-lg-4 position-absolute"
            style={{ right: 0, zIndex: 1 }}
          >
            <div className="form-group w-100 position-relative">
              <input
                id="tableSearch"
                type="text"
                className={
                  "form-control nav-search border-top-0 border-right-0 border-left-0 " +
                  userLevelColors(this.props.level).className
                }
                placeholder="Search for active offers/searches..."
              />
              <button
                style={{ top: "5px", right: 0 }}
                type="button"
                className="navbar-search-btn bg-transparent border-0 position-absolute"
              >
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>

          <table id="table_id" className="table table-striped table-bordered">
            <thead>
              <tr>
                {this.state.cols.map((item, i) => {
                  return <th key={i}>{item}</th>;
                })}
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
