import React from "react";

export const DemoStep2 = (props) => {
    return (
        <div className="DemoStep2 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/step_2.png')} style={{height: '450px'}} alt={'...'} />
            <p className="mt-4">To edit your Company Profile data, including your logo, location, contact address and personal contact - click on "Edit Profile" in the right sidebar.</p>
        </div>
    )
};
