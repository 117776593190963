import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../../services/globalFunctions";
import {connect} from "react-redux";
import StepWizard from 'react-step-wizard';
import {DemoStep1} from "./Step1";
import {DemoStep2} from "./Step2";
import {DemoStep3} from "./Step3";
import {DemoStep4} from "./Step4";
import {DemoStep5} from "./Step5";
import {DemoStep6} from "./Step6";

class WelcomeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            wizardInstance: null,
            steps: [1,2,3,4,5, 6]
        };
        this.wizardInstance = this.wizardInstance.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.showButton = this.showButton.bind(this);
    }

    wizardInstance(e) {
        this.setState({wizardInstance: e});
    }

    onClickNext() {
        let i = this.state.activeStep+1;
        i <= 6 ? this.setState({activeStep:i}) : void 0;
        this.state.wizardInstance.nextStep();
    }

    onClickPrevious() {
        let i = this.state.activeStep-1;
        i > 0 ? this.setState({activeStep:i}) : void 0;
        this.state.wizardInstance.previousStep();
    }

    showButton() {
        if(this.state.activeStep === 6) {
            return <Button className="btn-wlr" onClick={this.props.onHide} style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}>Get started</Button>
        } else {
            return <div><Button style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} className={this.state.activeStep > 1 ? "btn-wlr" : "d-none"} variant="dark"  onClick={this.onClickPrevious}>
                Previous
            </Button>
                <Button variant="primary" style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} className={"ml-2 btn-wlr"} onClick={this.onClickNext}>
                    Next
                </Button>
                <Button variant="secondary" className={"ml-2"} onClick={this.props.onHide}>
                    Skip
                </Button></div>
        }
    }

    render() {
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className='HeroImageModal WelcomeModal'>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>Welcome to WorldRing</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="overflow-hidden">
                        <StepWizard initialStep={1} instance={this.wizardInstance}>
                            <DemoStep1 />
                            <DemoStep2/>
                            <DemoStep3/>
                            <DemoStep4/>
                            <DemoStep5/>
                            <DemoStep6/>
                        </StepWizard>
                    </div>
                    <div className="pagination">
                        {
                            this.state.steps.map((number) => {
                                return <li
                                    key={number}
                                    className={'page-item ' + (this.state.activeStep === number ? 'active' : '')}
                                >
                                    <a
                                        className={
                                            'page-link columns ' + (this.state.activeStep === number ? 'active' : '')
                                        }
                                        // onClick={() => this.onChangePageNumber(number)}
                                    >
                                        {number}
                                    </a>
                                </li>
                            })
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {this.showButton()}
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeModal);

