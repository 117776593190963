import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";

export const useGetCompanies = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  status,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(`users`, {
          params: {
            //PAGINATION
            per_page: perPage,
            page,

            //FILTERS
            search,
            user_level_id: userLevel ? [userLevel] : "",

            //REQUEST
            my_related: true,
            is_register: 1,
            role_id: 3,
            order: sortList,
            columns_search: ["name", "email", "phone"],
          },
        });

        const formattedData = data.data.map((customer) => {
          const isCompanies = !!customer.companies.length;
          const isAddresses =
            isCompanies && !!customer.companies[0].addresses.length;
          return {
            id: customer.id,
            company_name: customer.companies.length
              ? customer.companies[0].name
              : "-",
            country: isAddresses
              ? customer.companies[0].addresses[0].country_name
              : "-",

            city: isAddresses
              ? customer.companies[0].addresses[0].city_name || "-"
              : "-",

            user_name: customer.name,
            aic: customer.inviter.companies[0].name.replace("&#038;", "&"),
            email: customer.email || "-",
            phone: customer.phone || "-",
            user_level: customer.user_level.name,
            created_at: formatDateToDDMMYYYY(customer.created_at),
            expire_at: "-",
            sent_at: "-",
            status: "-",
            expireTime: "-",
          };
        });

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    status,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total | 0 };
};
