import React from "react";
import WelcomeVideoModal from "../../../components/Jumpseat/WelcomeVideoModal";

class Step1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            welcomeModal: false
        }
    }

    render() {
        return (
            <div>
                <h2 className="font-weight-light">Welcome to <strong>WorldRing:</strong></h2>
                <h4 className="font-weight-light my-3">linking people, business and opportunities globally.</h4>
                <p className="text-left">Congratulations and thanks for your interest in becoming a part of the number one network for international business relations and business development. You have been recommended by one of our members to enjoy the <u>free of charge</u> jumpseat membership option.
                    <br />
                    In order to activate your <u>free of charge</u> jumpseat option and find international contacts for your need, please help us refine our offering by answering a few questions.
                </p>
                <input type="button" className="btn btn-dark mt-5" value="Start" onClick={this.props.onClickNext.bind(null, 1)} />
                <p className="mt-4" onClick={() => this.setState({welcomeModal: true})} style={{textDecoration: 'underline', marginTop: '10px', cursor: 'pointer'}}>View intro video</p>
                <WelcomeVideoModal show={this.state.welcomeModal} onHide={() => this.setState({welcomeModal: false})} />
            </div>
        );
    }
}

export default Step1;
