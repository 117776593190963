import React from "react";
import classes from "./AgentInfoModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMailBulk,
  faMobile,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { avatarFallback } from "../../assets/icons";

export const AgentInfoModal = ({
  agentInfo,
  closeModal,
  field = "founders",
}) => {
  console.log("agentInfo", agentInfo);

  const isHaveFounders = agentInfo[field] && agentInfo[field].length > 0;

  const founder = isHaveFounders && agentInfo[field][0];

  const founderLogo = isHaveFounders
    ? founder.wp_logo || avatarFallback
    : avatarFallback;
  return (
    <div className={classes.wrapper} onClick={closeModal}>
      <div className={classes.content} onClick={(e) => e.stopPropagation()}>
        <div
          className={classes.userImg}
          style={{
            backgroundImage: `url(${founderLogo})`,
          }}
        />

        <div className={classes.userInfo}>
          <h1>
            {isHaveFounders
              ? `${founder.first_name || ""} ${founder.last_name || ""}`
              : "-"}
          </h1>
          {isHaveFounders && founder.job_title && (
            <h2> {isHaveFounders ? founder.job_title || "-" : "-"}</h2>
          )}

          <div className={classes.contact}>
            {isHaveFounders && founder.phone && (
              <>
                <FontAwesomeIcon style={{ marginTop: "5px" }} icon={faPhone} />
                <a href={`tel:${founder.phone}`}>{founder.phone}</a>
              </>
            )}

            {isHaveFounders && founder.mobile && (
              <>
                <FontAwesomeIcon style={{ marginTop: "5px" }} icon={faMobile} />
                <a href={`tel:${founder.mobile}`}>{founder.mobile}</a>
              </>
            )}

            {isHaveFounders && founder.email && (
              <>
                <FontAwesomeIcon
                  style={{ marginTop: "5px" }}
                  icon={faMailBulk}
                />
                <a href={`mailto:${founder.email}`}>{founder.email}</a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
