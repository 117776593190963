import React from "react";
import styles from "./index.module.scss";
import {
  menuBottomArrow,
  menuBottomArrowBlack,
} from "../../../../assets/icons";
import { store } from "../../../../store/store";
import classNames from "classnames";

const newsfeedNotificationCount = 4;

export const SectionPopup = ({ title, subSections, isOpen, setIsOpen }) => {
  const toggleSection = () => {
    setIsOpen((prev) => (prev === title ? "" : title));
  };

  const userId = store.getState().login.company_ids[0];

  return (
    <div className={classNames(styles.container, { [styles.isOpen]: isOpen })}>
      <div className={styles.topContainer} onClick={toggleSection}>
        {isOpen ? (
          <img
            className={styles.bottomArrow}
            src={menuBottomArrowBlack}
            alt="arrow-bottom icon"
          />
        ) : (
          <img
            className={styles.topArrow}
            src={menuBottomArrow}
            alt="arrow-bottom icon"
          />
        )}

        <h2 style={{ fontWeight: 500 }}>{title}</h2>
      </div>
      {
        <div
          className={classNames(styles.subCategoriesContainer, {
            [styles.isOpen]: isOpen,
          })}
        >
          {subSections.map(({ title, link, disabled }) => {
            const isCompanyProfileSection = title === "Company Profile";
            const isNewsFeed = title === "Newsfeed";
            return (
              <a
                // add id for dynamic route
                href={isCompanyProfileSection ? link + userId : link}
                key={title}
                onClick={(e) => disabled && e.preventDefault()}
              >
                <div style={{ display: "flex", gap: 5, alignItems: "center" }}>
                  {isNewsFeed && (
                    <div
                      style={{
                        background: "#FB9202",
                        color: "white",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 2px",
                        height: 24,
                        minWidth: 24,
                        fontSize: 14,
                      }}
                    >
                      {newsfeedNotificationCount}
                    </div>
                  )}
                  <span>{title}</span>
                </div>
              </a>
            );
          })}
        </div>
      }
    </div>
  );
};
