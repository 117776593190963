import React from "react";

export const DemoStep3 = (props) => {
    return (
        <div className="DemoStep3 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/step_3.png')} style={{height: '450px'}} alt={'...'} />
            <p className="mt-4">Click on "Edit Description" to add a custom description, such as your companies history, values, mission and vision. You can format your description to your liking with our HTML editor.</p>
        </div>
    )
};
