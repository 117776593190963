import React from "react";
import {Button, Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {ButtonSpinner, userLevelColors} from "../../services/globalFunctions";
import axios from "axios";
import {base_url} from "../../services/const";
import {NotificationManager} from 'react-notifications';

let searchInterval;

const INITIAL_STATE = {
    hsCodeVal: '',
    showLimitError: false,
    target: '',
    specification: '',
    searching: false,
    hsCodes: {},
    valObj: {},
    showBtnLoading: false
};

class JumpseatNewHsCodeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE
        };
        this.onClickListItem = this.onClickListItem.bind(this);
        this.getHsCode = this.getHsCode.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.sendHsCodesToBackend = this.sendHsCodesToBackend.bind(this);
    }

    onClickListItem(item, hsCode) {
        let obj = {
            id: hsCode,
            item: item
        };
        this.setState({selectedOptions: obj, showSearchResult: false, hsCodeVal: item});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {show} = this.props;
        if(show !== prevProps.show && !show) {
            this.setState({...INITIAL_STATE});
        }
    }

    getHsCode(e) {
        clearTimeout(searchInterval);
        let searchVal = e.target.value;
        this.setState({hsCodeVal: searchVal, showSearchResult: true, hsCodes: []});
        if(searchVal.length > 2) {
            searchInterval = setTimeout(() => {
                axios.request({
                    method: 'get',
                    url: `${base_url}/hscodes/search/${searchVal}`,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${this.props.token}`
                    }
                })
                    .then((ret) => {
                        if (ret.data.status === 200) {
                            this.setState({
                                hsCodes: ret.data.data
                            });
                        } else {
                            NotificationManager.error('No result found');
                            this.setState({showSearchResult: false});
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
            }, 3000)
        }
        if(searchVal.length <= 0) {
            this.setState({showSearchResult: false});
        }
    }

    onClickSave() {
        this.setState({showBtnLoading: true});
        this.sendHsCodesToBackend(this.state.selectedOptions.id, this.props.type, this.state.target, this.state.specification)
    }

    sendHsCodesToBackend(hsCode, type, target, specification) {
        return new Promise((resolve, reject) => {
            let params = new URLSearchParams();
            params.append('hs_codes_id', hsCode);
            params.append('type', type);
            target ? params.append('target_group', target) : void 0;
            specification ? params.append('specification', specification) : void 0;

            axios.request({
                method: 'put',
                url: `${base_url}/company/pns/${this.props.companyIds[0]}`,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.props.token}`
                }
            }).then((res) => {
                this.setState({showBtnLoading: false});
                this.props.onHide();
                resolve(res.data.status);
            }).catch(err => {
                this.setState({showBtnLoading: false});
                console.log(err);
            });
        })
    }

    render() {
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className="CompanyServicesModal">
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>New {this.props.type === 'offer' ? 'Offering' : 'Searching'}</h3>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group w-100">
                        <input value={this.state.hsCodeVal} onChange={this.getHsCode} className="form-control" placeholder="Search HS Code" />
                        <small><i>Tip: If you are looking for a wide range of products, please enter only the first 3 digits of the HS code. <br />You can always contact your local WorldRing-Agent for any assistance.</i></small>
                    </div>
                    {
                        this.state.showSearchResult &&
                        <div style={this.state.hsCodes ? Object.keys(this.state.hsCodes).length > 0 ? {maxHeight: '200px', borderRadius: '4px', borderTop: '1px solid rgba(0, 0, 0, 0.125)',  borderBottom: '1px solid rgba(0, 0, 0, 0.125)', overflow: 'scroll'} : {} : {}} className={"position-absolute w-100 "}>
                            <ul className="list-group w-100">
                                {
                                    Object.keys(this.state.hsCodes).length > 0 ? Object.keys(this.state.hsCodes).map((item, i) => {
                                        return <li key={i} className="list-group-item" onClick={this.onClickListItem.bind(null, this.state.hsCodes[item], item)}>{this.state.hsCodes[item]}</li>
                                    }) : <div className="spinner-border" role="status" style={{marginLeft: '50%'}}>
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                            </ul>
                        </div>

                    }
                    <div className="d-flex">
                        <div className="form-group w-100 mr-2">
                            <textarea rows={7} value={this.state.target} onChange={(e) => this.setState({target: e.target.value})} className="form-control" placeholder="Target industry" />
                        </div>
                        <div className="form-group w-100 ml-2">
                            <textarea rows={7} value={this.state.specification} onChange={(e) => this.setState({specification: e.target.value})} className="form-control" placeholder="Specification" />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} variant="secondary" onClick={this.props.onHide}>
                        Close
                    </Button>
                    <Button disabled={this.state.showBtnLoading} style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} variant="dark" onClick={this.onClickSave}>
                        {this.state.showBtnLoading ?
                            <ButtonSpinner
                                level={this.props.level}
                                showLoading={true}
                            />
                            : "Save"}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    return {
        level, token, companyIds
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JumpseatNewHsCodeModal);
