import React from "react";
import { Button, Modal } from "react-bootstrap";
import { userLevelColors } from "../../../services/globalFunctions";
import { connect } from "react-redux";
import axios from "axios";
import { base_url } from "../../../services/const";

class ProductDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      fileUrl: null,
    };
    this.getPdf = this.getPdf.bind(this);
    this.onHideModal = this.onHideModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.show !== prevProps.show &&
      this.props.show &&
      this.props.pdf
    ) {
      this.getPdf();
    }
  }

  onHideModal() {
    this.setState({ fileName: "", fileUrl: null }, () => {
      this.props.onHide();
    });
  }

  getPdf() {
    // GET /files/pdf/{file_id}
    axios
      .request({
        method: "GET",
        url: `${base_url}/files/pdf/${this.props.pdf}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.data.data) {
          const binaryImg = atob(res.data.data.data);
          const length = binaryImg.length;
          const arrayBuffer = new ArrayBuffer(length);
          const uintArray = new Uint8Array(arrayBuffer);

          for (let i = 0; i < length; i++) {
            uintArray[i] = binaryImg.charCodeAt(i);
          }

          const fileBlob = new Blob([uintArray], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(fileBlob);
          this.setState({ fileName: res.data.data.filename, fileUrl: fileURL });
        }
      })
      .catch((err) => {});
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.onHideModal}
        className="CompanyServicesModal"
      >
        <Modal.Header
          className="box"
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            Product Details
          </h3>
        </Modal.Header>
        <Modal.Body>
          <div className="w-100 company-profile-image-box">
            <div className="w-100 text-center my-4">
              {this.props.image ? (
                <img
                  src={this.props.image}
                  alt={"..."}
                  style={{ height: "250px", width: "auto" }}
                />
              ) : (
                <img
                  src={require("../../../../src/assets/brand/logo.svg")}
                  alt={"..."}
                  style={{ height: "170px", width: "auto" }}
                />
              )}
            </div>
            <div className="col-lg-12 text-left my-4 mt-5 pr-0 pl-0">
              <div className="row w-100 mr-0 ml-0">
                <h4 className="col-lg-4 mb-0 py-3 mr-1">{this.props.title}</h4>
                <div
                  className={
                    "col py-3 px-2 mx-1 box " +
                    (this.props.isAg ? "d-none" : "")
                  }
                >
                  <div className="form-group">
                    <label>
                      <small>HS Code:</small>
                    </label>
                    <p style={{ fontSize: "14px" }}>
                      <span
                        style={{ fontSize: "14px" }}
                        className="badge badge-secondary"
                      >
                        {this.props.hsCode
                          ? this.props.hsCode.code +
                            " - " +
                            this.props.hsCode.description.substr(0, 9 - 1) +
                            "..."
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  className={
                    "col py-3 px-2 mx-1 box " +
                    (this.props.isAg ? "d-none" : "")
                  }
                >
                  <div className="form-group">
                    <label>
                      <small>Target group:</small>
                    </label>
                    <p style={{ fontSize: "14px" }}>{this.props.target}</p>
                  </div>
                </div>
                <div
                  className={
                    "col py-3 px-2 mx-1 box " +
                    (this.props.isAg ? "d-none" : "")
                  }
                >
                  <div className="form-group">
                    <label>
                      <small>Specification:</small>
                    </label>
                    <p style={{ fontSize: "14px" }}>
                      {this.props.specification}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-left my-4 box py-3">
              <p>{this.props.description}</p>
            </div>
            <div
              className={
                "col-lg-12 text-left my-4 pl-0 " +
                (this.state.fileUrl ? "" : "d-none")
              }
            >
              <Button
                className="btn-wlr"
                style={{
                  background: userLevelColors(this.props.level).buttonBg,
                  color: userLevelColors(this.props.level).buttonFont,
                  borderColor: userLevelColors(this.props.level).buttonFont,
                }}
                variant="secondary"
                onClick={() => window.open(this.state.fileUrl)}
              >
                {this.state.fileName ? this.state.fileName : void 0}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  let token = state.login.token;
  return {
    level,
    token,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailsModal);
