import React from "react";
import { connect } from "react-redux";
import { userLevelColors } from "../services/globalFunctions";
import { Button } from "react-bootstrap";
import axios from "axios";
import { base_url } from "../services/const";
import ProductModal from "../components/CompanyProfileModals/ServicesModal/ProductModal";
import MyProductsListItem from "../components/MyProductsListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faUpload,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Select from "react-select";
import { isResponseError } from "../utils/axios";

const sortingOptions = [
  { label: "Name A-Z", value: "asc" },
  { label: "Name Z-A", value: "des" },
  { label: "HSCode 0-99", value: "hsCode" },
];

class MyProducts extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      pns: [],
      pnsCc: [],
      showProductModal: false,
      isEditingItem: false,
      itemToEdit: null,
      searchVal: "",
    };
    this.getPnS = this.getPnS.bind(this);
    this.editPnS = this.editPnS.bind(this);
    this.onClickUploadPnS = this.onClickUploadPnS.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onChangeSorting = this.onChangeSorting.bind(this);
  }

  onClickUploadPnS(e) {
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("file", file);
    axios.post('/commodities',formData)
      .then((ret) => {
        if (ret.data.status === 200) {
          this.getPnS();
          NotificationManager.success("HSCodes uploaded successfully");
        } else {
          NotificationManager.error(
            ret.data.errors[Object.keys(ret.data.errors)[0]]
          );
        }
      })
      .catch((err) => {
        NotificationManager.error("Something went wrong, please try again.");
      });
    e.target.value = null;
  }

  componentDidMount() {
    this.getPnS();
  }

  editPnS(item) {
    this.setState({
      showProductModal: true,
      isEditingItem: true,
      itemToEdit: item,
    });
  }

  getPnS() {
    axios
      .get("/commodities", {
        params: {
          my: true,
          // with:[
          //   'pairings'
          // ],
        },
      })
      .then((ret) => {
        if (!isResponseError(ret)) {
          this.setState({ pns: ret.data.data, pnsCc: ret.data.data });
        } else {
          this.setState({ pns: [] });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangeSearch(e) {
    const { pnsCc } = this.state;
    const value = e.target.value;
    this.setState({ searchVal: value });
    if (value.length > 2) {
      const filter = pnsCc.filter((e) => e.name.includes(value));
      this.setState({ pns: filter });
    } else {
      this.setState({ pns: pnsCc });
    }
  }

  onChangeSorting(e) {
    const { pns } = this.state;
    if (e.value === "des") {
      const sorted = pns.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
      this.setState({ pns: sorted });
    }
    if (e.value === "asc") {
      const sorted = pns.sort((a, b) =>
        b.name > a.name ? 1 : a.name > b.name ? -1 : 0
      );
      this.setState({ pns: sorted });
    }
    if (e.value === "hsCode") {
      const sorted = pns.sort((a, b) =>
        a.hsCode.code > b.hsCode.code
          ? 1
          : b.hsCode.code > a.hsCode.code
          ? -1
          : 0
      );
      this.setState({ pns: sorted });
    }
  }

  render() {
    const { level, companyIds } = this.props;
    const { showProductModal, isEditingItem, pns, itemToEdit, searchVal } =
      this.state;
    return (
      <div className="page-container AdminPageContainer MyProductsListingPage">
        <div className="page-header">
          <form
            className="form-group col-5 position-absolute topsearch-container row"
            style={{ bottom: "55px" }}
          >
            <h1 className="search-heading">My Products</h1>
            <input
              className="form-control topsearch"
              placeholder="Search.."
              value={searchVal}
              onChange={this.onChangeSearch}
            />
            <button
              type="submit"
              className="navbar-search-btn bg-transparent border-0"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
        </div>
        <div
          className="top-navbar align-items-center addNewProductTopNavBar"
          style={{
            marginTop: 50,
            borderColor: userLevelColors(level).buttonFont,
          }}
        >
          <div className="leftBlockAddNewProduct" style={{ width: 200 }}></div>
          <div className="middleBlockAddNewProduct">
            <Button
              onClick={() =>
                this.setState({ showProductModal: true, isEditingItem: false })
              }
              style={{
                background: userLevelColors(level).buttonBg,
                color: userLevelColors(level).buttonFont,
                borderColor: userLevelColors(level).buttonFont,
              }}
            >
              Add new product
            </Button>
            <p>Please limit the HScode length to 4</p>
          </div>

          <div className="rightBlockAddNewProduct">
            <div className="rightBlockAddNewProduct-upload">
              <p>Upload multiple products</p>
              <input
                ref={this.ref}
                type="file"
                className="d-none"
                accept=".csv, .xls, .xlsx, .numbers"
                onChange={this.onClickUploadPnS}
              />
              <FontAwesomeIcon
                onClick={() => this.ref.current.click()}
                className="mr-3"
                style={{ cursor: "pointer" }}
                icon={faUpload}
              />
            </div>
            <div className="rightBlockAddNewProduct-download">
              <p>sample upload file</p>
              <Link
                to="/wr-massupload-template_V5b.xlsx"
                target="_blank"
                download
              >
                <FontAwesomeIcon
                  className="mr-3"
                  style={{ cursor: "pointer" }}
                  icon={faDownload}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="d-flex justify-content-between mb-4">
            <h4 className="font-weight-light">&nbsp;</h4>
            <div className="col-lg-3">
              <Select
                options={sortingOptions}
                placeholder="Sort"
                onChange={this.onChangeSorting}
              />
            </div>
          </div>
          {pns.length > 0 &&
            pns.map((item, i) => {
              return (
                <MyProductsListItem
                  editPnS={this.editPnS}
                  item={item}
                  key={item.id}
                />
              );
            })}
          {pns.length === 0 && (
            <div className="searchresult box p-3 mb-4 position-relative row col-lg-12 align-items-center">
              <div className="col-lg-2">
                <img
                  style={{
                    height: "70px",
                    width: "auto",
                    objectFit: "contain",
                    maxWidth: "200px",
                  }}
                  src={require("../assets/images/WorldRing.png")}
                  alt="..."
                />
              </div>
              <div className="col-lg-6">
                <p className="m-0 text-center">
                  {searchVal.length === 0
                    ? "You have not added any Product or Service yet."
                    : "You have not yet added any Product or Service with that name yet."}
                </p>
              </div>
              <div className="col-lg-4 text-right">
                <button
                  style={{
                    background: userLevelColors(level).buttonBg,
                    color: userLevelColors(level).buttonFont,
                    borderColor: userLevelColors(this.props.level).buttonFont,
                  }}
                  className="btn btn-wlr text-uppercase"
                  onClick={() =>
                    this.setState({
                      showProductModal: true,
                      isEditingItem: false,
                    })
                  }
                >
                  Start now!
                </button>
              </div>
            </div>
          )}
        </div>
        {showProductModal && (
          <ProductModal
            isEditingItem={isEditingItem}
            show={showProductModal}
            onHide={() =>
              this.setState({ showProductModal: false, itemToEdit: null })
            }
            companyId={companyIds[0]}
            getPnS={this.getPnS}
            itemToEdit={itemToEdit}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  let companyIds = state.login.company_ids;
  return {
    token,
    level,
    companyIds,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyProducts);
