import React from "react";

export const DemoStep5 = (props) => {
    return (
        <div className="DemoStep5 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/image.png')} style={{height: '450px'}} alt={'...'} />
            <p className="mt-4">In the products and services section of your company profile you can add more products or adjust your current offerings.</p>
        </div>
    )
};
