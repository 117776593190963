import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faSearch} from "@fortawesome/free-solid-svg-icons";
import MembershipPackages from "../MembershipPackages";

export const JumpseatMatchesFallback = (props) => {
    const [showModal, setModalState] = React.useState(false);
    return (
        <div className="JumpseatMatchesFallbackContainer JumpseatMatchesFallbackContent position-relative">
            <div className="searchresult w-100 row p-3 col-lg-12">
                <div className="JumpseatOverlay" style={{position: 'absolute', height: props.showFallback ? '375px' : '285px', background: 'grey', opacity: '0.6', marginTop: '250px', marginLeft: '570px', width: '640px'}}></div>
                {
                    props.showFallback ?
                        <div style={{marginLeft: '590px', maxWidth: '600px', position: 'absolute'}}>
                            <h3 className="text-white" style={{marginTop: '260px'}}>Congratulations! Your search has been successfully shared with our Network.</h3>
                            <p className="mb-0 mt-4 text-white" style={{fontSize: '18px'}}>The WorldRing agents around the globe are looking now to find the perfect match for your search.</p>
                            <p className="mb-0 text-white" style={{fontSize: '18px'}}><u>Once a match has been found, you will get notified automatically.</u></p>
                            <p className="mb-0 mt-5 text-white" style={{fontSize: '18px'}}>You can use the menu <FontAwesomeIcon size={"lg"} icon={faBars}/> at the top right of the page to add more products to your search. Use the menu as well to amend your current search and find out more about our great full membership offers.</p>
                            <h3 className="mt-3 mb-5 text-white"><u>Thank you for using the WorldRing-Marketplace</u></h3>
                        </div> :
                        <div style={{marginLeft: '590px', maxWidth: '600px', position: 'absolute'}}>
                            <p className="text-white" style={{fontSize: '26px', marginTop: '260px'}}>There is currently no match for your request available.</p>
                            <p className="text-white" style={{fontSize: '26px'}}>Our network is still trying to find the right match for your request.</p>
                            <p className="text-white" style={{fontSize: '26px'}}>Please contact your designated agent, if you need any further support.</p>
                        </div>
                }
            </div>
            <MembershipPackages show={showModal}
                                onHide={() => setModalState(false)}/>
        </div>
    )
};
