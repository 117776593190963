import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {faEdit} from "@fortawesome/pro-light-svg-icons";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import CompanyServicesModal from "../CompanyProfileModals/ServicesModal/ProductServicesModal";
import { deletePnS, userLevelColors } from "../../services/globalFunctions";
import ConfirmationModal from "../CompanyProfileModals/ConfirmationModal";
import { NotificationManager } from "react-notifications";
import Slider from "react-slick";
import ProductDetailsModal from "../CompanyProfileModals/ServicesModal/ProductDetailsModal";

class PnSShowCase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPnSModal: false,
      showConfirmationModal: false,
      pnsId: null,
      pnsName: "",
      showDetailsModal: false,
      overlayTitle: "",
      overlayDescription: "",
      overlayImage: "",
    };
    this.onClickDeletePnS = this.onClickDeletePnS.bind(this);
    this.deletePnS = this.deletePnS.bind(this);
  }

  onClickDeletePnS(pnsId, title) {
    this.setState({
      showConfirmationModal: true,
      pnsId: pnsId,
      pnsName: title,
    });
  }

  async deletePnS() {
    await deletePnS(this.state.pnsId, this.props.token).then((res) => {
      if (res.data.status === 200) {
        NotificationManager.success("Entry deleted", "Success");
        this.props.getPnS();
        this.setState({ showConfirmationModal: false });
      } else {
        NotificationManager.error("Something went wrong. Try again.", "Error");
        this.setState({ showConfirmationModal: false });
      }
    });
  }

  render() {
    let xIconStyles = {
      right: "20px",
      top: "4px",
      background: "black",
      borderRadius: "25px",
      padding: "2px",
      width: "20px",
      height: "18px",
      color: "white",
    };
    const carouselSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.props.pns.length > 1 ? 2 : 1,
      slidesToScroll: this.props.pns.length > 1 ? 2 : 1,
      autoplay: true,
      autoplaySpeed: 5000,
      swipe: false,
    };
    return (
      <div
        className={
          "product-services mt-5 w-100 box position-relative " +
          (Array.isArray(this.props.agContact) ? "" : "d-none")
        }
      >
        {this.props.editProfile ? (
          <button
            className="btn d-inline-block"
            onClick={() => this.setState({ showPnSModal: true })}
            style={{ padding: "0", marginBottom: "10px" }}
          >
            <FontAwesomeIcon
              style={{ verticalAlign: "middle" }}
              icon={faPencilAlt}
            />{" "}
            <span style={{ verticalAlign: "middle" }}>
              Edit Product and Services
            </span>
          </button>
        ) : (
          ""
        )}
        <h4 className="section-title" style={{ display: "block" }}>
          Product and Services
        </h4>
        <div className="product-services-card card">
          <div className="card-body">
            <div
              style={{ marginLeft: "20px" }}
              className={this.props.pns.length > 1 ? "" : "text-center"}
            >
              {this.props.pns ? (
                this.props.pns.length > 0 ? (
                  <Slider {...carouselSettings}>
                    {this.props.pns.map((item, index) => {
                      return (
                        <div
                          className={
                            "text-center mt-2 remove-focus " +
                            (this.props.pns.length > 1
                              ? "col-lg-12"
                              : "col-lg-6")
                          }
                          key={item.pns_id}
                          onClick={() =>
                            this.setState((prevState) => ({
                              showDetailsModal: !prevState.showOverlay,
                              overlayDescription: item.description,
                              overlayTitle: item.name,
                              overlayImage: item.image.data
                                ? `data:image/jpeg;base64,${item.image.data}`
                                : `data:image/jpeg;base64,${this.props.company_logo.data}`,
                            }))
                          }
                        >
                          <FontAwesomeIcon
                            onClick={() =>
                              this.onClickDeletePnS(item.pns_id, item.name)
                            }
                            style={xIconStyles}
                            className={
                              "position-absolute " +
                              (this.props.editProfile ? "" : "d-none")
                            }
                            icon={faTimes}
                          />
                          <img
                            style={{ width: "100%" }}
                            src={
                              item.image.data
                                ? `data:image/jpeg;base64,${item.image.data}`
                                : `data:image/jpeg;base64,${this.props.company_logo.data}`
                              // "https://placehold.co/150x150"
                            }
                            alt="..."
                          />
                          <p className="text-center mt-4 w-100">
                            {item.name ? item.name : item.description}
                          </p>
                        </div>
                      );
                    })}
                  </Slider>
                ) : (
                  <div
                    className={"w-100 text-center "}
                    style={{ padding: "30px" }}
                  >
                    <p
                      className={
                        "w-100 text-center " +
                        (this.props.editProfile ? "d-none" : "")
                      }
                    >
                      This user has not added any product and services yet.
                    </p>
                    <p
                      className={
                        "mb-2 " + (this.props.editProfile ? "" : "d-none")
                      }
                    >
                      Haven't added any services yet?
                    </p>
                    <input
                      onClick={() => this.setState({ showPnSModal: true })}
                      type="button"
                      className={
                        "btn btn-wlr " +
                        (this.props.editProfile ? "" : "d-none")
                      }
                      value="Add Services"
                      style={{
                        background: userLevelColors(this.props.level).buttonBg,
                        color: userLevelColors(this.props.level).buttonFont,
                        borderColor: userLevelColors(this.props.group)
                          .buttonFont,
                      }}
                    />{" "}
                  </div>
                )
              ) : (
                <div
                  className={"w-100 text-center "}
                  style={{ padding: "30px" }}
                >
                  <p
                    className={
                      "w-100 text-center " +
                      (this.props.editProfile ? "d-none" : "")
                    }
                  >
                    This user has not added any product and services yet.
                  </p>
                  <p
                    className={
                      "mb-2 " + (this.props.editProfile ? "" : "d-none")
                    }
                  >
                    Haven't added any services yet?
                  </p>
                  <input
                    onClick={() => this.setState({ showPnSModal: true })}
                    type="button"
                    className={
                      "btn btn-wlr " + (this.props.editProfile ? "" : "d-none")
                    }
                    value="Add Services"
                    style={{
                      background: userLevelColors(this.props.level).buttonBg,
                      color: userLevelColors(this.props.level).buttonFont,
                      borderColor: userLevelColors(this.props.group).buttonFont,
                    }}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <CompanyServicesModal
          show={this.state.showPnSModal}
          onHide={() => this.setState({ showPnSModal: false })}
          companyServices={this.props.pns}
          companyId={this.props.companyId}
          getPnS={this.props.getPnS}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          onHide={() => this.setState({ showConfirmationModal: false })}
          onClickDelete={this.deletePnS}
          itemToDelete={this.state.pnsName}
        />
        <ProductDetailsModal
          show={this.state.showDetailsModal}
          description={this.state.overlayDescription}
          title={this.state.overlayTitle}
          image={this.state.overlayImage}
          onHide={() => this.setState({ showDetailsModal: false })}
          isAg={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let activeUserCompanyArr = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  return {
    token,
    activeUserCompanyArr,
    level,
    group,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PnSShowCase);
