import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { isResponseError } from "../../utils/axios";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";

export const useGetProducts = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  commodity_type_id,
  country,
  sortList,
  refetch,
  setRefetch,
  isAdmin,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const { data } = await axios.get(`commodities`, {
        params: {
          //PAGINATION
          per_page: perPage,
          page,
          //FILTERS
          commodity_type_id,
          country,
          search: search && search.trim() ? search.trim() : undefined,

          columns_search: [
            "id",
            "name",
            "incoterm",
            "description",
            "country",
            "specification",
          ],

          user_level_id: userLevel ? [userLevel] : undefined,
          //SORTING
          order: sortList || undefined,
          //REQUEST
          is_pairings: true,
          with: [
            "user",
            "user.companies",
            "user.files",
            "user.role",
            "user.inviter",
            //"pairings",
          ],
        },
      });

      if (!isResponseError(data)) {
        const formattedData = data.data.map((commodity) => {
          const agentInCharge =
            commodity.user.inviter &&
            commodity.user.user_level.name !== "Trading Company"
              ? commodity.user.inviter.companies[0].name
              : "-";

          const isJump =
            (commodity.user.user_level.name === "Jumpseat" ||
              commodity.user.user_level.name === "Temp") &&
            !isAdmin;

          return {
            customer: isJump ? "-" : commodity.user.companies[0].name,
            type: commodity.commodity_type.code,
            HSCode: commodity.product.code,
            "product Description": commodity.description,
            specification: commodity.specification,
            country: commodity.country,
            AIC: agentInCharge.replace("&#038;", "&"),
            "Membership Level": commodity.user.user_level
              ? commodity.user.user_level.name
              : "-",
            number: commodity.number,
            id: commodity.id,
            "online since": formatDateToDDMMYYYY(commodity.created_at),
            "date of match": commodity.pairing_at
              ? formatDateToDDMMYYYY(commodity.pairing_at)
              : "-",
            user: commodity.user,
            is_belongs_to_me: commodity.is_belongs_to_me,

            pairings: commodity.pairings.map((matchedCommodity) => {
              const pairingAgentInCharge =
                matchedCommodity.user.inviter &&
                matchedCommodity.user.user_level.name !== "Trading Company"
                  ? matchedCommodity.user.inviter.companies[0].name
                  : "-";
              const isJump =
                !isAdmin &&
                (matchedCommodity.user.user_level.name === "Jumpseat" ||
                  matchedCommodity.user.user_level.name === "Temp");
              return {
                customer: isJump
                  ? "-"
                  : matchedCommodity.user.companies[0].name ||
                    `${matchedCommodity.user.inviter.name}`,
                type: matchedCommodity.commodity_type.code,
                HSCode: matchedCommodity.product.code,
                "product Description": matchedCommodity.description,
                specification: matchedCommodity.specification,
                country: matchedCommodity.country,
                AIC: pairingAgentInCharge.replace("&#038;", "&"),
                "Membership Level": matchedCommodity.user.user_level
                  ? matchedCommodity.user.user_level.name
                  : "-",
                number: matchedCommodity.number,
                id: matchedCommodity.id,
                "online since": formatDateToDDMMYYYY(
                  matchedCommodity.created_at
                ),
                "date of match": matchedCommodity.pairing_at
                  ? formatDateToDDMMYYYY(matchedCommodity.pairing_at)
                  : "-",
                user: matchedCommodity.user,
              };
            }),
          };
        });

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;

        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    commodity_type_id,
    country,
    sortList,
    refetch,
    setRefetch,
    isAdmin,
  ]);

  return { data: data || [], isLoading, error, total: total || 0 };
};
