import React from "react";

class PageHeader extends React.Component {
    render() {
        return (
            <div>
                <div className={"page-header " + (this.props.withMargin ? " mb-5 " : "")}>

                </div>
            </div>

        )
    }
}

export default PageHeader;
