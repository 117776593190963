import React from "react";
import {Button, Modal} from "react-bootstrap";
import axios from 'axios';
import {base_url, countryList} from "../services/const";
import {connect} from "react-redux";
import {NotificationManager} from "react-notifications";
import {SearchSpinner, userLevelColors} from "../services/globalFunctions";

class CronJobsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            tableData: null,
            updatingStatus: false
        };
        this.getCronJobs = this.getCronJobs.bind(this);

    }

    getCronJobs(refetching) {
        return new Promise((resolve, reject) => {
            !refetching && this.setState({isFetching: true});
            axios.request({
                method: 'GET',
                url: `${base_url}/cronjobs`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.props.token}`
                }
            }).then(ret => {
                this.setState({isFetching: false});
                if(ret.data.status === 200 && ret.data.data.length > 0) {
                    let data = {
                        cols: ['Name', 'Status', ''],
                        rows: [
                        ]
                    };
                    data.rows = ret.data.data.map((item) => {
                        return [item.name, item.status, this.statusBtn(item)]
                    });
                    this.setState({tableData: data}, () => {
                        resolve();
                    });
                }
            }).catch(err => {
                reject(err);
                this.setState({isFetching: false});
                console.log(err);
            })
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show !== prevProps.show && this.props.show) {
            this.getCronJobs();
        }
    }

    onClickStatusBtn(item) {
        this.setState({updatingStatus: true});
        axios.request({
            method: 'GET',
            url: `${base_url}/cronjobs/${this.statusBtnString(item.status).endpoint}/${item.id}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(async ret => {
            if(ret.data.status === 200) {
                await this.getCronJobs(true);
                this.setState({updatingStatus: false});
            } else {
                this.setState({updatingStatus: false});
            }
        }).catch(err => {
            console.log(err);
            this.setState({updatingStatus: false});
        })
    }

    statusBtn(item) {
        return (
            <Button
                style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                variant="secondary"
                onClick={this.onClickStatusBtn.bind(this, item)}
            >
                {this.statusBtnString(item.status).str}
            </Button>
        )
    }

    statusBtnString(status) {
        switch (status) {
            case 'Error':
                return {str: 'Restart', endpoint: 'enable'};
            case 'Running':
                return {str: 'Disable', endpoint: 'disable'};
            case 'Inactive':
                return {str: 'Enable', endpoint: 'enable'};
            default:
                return {str: '', endpoint: ''};
        }
    }

    render() {
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide}>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}} className="">Cron Jobs</h3>
                </Modal.Header>
                {this.state.updatingStatus && <div className="overlay">
                    <SearchSpinner level={this.props.level} showLoading={this.state.updatingStatus} /> :
                </div>}
                <Modal.Body>
                    {
                        this.state.isFetching ?
                            <SearchSpinner level={this.props.level} showLoading={this.state.isFetching} /> :
                            <table className={"table mt-4 table-borderless "}>
                                <thead>
                                <tr className="col-lg-12">
                                    {this.state.tableData
                                        ? this.state.tableData.cols.map((item, i) => {
                                            return (
                                                <th key={i} scope="col">
                                                    {item}
                                                </th>
                                            );
                                        })
                                        : void 0}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    (this.state.tableData && this.state.tableData.rows) &&
                                        this.state.tableData.rows.map((item, i) => {
                                            return (
                                                <tr className="col-lg-12" key={i}>
                                                    {
                                                        item.map((el, i) => {
                                                            return <td key={i}>{el}</td>
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                }
                                </tbody>
                            </table>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                        variant="secondary"
                        onClick={this.props.onHide}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;
    return {
        token, level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CronJobsModal);
