import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";

export const useGetAgents = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  status,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(`users`, {
          params: {
            per_page: perPage,
            page,
            search,
            sorting: sortList,
            user_level_id: 8,
            status,
            columns_search: ["name", "email", "phone"],
          },
        });

        const formattedData = data.data.map((agent) => {
          const isCompanies = !!agent.companies.length;
          const isAddresses =
            isCompanies && !!agent.companies[0].addresses.length;

          let country = "-";
          let city = "-";

          if (isAddresses) {
            const name = agent.companies[0].addresses[0].name;
            if (name) {
              const nameArr = name.split(",");
              if (nameArr && nameArr.length) {
                country = nameArr[nameArr.length - 1];
              }

              if (nameArr && nameArr.length) {
                if (nameArr.length - 3 >= 0) {
                  city = nameArr[nameArr.length - 3];
                } else {
                  city = nameArr[nameArr.length - 2];
                }
              }
            }
          }

          return {
            id: agent.id,
            company_name: agent.companies.length
              ? agent.companies[0].name.replace("&#038;", "&")
              : "-",
            country: country,

            city: city,

            user_name: agent.name,
            aic: "-",
            email: agent.email || "-",
            phone: agent.phone || "-",
            user_level:
              agent.user_level.name === "Distributor"
                ? "Super Agent"
                : agent.user_level.name,
            created_at: formatDateToDDMMYYYY(agent.created_at),
            expire_at: "-",
            sent_at: "-",
            status: "-",
            expireTime: "-",

            // "Company name": agent.companies.length
            //   ? agent.companies[0].name
            //   : "-",
            // "User name": agent.name,
            // "Sent to Email": agent.email,
            // "Sent on": "-",
            // Status: "-",
            // "time to expiry": "-",
            // ID: agent.id,
          };
        });

        setData(formattedData);
        setTotal(data.total);
        hasFetchedOnce.current = true;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    status,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total | 0 };
};
