import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import SearchingModal from "../CompanyProfileModals/ServicesModal/SearchingModal";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import ConfirmationModal from "../CompanyProfileModals/ConfirmationModal";
import {
  deletePnS,
  PnSLimit,
  userLevelColors,
} from "../../services/globalFunctions";
import Slider from "react-slick";
import ProductDetailsModal from "../CompanyProfileModals/ServicesModal/ProductDetailsModal";

class SearchingShowcase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchingModal: false,
      searches: [],
      showConfirmationModal: false,
      pnsId: null,
      pnsName: "",
      showDetailsModal: false,
      overlayDescription: "",
      overlayHsCode: "",
      overlayTitle: "",
      overlayImage: "",
      overlayTarget: "",
      overlaySpecs: "",
      overlayPdf: "",
      buttonValue: null,
    };
    this.filterPnS = this.filterPnS.bind(this);
    this.onClickDeletePnS = this.onClickDeletePnS.bind(this);
    this.deletePnS = this.deletePnS.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searches !== prevProps.searches) {
      this.filterPnS();
    }
  }

  componentDidMount() {
    this.filterPnS();
  }

  filterPnS() {
    if (this.props.searches.length > 0) {
      let arr = [];
      this.props.searches.map((item) => {
        if (item.type === "search") {
          arr.push(item);
        }
        return true;
      });
      let limitedArr = [];
      for (let i = 0; i < PnSLimit(this.props.group); i++) {
        if (arr[i]) {
          limitedArr.push(arr[i]);
        }
      }
      this.setState({ searches: limitedArr });
    } else {
      this.setState({ searches: [] });
    }
  }

  onClickDeletePnS(pnsId, title) {
    this.setState({
      showConfirmationModal: true,
      pnsId: pnsId,
      pnsName: title,
    });
  }

  async deletePnS() {
    await deletePnS(this.state.pnsId, this.props.token).then((res) => {
      if (res.data.status === 200) {
        NotificationManager.success("Entry deleted", "Success");
        this.props.getPnS();
        this.setState({ showConfirmationModal: false });
      } else {
        NotificationManager.error("Something went wrong. Try again.", "Error");
        this.setState({ showConfirmationModal: false });
      }
    });
  }

  render() {
    let xIconStyles = {
      right: "20px",
      top: "4px",
      background: "black",
      borderRadius: "25px",
      padding: "2px",
      width: "20px",
      height: "18px",
      color: "white",
      boxShadow: "0 0 6px rgba(0, 0, 0, 0.4)",
    };
    const carouselSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: this.state.searches.length > 1 ? 2 : 1,
      slidesToScroll: this.state.searches.length > 1 ? 2 : 1,
      autoplay: true,
      autoplaySpeed: 5000,
      swipe: false,
    };
    return (
      <div
        className={
          "product-services mt-4 w-100 box position-relative " +
          (Array.isArray(this.props.agContact) ? "d-none" : "")
        }
      >
        {this.props.editProfile ? (
          <button
            className="btn d-inline-block"
            onClick={() => this.setState({ showSearchingModal: true })}
            style={{ padding: "0", marginBottom: "10px" }}
          >
            <FontAwesomeIcon style={{ verticalAlign: "middle" }} icon={faPen} />{" "}
            <span style={{ verticalAlign: "middle" }}>Edit Searches</span>
          </button>
        ) : (
          ""
        )}
        <h4 className="section-title" style={{ display: "block" }}>
          Detailed Searches
        </h4>
        <div className="product-services-card card">
          <div className="card-body">
            <div
              style={{ marginLeft: "20px" }}
              className={this.state.searches.length > 1 ? "" : "text-center"}
            >
              {this.state.searches ? (
                this.state.searches.length > 0 ? (
                  <Slider {...carouselSettings}>
                    {this.state.searches.map((item, i) => {
                      return item.name || item.hsCode.code ? (
                        <div
                          className={
                            "text-center remove-focus mt-2 " +
                            (this.state.searches.length > 1
                              ? "col-lg-12"
                              : "col-lg-6")
                          }
                          key={item.pns_id}
                          onClick={() =>
                            this.setState((prevState) => ({
                              showDetailsModal: !prevState.showDetailsModal,
                              overlayDescription: item.description,
                              overlayHsCode: item.hsCode,
                              overlayTitle: item.name,
                              overlaySpecs: item.specification,
                              overlayTarget: item.target_group,
                              overlayPdf: item.ps_pdf_id,
                              overlayImage: item.image.data
                                ? `data:image/jpeg;base64,${item.image.data}`
                                : "https://placehold.co/150x150",
                            }))
                          }
                        >
                          <FontAwesomeIcon
                            onClick={() =>
                              this.onClickDeletePnS(item.pns_id, item.name)
                            }
                            style={xIconStyles}
                            className={
                              "position-absolute " +
                              (this.props.editProfile ? "" : "d-none")
                            }
                            icon={faTimes}
                          />
                          <img
                            style={{ width: "100%" }}
                            src={
                              item.image.data
                                ? `data:image/jpeg;base64,${item.image.data}`
                                : "https://placehold.co/150x150"
                            }
                            alt="..."
                          />
                          <p className="text-center mt-4 w-100">
                            {item.name
                              ? item.name
                              : item.hsCode.code
                              ? item.hsCode.code + "-" + item.hsCode.description
                              : ""}
                          </p>
                        </div>
                      ) : (
                        void 0
                      );
                    })}
                  </Slider>
                ) : (
                  <div
                    className={"w-100 text-center "}
                    style={{ padding: "30px" }}
                  >
                    <p
                      className={
                        "w-100 text-center " +
                        (this.props.editProfile ? "d-none" : "")
                      }
                    >
                      This user has not added any detailed offering yet.
                    </p>
                    <p
                      className={
                        "mb-2 " + (this.props.editProfile ? "" : "d-none")
                      }
                    >
                      Haven't added any services yet?
                    </p>
                    <input
                      onClick={() =>
                        this.setState({ showSearchingModal: true })
                      }
                      type="button"
                      className={
                        "btn btn-wlr " +
                        (this.props.editProfile ? "" : "d-none")
                      }
                      value="Add Product Searching"
                      style={{
                        background: userLevelColors(this.props.group).buttonBg,
                        color: userLevelColors(this.props.group).buttonFont,
                        borderColor: userLevelColors(this.props.group)
                          .buttonFont,
                      }}
                    />{" "}
                  </div>
                )
              ) : (
                <div
                  className={"w-100 text-center "}
                  style={{ padding: "30px" }}
                >
                  <p
                    className={
                      "w-100 text-center " +
                      (this.props.editProfile ? "d-none" : "")
                    }
                  >
                    This user has not added any detailed offering yet.
                  </p>
                  <p
                    className={
                      "mb-2 " + (this.props.editProfile ? "" : "d-none")
                    }
                  >
                    Haven't added any services yet?
                  </p>
                  <input
                    onClick={() => this.setState({ showSearchingModal: true })}
                    type="button"
                    className={
                      "btn btn-wlr " + (this.props.editProfile ? "" : "d-none")
                    }
                    value="Add Product Searching"
                    style={{
                      background: userLevelColors(this.props.group).buttonBg,
                      color: userLevelColors(this.props.group).buttonFont,
                      borderColor: userLevelColors(this.props.group).buttonFont,
                    }}
                  />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <SearchingModal
          show={this.state.showSearchingModal}
          onHide={() => this.setState({ showSearchingModal: false })}
          searches={this.state.searches}
          companyId={this.props.companyId}
          getPnS={this.props.getPnS}
        />
        <ConfirmationModal
          show={this.state.showConfirmationModal}
          onHide={() => this.setState({ showConfirmationModal: false })}
          onClickDelete={this.deletePnS}
          itemToDelete={this.state.pnsName}
        />
        <ProductDetailsModal
          show={this.state.showDetailsModal}
          description={this.state.overlayDescription}
          hsCode={this.state.overlayHsCode}
          title={this.state.overlayTitle}
          image={this.state.overlayImage}
          target={this.state.overlayTarget}
          specification={this.state.overlaySpecs}
          pdf={this.state.overlayPdf}
          onHide={() => this.setState({ showDetailsModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let activeUserCompanyArr = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  return {
    token,
    activeUserCompanyArr,
    level,
    group,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchingShowcase);
