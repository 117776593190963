import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useTable, useSortBy, usePagination } from "react-table";
import "../../../../services/date-euro.js";
import styles from "./index.module.scss";
import { SearchSpinner } from "../../../../services/globalFunctions.js";
import ReactPaginate from "react-paginate";
import { DownloadButton } from "./components/DownloadButton/index.js";
import NewInviteModal from "../../../../components/Jumpseat/NewInviteModal.js";
import { handleCopyClick } from "./utils/handleCopyClick.js";
import { downloadCSV, downloadPDF, downloadXLSX } from "./utils/downloads.js";
import { MatchesButton } from "../MatchesButton/index.js";
import { ConfirmModal } from "../../../../components/ConfirmModal/index.js";
import { OptionsButton } from "../OptionsButton.jsx";
import {
  pencil,
  sortingAsc,
  sortingDesc,
} from "../../../../assets/icons/index.js";
import ProductModal from "../../../../components/CompanyProfileModals/ServicesModal/ProductModal.js";
import { ThreeDotsContainer } from "./ThreeDotsContainer.jsx";
import { ExpandedContainer } from "./ExpandedContainer.jsx";
import { SelectInput } from "../SelectInput/index.js";
import { perPageOptions } from "../../AgentDashboard.js";
import { productColumns, userColumns } from "./columns.js";

const TopThreeIcons = ({ sections, state, setState }) => {
  return (
    <div
      style={{
        maxHeight: 300,
        minHeight: 90,
        position: "relative",
        padding: 1,
      }}
    >
      <div style={{ maxHeight: 300, minHeight: 90, overflow: "auto" }}>
        {sections.map((level, index) => {
          const isActive = state.includes(level.value);
          return (
            <div
              style={{ display: "flex", gap: 7, cursor: "pointer" }}
              onClick={() =>
                isActive
                  ? setState((levels) =>
                      levels.filter((lvl) => lvl !== level.value)
                    )
                  : setState((levels) => [...levels, level.value])
              }
            >
              <input
                type="checkbox"
                checked={isActive}
                style={{ cursor: "pointer" }}
              />
              <span style={{ whiteSpace: "nowrap" }}>{level.label}</span>
            </div>
          );
        })}
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          style={{
            marginTop: 50,
            border: "none",
            background: "transparent",
            color: "blue",
            position: "absolute",
            bottom: 10,
            right: 0,
          }}
          onClick={() => setState([])}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

const AgentDashboardTable = ({
  data,
  isLoading,
  setActivePage,
  activePage,
  total,
  threeIconsData,
  isProductPage,
  isInvitationsTab: isInvitation,
  sortList,
  setSortList,
  setRefetch,
  perPage,
  setPerPage,
  setSelectedCustomers,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);

  const columns = useMemo(() => {
    return isProductPage ? productColumns : userColumns;
  }, [isProductPage]);

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        pageCount: Math.ceil(total / perPage),
        manualPagination: true,
        disableSortBy: true,
      },
      useSortBy,
      usePagination
    );

  const handleSelect = (row) => {
    row = row.original || row;

    console.log("row", row);

    const isSelected = selectedRows.map((row) => row.id).includes(row.id);

    if (isSelected) {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== row.id)
      );
      setSelectedCustomers &&
        setSelectedCustomers((prev) => prev.filter((el) => el !== row.id));
    } else {
      setSelectedRows([...selectedRows, row]);
      setSelectedCustomers && setSelectedCustomers((prev) => [...prev, row.id]);
    }
  };

  const handleOrangeButtonClick = (event, row) => {
    event.stopPropagation();
    const isExpanded = expandedRows.includes(row);

    if (isExpanded) {
      setExpandedRows(
        expandedRows.filter((expandedRow) => expandedRow !== row)
      );
    } else {
      setExpandedRows([...expandedRows, row]);
    }
  };
  const paginationLeftArrow = require("../../../../assets/icons/paginationLeftArrow.svg");

  const leftArrowIcon = (
    <div>
      <img src={paginationLeftArrow} alt="left arrow icon" />
    </div>
  );
  const rightArrowIcon = (
    <div className={styles.paginationRigthArrow}>
      <img src={paginationLeftArrow} alt="left arrow icon" />
    </div>
  );

  const [isUserDeleteConfirmModalIsOpen, setIsUserDeleteConfirmModalIsOpen] =
    useState(false);
  const [isHeadersChecked, setIsHeadersChecked] = useState(false);

  useEffect(() => {
    const isNotEmpty = selectedRows.length > 0 && page.length > 0;
    const isEqual = selectedRows.length === page.length;
    setIsHeadersChecked(isNotEmpty && isEqual);
  }, [selectedRows, page]);

  const isCustomerPage = !isProductPage;

  const [editedProductId, setEditedProductId] = useState(0);

  const itemToEdit = {
    id: editedProductId,
  };

  return (
    <div className={styles.productTablerWrapper}>
      {isLoading ? (
        <SearchSpinner showLoading />
      ) : (
        <div className={styles.container}>
          {/* modal for edit */}
          {!!editedProductId && (
            <ProductModal
              show={!!editedProductId}
              onHide={() => {
                setEditedProductId(null);
                setRefetch(true);
              }}
              clientHSCodeModal={true}
              fromAgentDashboard={true}
              isEditingItem={true}
              itemToEdit={itemToEdit}
            />
          )}
          <ConfirmModal
            isOpen={isUserDeleteConfirmModalIsOpen}
            onClose={() => setIsUserDeleteConfirmModalIsOpen(false)}
            postConfirmAction={() => {}}
          />
          <div className={styles.tableWrapper}>
            <table {...getTableProps()} className={`${styles.adminTable}`}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    <div
                      style={{
                        paddingLeft: 20,
                        height: 74,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        style={{ width: 15, height: 15, cursor: "pointer" }}
                        type="checkbox"
                        checked={isHeadersChecked}
                        onChange={(event) => {
                          setIsHeadersChecked((state) => !state);
                          if (isHeadersChecked) {
                            setSelectedRows([]);
                            setSelectedCustomers && setSelectedCustomers([]);
                          } else {
                            setSelectedRows(page.map((row) => row.original));
                            setSelectedCustomers &&
                              setSelectedCustomers(
                                page.map((row) => row.original.id)
                              );
                          }
                        }}
                      />
                    </div>
                    {headerGroup.headers.map((column, index) => {
                      const sortField = column.sort;
                      const isSortable =
                        sortList && sortList.hasOwnProperty(sortField);

                      return (
                        <th
                          key={column.id}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          style={{ cursor: sortField ? "pointer" : "default" }}
                          onClick={() => {
                            sortField &&
                              setSortList((list) => {
                                if (list) {
                                  if (list[sortField]) {
                                    if (list[sortField] === "asc")
                                      return { [sortField]: "desc" };

                                    if (list[sortField] === "desc") return {};
                                  } else {
                                    return { [sortField]: "asc" };
                                  }
                                }

                                return { [sortField]: "asc" };
                              });
                          }}
                        >
                          <div className={styles.headerContent}>
                            <span className={styles.headerSectionTitle}>
                              {column.render("Header")}
                              {isSortable && (
                                <img
                                  style={{ marginLeft: "5px" }}
                                  src={
                                    sortList[sortField] === "asc"
                                      ? sortingAsc
                                      : sortingDesc
                                  }
                                  alt="sorting"
                                />
                              )}
                            </span>

                            {threeIconsData.map(
                              ({
                                threeIconsOptions,
                                threeIconsValue,
                                setThreeIcons,
                                columnName,
                              }) => {
                                return (
                                  column.Header === columnName && (
                                    <div
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                    >
                                      <OptionsButton>
                                        <TopThreeIcons
                                          sections={threeIconsOptions}
                                          state={threeIconsValue}
                                          setState={setThreeIcons}
                                        />
                                      </OptionsButton>
                                    </div>
                                  )
                                );
                              }
                            )}
                          </div>
                        </th>
                      );
                    })}
                    <div style={{ width: 57 }} />
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {!page.length && (
                  <td colSpan={10} className={styles.emptyWrapper}>
                    No entries to display
                  </td>
                )}
                {page.map((row, index) => {
                  prepareRow(row);

                  // NEED для products, нужно понимать, что данный продукт - продукт агента
                  const isMyEntryRow = () => {
                    if (isCustomerPage) return false;

                    return (
                      row.original.is_belongs_to_me &&
                      row.original.user.user_level_id === 6
                    );
                  };

                  const isRowSelected = selectedRows
                    .map((row) => row.id)
                    .includes(row.original.id);

                  const isRowExpanded = expandedRows.includes(row);

                  return (
                    <>
                      <tr
                        key={row.original.id}
                        {...row.getRowProps()}
                        className={isRowExpanded ? styles.orangeRow : ""}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: 15,
                            height: 74,
                            alignItems: "center",
                            background: "white",
                          }}
                        >
                          <div
                            style={{ paddingLeft: 20 }}
                            onClick={() => handleSelect(row)}
                          >
                            <input
                              style={{
                                width: 15,
                                height: 15,
                                cursor: "pointer",
                              }}
                              type="checkbox"
                              checked={isRowSelected}
                            />
                          </div>
                          {!!(
                            row.original.pairings &&
                            row.original.pairings.length
                          ) && (
                            <MatchesButton
                              isOpen={isRowExpanded}
                              onClick={(event) =>
                                handleOrangeButtonClick(event, row)
                              }
                            />
                          )}
                        </div>
                        {row.cells.map((cell, index) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              key={index}
                              style={{
                                minWidth:
                                  cell.column.Header === "Product Description"
                                    ? "420px"
                                    : "50px",
                              }}
                            >
                              <div
                                className={styles.tableRowContent}
                                style={
                                  {
                                    // overflow: 'auto',
                                    // maxWidth: 120,
                                  }
                                }
                              >
                                {cell.render("Cell")}
                              </div>
                            </td>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            gap: 15,
                            alignItems: "center",
                            height: 74,
                            background: "white",
                            paddingRight: 15,
                          }}
                        >
                          {isMyEntryRow() && (
                            <div
                              onClick={() => {
                                setEditedProductId(row.original.id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img src={pencil} alt="pencil" />
                            </div>
                          )}

                          <ThreeDotsContainer
                            isInvitation={isInvitation}
                            isProductPage={isProductPage}
                            row={row}
                          />
                        </div>
                      </tr>
                      <ExpandedContainer
                        handleSelect={handleSelect}
                        selectedRows={selectedRows}
                        isRowSelected={isRowSelected}
                        row={row}
                        isRowExpanded={isRowExpanded}
                        isInvitation={isInvitation}
                        isProductPage={isProductPage}
                      />
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>

          {!!page.length && (
            <div style={{ padding: "0 15px" }}>
              <div className={styles.paginateWrapper}>
                <div className={styles.perPageWrapper}>
                  <p>Per page:</p>
                  <SelectInput
                    options={perPageOptions}
                    value={perPage}
                    setValue={setPerPage}
                    withoutArrow
                  />
                </div>
                <ReactPaginate
                  pageCount={Math.ceil(total / perPage)}
                  forcePage={activePage - 1}
                  onPageChange={({ selected: currentPage }) => {
                    setActivePage(currentPage + 1);
                    setSelectedRows([]);
                  }}
                  previousLabel={leftArrowIcon}
                  nextLabel={rightArrowIcon}
                  marginPagesDisplayed={1}
                  containerClassName={styles.paginationContainer}
                  activeClassName={styles.paginationActiveButton}
                />
              </div>
              <div className={styles.divider} />
              <div className={styles.exportFileContainer}>
                <div className={styles.exportFileLabel}>Export file:</div>
                <div className={styles.buttons}>
                  <DownloadButton
                    text="Download xls."
                    onClick={() => downloadXLSX(selectedRows)}
                  />
                  <DownloadButton
                    text="Download pdf."
                    onClick={() => downloadPDF(selectedRows)}
                  />
                  <DownloadButton
                    text="Download csv."
                    onClick={() => downloadCSV(selectedRows)}
                  />
                  <DownloadButton
                    text="Copy"
                    isCopy
                    onClick={() => handleCopyClick(selectedRows)}
                  />
                </div>
              </div>
            </div>
          )}
          <NewInviteModal
            show={isInviteModalVisible}
            onHide={() => setIsInviteModalVisible(false)}
            getTableData={{}}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

export default connect(mapStateToProps)(AgentDashboardTable);
