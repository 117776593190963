import { useState, useEffect, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { formatDateToDDMMYYYY } from "../../services/globalFunctions";
import { statuses } from "../../layouts/AgentDashboard/consts";

export const useGetInvitationCompanies = ({
  page,
  perPage,
  search,
  userLevel,
  cancel,
  status,
  sortList,
  refetch,
  setRefetch,
}) => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const hasFetchedOnce = useRef(false);

  useEffect(() => {
    if (cancel && hasFetchedOnce.current) {
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const { data: invites } = await axios.get(`invites`, {
          params: {
            //PAGINATION
            per_page: perPage,
            page,

            //
            search: search && search.trim() ? search.trim() : undefined,
            sorting: sortList,
            status,
            // columns_search: ["name", "email", "phone"],

            //REQUEST
            my_related: true,
            user_level_id: 1,
            is_register: 0,
            with: ["invitedUser"],
          },
        });

        const formattedData = invites.data.map((invite) => {
          const createdAt = moment(invite.created_at);
          const daysSinceCreation = moment().diff(createdAt, "days");
          const timeToExpiry = 365 - daysSinceCreation;

          const isCompanies = !!invite.invited_user.companies.length;
          const isAddresses =
            isCompanies && !!invite.invited_user.companies[0].addresses.length;

          return {
            id: invite.id,
            company_name: invite.invited_user.companies.length
              ? invite.invited_user.companies[0].name.replace("&#038;", "&")
              : "-",
            country: isAddresses
              ? invite.invited_user.companies[0].addresses[0].country_name
              : "-",

            city: isAddresses
              ? invite.invited_user.companies[0].addresses[0].city_name || "-"
              : "-",

            user_name: invite.invited_user.name,
            email: invite.invited_user.email || "-",
            phone: invite.invited_user.phone || "-",
            user_level: invite.invited_user.user_level.name,
            created_at: "-",
            expire_at: "-",
            sent_at: formatDateToDDMMYYYY(invite.updated_at),
            status: statuses.find((status) => status.value === invite.status)
              .label,
            expireTime: timeToExpiry,
          };
        });

        setData(formattedData);
        setTotal(invites.total);
        hasFetchedOnce.current = true;
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
        setRefetch(false);
      }
    };

    fetchData();
  }, [
    page,
    perPage,
    search,
    userLevel,
    cancel,
    status,
    sortList,
    refetch,
    setRefetch,
  ]);

  return { data: data || [], isLoading, error, total: total | 0 };
};
