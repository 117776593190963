import React from "react";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../../services/globalFunctions";

class CancelFallback extends React.Component {

    render() {
        return (
            <div>
                <Modal show={this.props.show} size={'sm'} onHide={this.props.onHide} className="CompanyServicesModal NewInviteModal">
                    <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                        <h3 style={{color: userLevelColors(this.props.level).font}}>Warning!</h3>
                    </Modal.Header>
                    <Modal.Body>
                        Your details have not been saved. if you want to close without saving, click on OK.
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between w-100">
                            <div>
                                <Button style={{
                                    background: userLevelColors(this.props.level).buttonBg,
                                    color: userLevelColors(this.props.level).buttonFont,
                                    borderColor: userLevelColors(this.props.level).buttonFont
                                }} className="mr-4" variant="secondary" onClick={this.props.onHide}>
                                    Close
                                </Button>
                                <Button
                                    onClick={this.props.onClickOk}
                                    variant="danger"
                                >
                                    OK
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    let level = state.login.level;
    return {
        token, companyIds, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CancelFallback);