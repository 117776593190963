import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../services/globalFunctions";
import {connect} from "react-redux";

class JumpseatConfirmationModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} size={'md'} onHide={this.props.onHide} className='HeroImageModal' style={{marginTop:'18%'}}>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>Submit your Searches/Offers</h3>
                </Modal.Header>
                <Modal.Body className="mb-3">
                    <p>Is all information entered correct?</p>
                    <p>Do you want to share your entry with the WorldRing Network?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-light"  onClick={this.props.onHide}>
                        Cancel
                    </Button>
                    <Button style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, border: `1px solid ${userLevelColors(this.props.level).buttonFont}`}} variant="dark" onClick={this.props.onSubmit}>
                        Yes, please
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JumpseatConfirmationModal);

