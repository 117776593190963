import React from "react";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {ButtonSpinner, deletePnS, userLevelColors} from "../../services/globalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {base_url, countryList} from "../../services/const";
import HsCodeForm from "./HsCodeForm";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import CancelFallback from "./CancelFallback";

const INITIAL_STATE = {
    hsCodesToShow: [1],
    valObj: {0: {}},
    companyName: '',
    filteredClients: [],
    country: '',
    fname: '',
    lname: '',
    errors: {},
    childErrors: {},
    selectedCompanyId: '',
    title: 'Mr.',
    hsCodeToEdit: null,
    btnLoading: false,
    deleteBtnLoading: false,
    showCancelModal: false
};

class HsCodeModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.onChangeSpecs = this.onChangeSpecs.bind(this);
        this.onChangeTarget = this.onChangeTarget.bind(this);
        this.onChangeHsCode = this.onChangeHsCode.bind(this);
        this.onChangeCompanyName = this.onChangeCompanyName.bind(this);
        this.onClickClientCompany = this.onClickClientCompany.bind(this);
        this.onClickAddMoreFields = this.onClickAddMoreFields.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.saveHsCode = this.saveHsCode.bind(this);
        this.updateHsCode = this.updateHsCode.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.showCancelModal = this.showCancelModal.bind(this);
        this.onClickRemoveEntry = this.onClickRemoveEntry.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {editingOrNew, hsCodeToEdit, allClients, show} = this.props;
        if (editingOrNew === "edit" && hsCodeToEdit && show !== prevProps.show && show) {
            let companyData = allClients.find(e => e.company_name === hsCodeToEdit.company_name);
            this.setState({
                country: hsCodeToEdit.company_country,
                companyName: hsCodeToEdit.company_name,
                fname: companyData.fname,
                lname: companyData.lname,
                valObj: {
                    0: {
                        hsCode: [{id: '', item: hsCodeToEdit.hsCode.code + ' - ' + hsCodeToEdit.hsCode.description}],
                        specification: hsCodeToEdit.specification,
                        target: hsCodeToEdit.target_group,
                        type: hsCodeToEdit.type
                    }
                },
                hsCodeToEdit: hsCodeToEdit,
                selectedCompanyId: companyData.company_id
            });
        }
        if (show !== prevProps.show && !show) {
            this.setState(INITIAL_STATE);
            this.setState({valObj: {0: {}}});
        }
    }

    onChangeHsCode(key, val) {
        let obj = this.state.valObj;
        obj[key] ? obj[key].hsCode = val : obj[key] = {hsCode: val};
        this.setState({valObj: obj});
    }

    onChangeTarget(key, val) {
        let obj = this.state.valObj;
        obj[key] ? obj[key].target = val : obj[key] = {target: val};
        this.setState({valObj: obj});
    }

    onChangeSpecs(key, val) {
        let obj = this.state.valObj;
        obj[key] ? obj[key].specification = val : obj[key] = {specification: val};
        this.setState({valObj: obj});
    }

    onChangeCompanyName(e) {
        const {allClients} = this.props;
        let value = e.target.value;
        this.setState({companyName: value});
        if (value.length >= 3) {
            let filter = allClients.filter(e => e.company_name.toLowerCase().includes(value.toLowerCase()));
            this.setState({filteredClients: filter});
        } else {
            this.setState({filteredClients: []});
        }
    }

    onChangeType(key, val) {
        let obj = this.state.valObj;
        obj[key] ? obj[key].type = val : obj[key] = {type: val};
        this.setState({valObj: obj});
    }

    onClickClientCompany(e) {
        this.setState({
            companyName: e.company_name,
            country: e.country,
            fname: e.fname,
            lname: e.lname,
            filteredClients: [],
            selectedCompanyId: e.company_id
        });
    }

    onClickAddMoreFields() {
        this.setState({hsCodesToShow: this.state.hsCodesToShow.concat(1)}, () => {
            const {valObj} = this.state;
            valObj[this.state.hsCodesToShow.length - 1] = {};
            this.setState({valObj});
        })
    }

    async onClickSave() {
        const {companyName, country, fname, lname, errors, valObj, selectedCompanyId, hsCodesToShow} = this.state;
        const {editingOrNew, hsCodeToEdit} = this.props;
        let childErrorsObj = {};
        errors.cName = companyName.length === 0;
        errors.country = country.length === 0;
        errors.fname = fname.length === 0;
        errors.lname = lname.length === 0;
        this.setState({errors: errors});
        for (let i in errors) {
            if (errors[i]) {
                return false;
            }
        }
        for (let i in valObj) {
            let item = valObj[i];
            if (!item.hsCode) {
                childErrorsObj[i] = true;
                NotificationManager.error('HSCode is a required field');
                this.setState({childErrors: childErrorsObj});
                return false;
            } else {
                childErrorsObj[i] = false;
                this.setState({childErrors: childErrorsObj});
            }
            if (!item.type) {
                valObj[i].type = "offer";
            }
        }
        this.setState({btnLoading: true});
        if (editingOrNew === "edit") {
            this.updateHsCode(valObj[Object.keys(valObj)[0]], hsCodeToEdit.pns_id)
                .then(res => {
                    NotificationManager.success("HSCode updated successfully");
                    this.props.getData();
                    this.props.onHide();
                    this.setState({btnLoading: false});
                }).catch(err => {
                this.setState({btnLoading: false});
                NotificationManager.error('Something went wrong. Try again.');
            })

        } else {
            if (!selectedCompanyId) {
                this.setState({selectedCompanyId: "new"}, async () => {
                    let res = await this.saveHsCode(valObj[Object.keys(valObj)[0]]);
                    if(res.data && res.data.data && res.data.status === 200) {
                        let companyId = res.data.data.company_id;
                        let valObjCc = valObj;
                        delete valObjCc[Object.keys(valObj)[0]];
                        this.props.getAllClients();
                        this.setState({selectedCompanyId: companyId, valObj: valObjCc}, () => {
                            if(hsCodesToShow.length > 1) {
                                this.onClickSave();
                            } else {
                                this.setState({btnLoading: false}, () => {
                                    this.props.onHide();
                                    NotificationManager.success('HSCode added successfully');
                                    this.props.getData();
                                });
                            }
                        })
                    }
                });
            } else {
                let saveData = (item, selectedCompanyId) => this.saveHsCode(item, selectedCompanyId);
                let allPromises = Object.keys(valObj).map((item) => {
                    return saveData(valObj[item], selectedCompanyId);
                });
                Promise.all(allPromises).then(res => {
                    this.props.getData();
                    NotificationManager.success('HSCode added successfully');
                    this.setState({btnLoading: false}, () => {
                        this.props.onHide();
                    });
                });
            }
        }
    }

    saveHsCode(item, selectedCompanyId) {
        return new Promise((resolve, reject) => {
            const {companyName, fname, lname, title, country} = this.state;
            let params = new URLSearchParams();
            params.append('company_name', companyName);
            params.append('fname', fname);
            params.append('lname', lname);
            params.append('title', title);
            params.append('country', country);
            params.append('type', item.type);
            params.append('hs_codes_id', item.hsCode[0].id);
            item.target ? params.append('target_group', item.target) : void 0;
            item.specification ? params.append('specification', item.specification) : void 0;
            axios.request({
                method: "PUT",
                url: `${base_url}/company/pns/tmp/${selectedCompanyId}`,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.props.token}`
                }
            }).then(res => {
                // console.log(res, "***");
                resolve(res);
            }).catch(err => {
                console.log(err);
                resolve();
            })
        });
    }

    updateHsCode(item, id) {
        return new Promise((resolve, reject) => {
            let params = new URLSearchParams();
            params.append('type', item.type);
            params.append('target_group', item.target);
            params.append('specification', item.specification);
            item.hsCode[0].id ? params.append('hs_codes_id', item.hsCode[0].id) : void 0;
            axios.request({
                method: 'post',
                url: `${base_url}/company/pns/${this.state.selectedCompanyId}/${id}`,
                data: params,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.props.token}`
                }
            }).then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err);
                console.log(err);
            });
        })
    }

    onClickDelete() {
        const {hsCodeToEdit} = this.props;
        this.setState({deleteBtnLoading: true});
        deletePnS(hsCodeToEdit.pns_id, this.props.token)
            .then(res => {
                if (res.data.status === 200) {
                    this.props.onHide();
                    NotificationManager.success("HSCode deleted successfully");
                    this.props.getData();
                } else {
                    NotificationManager.error(res.data.errors[Object.keys(res.data.errors)[0]]);
                }
                this.setState({deleteBtnLoading: false});
            }).catch(err => {
            console.log(err);
            this.setState({deleteBtnLoading: false});
            NotificationManager.error("Something went wrong. Try again.");
        })
    }

    showCancelModal() {
        const {editingOrNew} = this.props;
        const {fname, lname, country, valObj, companyName} = this.state;
        let show = false;

        if(editingOrNew !== "edit") {
            for (let i in valObj) {
                let item = valObj[i];
                if (item.hsCode) {
                    this.setState({showCancelFallback: true}, () => console.log("hit"));
                    return;
                } else {
                    show = true;
                }
            }
            if(fname || lname || country || valObj[1] || companyName) {
                this.setState({showCancelFallback: true});
                return;
            }
            if(show) {
                this.props.onHide();
            }
        } else {
            this.props.onHide();
        }
    }

    onClickRemoveEntry(item) {
        const {hsCodesToShow, valObj} = this.state;
        const cc = hsCodesToShow.slice();
        const ccValObj = Object.assign({}, valObj);
        cc.splice(item, 1);
        delete ccValObj[item]
        this.setState({hsCodesToShow: cc, valObj: ccValObj});
    }

    render() {
        const {errors} = this.state;
        return (
            <div>
                <Modal show={this.props.show} size={'lg'} onHide={this.showCancelModal} className="CompanyServicesModal NewInviteModal">
                    <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                        <h3 style={{color: userLevelColors(this.props.level).font}}>{this.props.editingOrNew === "edit" ? "Edit Client HSCode" : "Create new Client HSCode"}</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row">
                                <div className="form-group col-lg-8 ">
                                    <input
                                        disabled={this.props.editingOrNew === "edit"}
                                        onChange={this.onChangeCompanyName}
                                        type="text"
                                        className={"form-control " + (userLevelColors(this.props.level).className) + (errors.cName ? "input-error" : '')}
                                        placeholder="Client Company Name*"
                                        value={this.state.companyName}
                                    />
                                    <div style={this.state.filteredClients.length > 0 ? {
                                        maxHeight: '200px',
                                        borderRadius: '4px',
                                        overflow: 'scroll',
                                        zIndex: '10'
                                    } : {}}
                                         className={"position-absolute mt-3 col-lg-12 pl-0 pr-4 w-100 " + (this.state.filteredClients.length > 0 ? "" : "d-none")}>
                                        <ul className="list-group w-100">
                                            {
                                                this.state.filteredClients.map((item, i) => {
                                                    return <li style={{cursor: 'pointer'}} key={i}
                                                               className="list-group-item"
                                                               onClick={this.onClickClientCompany.bind(null, item)}>{item.company_name}</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group col-lg-4 ">
                                    <select
                                        disabled={this.props.editingOrNew === "edit"}
                                        value={this.state.country}
                                        onChange={(e) => this.setState({country: e.target.value})}
                                        className={"form-control " + (userLevelColors(this.props.level).className) + +(errors.country ? "input-error" : '')}
                                    >
                                        <option selected disabled value={''}>Country*</option>
                                        {
                                            countryList.map((item, i) => {
                                                return <option key={i} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-2">
                                    <select
                                        value={this.state.title}
                                        onChange={(e) => this.setState({title: e.target.value})}
                                        className={"form-control " + (userLevelColors(this.props.level).className)}
                                        disabled={this.props.editingOrNew === "edit"}
                                    >
                                        <option disabled value={null}>Title</option>
                                        <option value="Dr.">Dr.</option>
                                        <option value="Prof.">Prof.</option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Ms.">Ms.</option>
                                        <option value="Mrs.">Mrs.</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                                <div className="form-group col-lg-5">
                                    <input
                                        disabled={this.props.editingOrNew === "edit"}
                                        type="text"
                                        className={"form-control " + (userLevelColors(this.props.level).className) + (errors.fname ? "input-error" : '')}
                                        placeholder="First Name*"
                                        value={this.state.fname}
                                        onChange={e => this.setState({fname: e.target.value})}
                                    />
                                </div>
                                <div className="form-group col-lg-5">
                                    <input
                                        disabled={this.props.editingOrNew === "edit"}
                                        type="text"
                                        className={"form-control " + (userLevelColors(this.props.level).className) + (errors.lname ? "input-error" : '')}
                                        placeholder="Last Name*"
                                        value={this.state.lname}
                                        onChange={e => this.setState({lname: e.target.value})}
                                    />
                                </div>
                            </div>
                        </form>
                        <div>
                            {
                                this.state.hsCodesToShow.map((item, i) => {
                                    return <HsCodeForm
                                        childErrors={this.state.childErrors}
                                        id={item.pns_id ? item.pns_id : i}
                                        key={i}
                                        companyServices={this.props.offerings}
                                        totalLength={this.state.hsCodesToShow.length}
                                        active={item.pns_id ? item.pns_id : i}
                                        onChangeTarget={this.onChangeTarget}
                                        onChangeSpecs={this.onChangeSpecs}
                                        onChangeHsCode={this.onChangeHsCode}
                                        onChangeType={this.onChangeType}
                                        editableItem={this.state.hsCodeToEdit}
                                        onClickRemoveItem={this.onClickRemoveEntry}
                                    />
                                })
                            }
                        </div>
                        <div
                            className={"w-100 text-center add-more-btn " + userLevelColors(this.props.level).className + (this.props.editingOrNew === "new" ? "" : "d-none")}>
                            <span onClick={this.onClickAddMoreFields}><FontAwesomeIcon icon={faPlus} size={'3x'}/></span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex justify-content-between w-100">
                            <div
                                onClick={this.onClickDelete}
                                className={this.props.editingOrNew === "edit" ? "" : "invisible"}
                                style={{cursor: "pointer"}}
                            >
                                {
                                    this.state.deleteBtnLoading ?
                                        <ButtonSpinner
                                            level={this.props.level}
                                            showLoading={true}/> :
                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                }

                            </div>
                            <div>
                                <Button className="mr-4" style={{
                                    background: userLevelColors(this.props.level).buttonBg,
                                    color: userLevelColors(this.props.level).buttonFont,
                                    borderColor: userLevelColors(this.props.level).buttonFont
                                }} variant="secondary" onClick={this.showCancelModal}>
                                    Close
                                </Button>
                                <Button
                                    style={{
                                        background: userLevelColors(this.props.level).buttonBg,
                                        color: userLevelColors(this.props.level).buttonFont,
                                        borderColor: userLevelColors(this.props.level).buttonFont
                                    }}
                                    variant="dark"
                                    onClick={this.onClickSave}
                                >
                                    {this.state.btnLoading ?
                                        <ButtonSpinner
                                            level={this.props.level}
                                            showLoading={true}
                                        />
                                        : "Save"}
                                </Button>
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
                <CancelFallback
                    onClickOk={() => this.setState({showCancelFallback: false}, this.props.onHide)}
                    show={this.state.showCancelFallback}
                    onHide={() => this.setState({showCancelFallback: false})}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    let level = state.login.level;
    return {
        token, companyIds, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HsCodeModal);