import React from "react";
import { logoutUser } from "../store/actions/loginAction";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import ClientHsCode from "../components/ClientManagement/ClientHsCode";
import JumpseatInvt from "./Jumpseat/JumpseatInvt";
import ClientsMatches from "./ClientsMatches";
import SalesPage from "./SalesPage";

class MyCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: "jumpseat",
    };
  }

  render() {
    const { eventKey } = this.state;
    return (
      <div className="page-container MyCustomers">
        <div className={"page-header mb-5"}></div>
        <div className="container my-customer-wrapper">
          <h4 className="font-weight-light mb-5">My Customers</h4>
          <Tabs
            onSelect={(e) => this.setState({ eventKey: e })}
            activeKey={eventKey}
            defaultActiveKey={eventKey}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="jumpseat" title="Jumpseat Invitations">
              <JumpseatInvt />
            </Tab>
            <Tab eventKey="hsCode" title="Customer HSCodes">
              <ClientHsCode activeKey={eventKey} />
            </Tab>
            <Tab eventKey="customerMatches" title="Customer Matches">
              <ClientsMatches activeKey={eventKey} />
            </Tab>
            <Tab
              eventKey="salesSupport"
              title="Sales Support"
              style={{ height: "auto" }}
            >
              <SalesPage />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let companyIds = state.login.company_ids;
  let level = state.login.group;
  let group = state.login.level;
  return {
    companyIds,
    level,
    group,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyCustomers)
);
