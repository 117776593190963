import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileAlt,
  faImage,
  faTimesCircle,
  faTimes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { toBase64, userLevelColors } from "../../services/globalFunctions";
import axios from "axios";
import { base_url, incotermOptions } from "../../services/const";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { MultiSelect } from "react-multi-select-component";

let searchInterval;

class NewHsCodeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      description: "",
      fileName: "",
      target: "",
      specifications: "",
      image: null,
      file: null,
      selectedOptions: {},
      hsCodeVal: "",
      hsCodes: {},
      showSearchResult: false,
      existingImageThumbnail: null,
      newImageThumbnail: [],
      type: props.typeAdding ? props.typeAdding : "search",
      quantity: "",
      weight: "",
      minUnits: "",
      length: "",
      width: "",
      height: "",
      modeOfProduction: "",
      capacity: "",
      color: "",
      minPrice: "",
      maxPrice: "",
      incoterm: [],
      "certification-offer": "false",
      "certification-search": "false",
      "season-offer": "false",
      "season-search": "false",
    };
    this.onClickListItem = this.onClickListItem.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  onClickListItem(item, hsCode) {
    const { selectedOptions } = this.state;
    if (!selectedOptions.item) {
      let obj = {
        id: hsCode,
        item: item,
      };
      this.setState(
        {
          [item + "-target"]: "",
          [item + "-specs"]: "",
          selectedOptions: obj,
          showSearchResult: false,
          hsCodeVal: "",
        },
        () => {
          this.props.onChangeHsCode(obj);
        }
      );
    } else {
      NotificationManager.error("Only one HS Code per item", "Error");
    }
    this.setState({ showSearchResult: false });
  }

  async onChangeImage(e, type) {
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large. Max size 5mb.", "Error");
    } else {
      this.setState({ image: e.target.files[0] }, () =>
        this.props.onChangeImage(type, this.state.image)
      );
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        const { newImageThumbnail } = this.state;
        newImageThumbnail.push(logo);
        this.setState({
          newImageThumbnail: newImageThumbnail,
          showFileError: false,
        });
      });
    }
  }

  render() {
    const { typeAdding, showDescriptionError, showTitleError } = this.props;

    return (
      <div className="row">
        <div className="col-lg-4">
          <div className="file-upload-container-contact-person">
            <div className="file-upload-content">
              <div>
                {this.state.existingImageThumbnail ? (
                  this.state.existingImageThumbnail.data ? (
                    <img
                      style={{ height: "65px" }}
                      src={`data:image/jpeg;base64,${this.state.existingImageThumbnail.data}`}
                      alt={"..."}
                    />
                  ) : (
                    <FontAwesomeIcon
                      size={"3x"}
                      icon={
                        this.state.newImageThumbnail.length > 0
                          ? faPlus
                          : faImage
                      }
                    />
                  )
                ) : (
                  <FontAwesomeIcon
                    size={"3x"}
                    icon={
                      this.state.newImageThumbnail.length > 0 ? faPlus : faImage
                    }
                  />
                )}
              </div>
              <p className="title pt-2 mb-1 ">Drag & Drop</p>
              <p className="sub-title mb-3 ">
                your product or service image here
              </p>
              {this.state.newImageThumbnail.length === 0 && (
                <p className="sub-title mt-1">max 5mb</p>
              )}
              <br />
              &nbsp;
            </div>
            <input
              style={{ padding: "130px 0px 12px 23%" }}
              type="file"
              id="file-upload"
              className={
                "choose-file-input " + (this.state.image ? "" : "file-selected")
              }
              onChange={(e) => this.onChangeImage(e, typeAdding)}
              accept=".png, .jpeg, .jpg"
            />
          </div>
          {this.state.newImageThumbnail.length > 0 && (
            <div>
              {this.state.newImageThumbnail.map((item, i) => {
                return (
                  <div
                    className="file-upload-content"
                    style={{
                      height: "180px",
                      width: "100%",
                      border: "2px dashed #ebebeb",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <img
                        style={{ objectFit: "cover", width: "165px" }}
                        src={`${item.data}`}
                        alt={"..."}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="file-upload-container-contact-person">
            <div className="file-upload-content">
              <FontAwesomeIcon
                size={"2x"}
                icon={this.state.file ? faCheck : faFileAlt}
              />
              <p className="title pt-2 mb-1 ">
                {this.state.file ? this.state.file.name : "Drag & Drop"}
              </p>
              {!this.state.file && (
                <p className="sub-title mb-3 ">your pdf here.</p>
              )}
              <br />
              &nbsp;
            </div>
            <input
              type="file"
              id="file-upload"
              className={
                "choose-file-input " + (this.state.file ? "" : "file-selected")
              }
              onChange={(e) =>
                this.setState(
                  { file: e.target.files[0], fileName: e.target.files[0].name },
                  () => this.props.onChangePdf(this.state.file)
                )
              }
              accept=".pdf, .csv, .xls, .xlsx"
            />
          </div>
        </div>
        <div className="col-lg-8">
          {!typeAdding && (
            <div className="form-group">
              <select
                value={this.state.type}
                onChange={(e) =>
                  this.setState({ type: e.target.value }, () =>
                    this.props.onChangeType(this.state.type)
                  )
                }
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
              >
                <option value="search">Search</option>
                <option value="offer">Offer</option>
              </select>
            </div>
          )}
          <div className="form-group">
            <input
              defaultValue={this.state.productName}
              onChange={(e) =>
                this.setState({ productName: e.target.value }, () =>
                  this.props.onChangeName(typeAdding, this.state.productName)
                )
              }
              type="text"
              className={
                `${showTitleError ? "input-error" : ""} form-control  ` +
                userLevelColors(this.props.level).className
              }
              placeholder="Product/service name"
            />
          </div>

          <div className="form-group">
            <textarea
              defaultValue={this.state.description}
              onChange={(e) =>
                this.setState({ description: e.target.value }, () =>
                  this.props.onChangeDescription(
                    typeAdding,
                    this.state.description
                  )
                )
              }
              rows={3}
              className={
                `${showDescriptionError ? "input-error" : ""} form-control  ` +
                userLevelColors(this.props.level).className
              }
              placeholder="Product/service description"
            />
          </div>
          {typeAdding !== "search" && (
            <div className="form-group">
              <input
                defaultValue={this.state.target}
                onChange={(e) =>
                  this.setState({ target: e.target.value }, () =>
                    this.props.onChangeTarget(typeAdding, this.state.target)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Target group"
              />
            </div>
          )}
          <div className="form-group">
            <input
              defaultValue={this.state.specifications}
              onChange={(e) =>
                this.setState({ specifications: e.target.value }, () =>
                  this.props.onChangeSpecs(
                    typeAdding,
                    this.state.specifications
                  )
                )
              }
              type="text"
              className={
                "form-control  " + userLevelColors(this.props.level).className
              }
              placeholder="Specification"
            />
          </div>
          {this.state.file && (
            <div className="form-group">
              <input
                defaultValue={this.state.fileName}
                onChange={(e) =>
                  this.setState({ fileName: e.target.value }, () =>
                    this.props.onChangeFileName(typeAdding, this.state.fileName)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="File name"
              />
            </div>
          )}

          {/* <div className="d-flex form-group">
            <div className="col-lg-6 pl-0">
              <input
                defaultValue={this.state.minPrice}
                onChange={(e) =>
                  this.setState({ minPrice: e.target.value }, () =>
                    this.props.onChangeMinPrice(typeAdding, this.state.minPrice)
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter min price"
              />
            </div>
            <div className="col-lg-6 pr-0">
              <input
                defaultValue={this.state.maxPrice}
                onChange={(e) =>
                  this.setState({ maxPrice: e.target.value }, () =>
                    this.props.onChangeMaxPrice(typeAdding, this.state.maxPrice)
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter max price"
              />
            </div>
          </div> */}

          {/* <div className="d-flex form-group">
            <div className="col-lg-4 pl-0">
              <input
                defaultValue={this.state.quantity}
                onChange={(e) =>
                  this.setState({ quantity: e.target.value }, () =>
                    this.props.onChangeQuantity(typeAdding, this.state.quantity)
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter quantity"
              />
            </div>
            <div className="col-lg-4">
              <input
                defaultValue={this.state.weight}
                onChange={(e) =>
                  this.setState({ weight: e.target.value }, () =>
                    this.props.onChangeWeight(typeAdding, this.state.weight)
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter weight"
              />
            </div>
            <div className="col-lg-4 pr-0">
              <input
                defaultValue={this.state.minUnits}
                onChange={(e) =>
                  this.setState({ minUnits: e.target.value }, () =>
                    this.props.onChangeMinUnits(typeAdding, this.state.minUnits)
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter min units"
              />
            </div>
          </div> */}

          {/* <div className="d-flex form-group">
            <div
              className="form-group pl-0 col-6 mb-0 d-flex"
              style={{ color: "rgba(0, 0, 0, 0.4)" }}
            >
              <div
                className="form-check-inline"
                style={{ color: "dimgray", paddingBottom: 5 }}
              >
                Certification
              </div>
              <div className="form-check form-check-inline px-2">
                <input
                  onChange={(e) =>
                    this.setState(
                      { ["certification-" + typeAdding]: e.target.value },
                      () =>
                        this.props.onChangeCertification(
                          typeAdding,
                          this.state["certification-" + typeAdding]
                        )
                    )
                  }
                  checked={this.state["certification-" + typeAdding] === "true"}
                  className="form-check-input"
                  type="radio"
                  name={typeAdding + "inlineRadioOptions"}
                  id={typeAdding + "inlineRadio1"}
                  value="true"
                />
                <label className="form-check-label mt-0" htmlFor="inlineRadio1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline px-2">
                <input
                  onChange={(e) =>
                    this.setState(
                      { ["certification-" + typeAdding]: e.target.value },
                      () =>
                        this.props.onChangeCertification(
                          typeAdding,
                          this.state["certification-" + typeAdding]
                        )
                    )
                  }
                  checked={
                    this.state["certification-" + typeAdding] === "false"
                  }
                  className="form-check-input"
                  type="radio"
                  name={typeAdding + "inlineRadioOptions"}
                  id={typeAdding + "inlineRadio2"}
                  value="false"
                />
                <label className="form-check-label mt-0" htmlFor="inlineRadio2">
                  No
                </label>
              </div>
            </div>
            {this.state["certification-" + typeAdding] === "true" && (
              <div className="form-group col-lg-6 px-0">
                <input
                  defaultValue={this.state["certificationtext-" + typeAdding]}
                  onChange={(e) =>
                    this.setState(
                      { ["certificationtext-" + typeAdding]: e.target.value },
                      () =>
                        this.props.onChangeCertificationDescription(
                          this.state["certificationtext-" + typeAdding],
                          typeAdding
                        )
                    )
                  }
                  type="text"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Certification description"
                />
              </div>
            )}
            {this.state["certification-" + typeAdding] !== "true" && (
              <div
                className="form-group pr-0 col-6 mb-0"
                style={{ color: "rgba(0, 0, 0, 0.4)" }}
              >
                <div
                  className="form-check-inline"
                  style={{ color: "dimgray", paddingBottom: 5 }}
                >
                  Seasonality
                </div>
                <div className="form-check form-check-inline px-2">
                  <input
                    onChange={(e) =>
                      this.setState(
                        { ["season-" + typeAdding]: e.target.value },
                        () =>
                          this.props.onChangeSeason(
                            typeAdding,
                            this.state["season-" + typeAdding]
                          )
                      )
                    }
                    checked={this.state["season-" + typeAdding] === "true"}
                    className="form-check-input"
                    type="radio"
                    name={typeAdding + "seasonRadioOption"}
                    id={typeAdding + "seasonality2"}
                    value="true"
                  />
                  <label
                    className="form-check-label mt-0"
                    htmlFor="seasonality1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline px-2">
                  <input
                    onChange={(e) =>
                      this.setState(
                        { ["season-" + typeAdding]: e.target.value },
                        () =>
                          this.props.onChangeSeason(
                            typeAdding,
                            this.state["season-" + typeAdding]
                          )
                      )
                    }
                    checked={this.state["season-" + typeAdding] === "false"}
                    className="form-check-input"
                    type="radio"
                    name={typeAdding + "seasonRadioOption"}
                    id={typeAdding + "seasonality2"}
                    value="false"
                  />
                  <label
                    className="form-check-label mt-0"
                    htmlFor="seasonality2"
                  >
                    No
                  </label>
                </div>
              </div>
            )}
          </div> */}

          {/* <div className="d-flex">
            {this.state["certification-" + typeAdding] === "true" && (
              <div
                className="form-group pr-0 col-6 mb-0 d-flex px-0"
                style={{ color: "rgba(0, 0, 0, 0.4)" }}
              >
                <div
                  className="form-check-inline"
                  style={{ color: "dimgray", paddingBottom: 5 }}
                >
                  Seasonality
                </div>
                <div className="form-check form-check-inline px-2">
                  <input
                    onChange={(e) =>
                      this.setState(
                        { ["season-" + typeAdding]: e.target.value },
                        () =>
                          this.props.onChangeSeason(
                            typeAdding,
                            this.state["season-" + typeAdding]
                          )
                      )
                    }
                    checked={this.state["season-" + typeAdding] === "true"}
                    className="form-check-input"
                    type="radio"
                    name={typeAdding + "seasonRadioOption"}
                    id={typeAdding + "seasonality2"}
                    value="true"
                  />
                  <label
                    className="form-check-label mt-0"
                    htmlFor="seasonality1"
                  >
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline px-2">
                  <input
                    onChange={(e) =>
                      this.setState(
                        { ["season-" + typeAdding]: e.target.value },
                        () =>
                          this.props.onChangeSeason(
                            typeAdding,
                            this.state["season-" + typeAdding]
                          )
                      )
                    }
                    checked={this.state["season-" + typeAdding] === "false"}
                    className="form-check-input"
                    type="radio"
                    name={typeAdding + "seasonRadioOption"}
                    id={typeAdding + "seasonality2"}
                    value="false"
                  />
                  <label
                    className="form-check-label mt-0"
                    htmlFor="seasonality2"
                  >
                    No
                  </label>
                </div>
              </div>
            )}
            {this.state["season-" + typeAdding] === "true" && (
              <div className="form-group col-lg-6 px-0">
                <input
                  defaultValue={this.state["seasonality-" + typeAdding]}
                  onChange={(e) =>
                    this.setState(
                      { ["seasonality-" + typeAdding]: e.target.value },
                      () =>
                        this.props.onChangeSeasonalityDescription(
                          this.state["seasonality-" + typeAdding],
                          typeAdding
                        )
                    )
                  }
                  type="text"
                  className={
                    "form-control  " +
                    userLevelColors(this.props.level).className
                  }
                  placeholder="Seasonality description"
                />
              </div>
            )}
          </div>

          <div className="d-flex align-items-center form-group">
            <div
              className="form-check-inline d-flex align-items-center"
              style={{ color: "dimgray", paddingBottom: 5 }}
            >
              Dimension
            </div>
            <div className="col-lg-2 ">
              <input
                defaultValue={this.state.length}
                onChange={(e) =>
                  this.setState({ length: e.target.value }, () =>
                    this.props.onChangeDimension(
                      typeAdding,
                      this.state.length,
                      "length"
                    )
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="L"
              />
            </div>
            <FontAwesomeIcon icon={faTimes} color={"dimgray"} />
            <div className="col-lg-2">
              <input
                defaultValue={this.state.width}
                onChange={(e) =>
                  this.setState({ width: e.target.value }, () =>
                    this.props.onChangeDimension(
                      typeAdding,
                      this.state.width,
                      "width"
                    )
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="W"
              />
            </div>
            <FontAwesomeIcon icon={faTimes} color={"dimgray"} />
            <div className="col-lg-2 ">
              <input
                defaultValue={this.state.height}
                onChange={(e) =>
                  this.setState({ height: e.target.value }, () =>
                    this.props.onChangeDimension(
                      typeAdding,
                      this.state.height,
                      "height"
                    )
                  )
                }
                type="number"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="H"
              />
            </div>
          </div>

          <div className="form-group">
            <input
              defaultValue={this.state.modeOfProduction}
              onChange={(e) =>
                this.setState({ modeOfProduction: e.target.value }, () =>
                  this.props.onChangeModeOfProduction(
                    typeAdding,
                    this.state.modeOfProduction
                  )
                )
              }
              type="text"
              className={
                "form-control  " + userLevelColors(this.props.level).className
              }
              placeholder="Enter mode of production"
            />
          </div>

          <div className="d-flex form-group">
            <div className="col-lg-6 pl-0">
              <input
                defaultValue={this.state.color}
                onChange={(e) =>
                  this.setState({ color: e.target.value }, () =>
                    this.props.onChangeColor(typeAdding, this.state.color)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter color"
              />
            </div>
            <div className="col-lg-6 pr-0">
              <input
                defaultValue={this.state.capacity}
                onChange={(e) =>
                  this.setState({ capacity: e.target.value }, () =>
                    this.props.onChangeCapacity(typeAdding, this.state.capacity)
                  )
                }
                type="text"
                className={
                  "form-control  " + userLevelColors(this.props.level).className
                }
                placeholder="Enter capacity"
              />
            </div>
          </div> */}

          <div className="form-group">
            <MultiSelect
              options={incotermOptions}
              value={this.state.incoterm}
              hasSelectAll={false}
              onChange={(e) => {
                this.setState({ incoterm: e });
                this.props.onChangeIncoterm(typeAdding, e);
              }}
              labelledBy="Incoterm"
              overrideStrings={{
                selectSomeItems:
                  "Choose terms of preferred delivery (incoterm)",
              }}
            />
          </div>
        </div>
        <hr className="seperator" style={{ width: "95%" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let level = state.login.level;
  return {
    token,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewHsCodeForm);
