import {ACTIVE_COMPANY, ACTIVE_PRODUCT, CLEAR_ROUTES, UPDATE_ROUTES} from "./actionTypes";
import {store} from "../store";
import {menuItems, routesNames} from "../../services/const";

export const updateRoutes = (ret, allRoutes) => {
    return (dispatch) => {
        let all = allRoutes.slice();
        all.push(ret);
        let sorted = [];
        let itemInMenu = false;
        Object.entries(menuItems).map(([key, value]) => {
            if(key === ret.name) {
                itemInMenu = true;

            }
        })
        Object.entries(routesNames).map(([key, value], i) => {
            let find = all.find(e => e.name === key);
            if(find) {
                sorted.push(find);
            }
        })
        if(!itemInMenu) {
            const idx = sorted.findIndex(a => a.name === ret.name);
            if(idx > -1) {
                sorted = sorted.slice(0, idx+1);
                dispatch(clearRoutes());
                dispatch(setRoutes(sorted));
            } else  {
                dispatch(setRoutes(ret));
            }
        } else {
            sorted = [sorted[0], ret];
            dispatch(clearRoutes());
            dispatch(setRoutes(sorted));
        }

    }
};

export const activeProduct = (data) => {
    return (dispatch) => {
        dispatch(setActiveProduct(data));
    }
}

export const activeCompany = (data) => {
    return (dispatch) => {
        dispatch(setActiveCompany(data));
    }
}

function setActiveCompany(ret) {
    return {
        type: ACTIVE_COMPANY,
        payload: ret
    }
}

function setActiveProduct(ret) {
    return {
        type: ACTIVE_PRODUCT,
        payload: ret
    }
}

function setRoutes(ret) {
    return {
        type: UPDATE_ROUTES,
        payload: ret
    }
}

export function clearRoutes() {
    return {
        type: CLEAR_ROUTES
    }
}
