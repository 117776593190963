import React from "react";

class MembershipAgreement extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="privacy-policy-container page-container">
        <div className="page-header mb-5"></div>
        <div className="container">
          <p>
            <strong>
              <u>
                Membership agreement of WestRing international trading &amp;
                consulting GmbH,{" "}
              </u>
            </strong>
          </p>
          <p>
            <strong>
              <u>
                vers. 3.1 8<sup>st</sup> July 2022
              </u>
            </strong>
          </p>
          <p>
            <strong>
              <u>Valid for: WorldRing the business network </u>
            </strong>
          </p>
          <ol>
            <li>
              <strong>
                <u>ACCEPTANCE OF TERMS</u>
              </strong>
            </li>
          </ol>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              WELCOME TO WorldRing the business network ("
              <strong>WorldRing</strong>"). The following sets forth the terms
              and conditions of the WorldRing Membership Agreement (this "
              <strong>Agreement</strong>") between you (&ldquo;
              <strong>Member</strong>&rdquo;) and WorldRing the business network
              with its legal representation WestRing international trading &amp;
              consulting GmbH contracting company determined in accordance with
              this clause 1.1 under which WorldRing offers you access to and use
              of t<strong>he Service</strong> through the websites, mobile
              sites, mobile applications and other portals owned, operated,
              branded or made available by WorldRing to you. <br /> As some or
              part of the Service may be supported and provided by affiliates of
              WorldRing, WorldRing may delegate some aspects of the Service to
              its affiliates. Use of the Service indicates that you accept the
              terms and conditions set forth below. If you do not accept all of
              the terms and conditions, please do not use the Service. BY
              COMPLETING THE REGISTRATION PROCESS AND CLICKING THE "I AGREE"
              BUTTON, YOU ARE INDICATING YOUR CONSENT TO BE BOUND BY THIS
              AGREEMENT AND PRIVACY POLICY WHICH ARE INCORPORATED HEREIN BY
              REFERENCE (COLLECTIVELY REFERRED TO AS THE "TERMS OF USE"). The
              WorldRing Membership Agreement will not take effect unless and
              until you have activated your Account. Terms not defined in this
              Agreement shall bear the same meaning as that contained in the
              Terms of Use.
              <br />{" "}
            </li>
            <li>
              WorldRing may amend this Agreement at any time by posting the
              amended and restated Agreement on the website. The amended and
              restated Agreement shall be effective immediately upon posting.
              Posting by WorldRing of the amended and restated Agreement and
              your continued use of the Service shall be deemed to be acceptance
              of the amended terms.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol start="2">
            <li>
              <strong>
                <u>THE SERVICE</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>
                The Service is free of charge for all users under the jumpseat
                membership level!{" "}
              </u>
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The Service will be offered for a period of 12 months, unless
              terminated in accordance with the terms of this Agreement.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The Service is only available after full registration and
              confirmation of membership level that applies. After the trial
              period, the full membership amount has to be paid to WestRing
              international trading &amp; consulting GmbH to maintain the access
              to the service. If the agreed membership fee will not received in
              time, the Service won&rsquo;t be available any longer
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The Service will have the following core features (which may be
              added to or modified, or suspended for scheduled or unscheduled
              maintenance purposes, from time to time at the sole discretion of
              WorldRing and notified to you):
              <br /> <br /> a) Company Profile - allows each Member to display
              and edit basic information about its business, such as year and
              place of establishment, estimated annual sales, number of
              employees, products and services offered, etc.
              <br /> b) Products - allows each Member to display and edit
              descriptions, specifications and images of products. The number of
              products displayed at the same time depends on the chosen
              membership level.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing may suspend or terminate all or part of the above at any
              time in its sole discretion without liability for any losses or
              damages arising out of or in connection with such suspension or
              termination.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Benefits, features and functions available to a Member may vary
              for different countries and regions.&nbsp; No warranty or
              representation is given that a particular feature or function or
              the same type and extent of features and functions will be
              available.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing shall issue a Member ID and Password (the latter shall
              be chosen by the Member during registration) to each Member to
              access the Service through such Member's Account. Each Member
              shall be solely responsible for maintaining the confidentiality of
              its Member ID and Password and for all use of and activities that
              occur under the Member ID and Password (whether such use or
              activities are authorized or not). A set of Member ID and Password
              is unique to a single Account and no Member shall share, assign or
              permit the use of its Account, Member ID or Password to another
              person, even to other individuals within the Member's own business
              entity (where applicable). Each Member acknowledges that sharing
              of its Account with other persons, or allowing multiple users
              outside of its business entity to use its Account (collectively,
              "multiple use"), may cause irreparable harm to WorldRing and each
              Member shall indemnify WorldRing against any loss or damages
              (including but not limited to loss of profits) suffered by
              WorldRing as a result of such multiple use of an Account. Each
              Member hereby undertakes to notify WorldRing immediately of any
              unauthorized use of its Account, Member ID or Password or any
              other breach of security. Each Member hereby agrees that WorldRing
              shall not be liable for any loss or damages arising from the
              Member's failure to comply with this paragraph.
              <br />{" "}
            </li>
            <li>
              WorldRing reserves the right to change, upgrade, modify, limit or
              suspend the Service or any of its related functionalities or
              applications at any time temporarily or permanently without prior
              notice. WorldRing further reserves the right but shall not be
              obliged to introduce new features, functionalities, applications
              or conditions to the Service or to future versions of the Service.
              All new features, functionalities, applications, conditions,
              modifications, upgrades and alterations shall be governed by this
              Agreement, unless otherwise stated by WorldRing.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member acknowledges that inability to use the Service wholly
              or partially for whatever reason may have adverse effects on its
              business. Each Member hereby agrees that in no event shall
              WorldRing be liable to the Member or any third parties for any
              inability to use the Service (whether due to disruption, changes
              to or termination of the Service or otherwise), any delays,
              inaccuracies, errors or omissions with respect to any
              communications or transmission or delivery of all or any part
              thereof, or any losses or damages (direct, indirect, consequential
              or otherwise) arising from the use of or inability to use the
              Service.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start="3">
            <li>
              <strong>
                <u>MEMBERSHIP LEVELS, PAYMENT AND SCOPE OF SERVICE </u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>WorldRing offers four (4) different membership levels:</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>jump seat</u> &ndash; free membership with limited access to
              the service
            </li>
          </ul>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; free
            registration and upload of product search or product offer
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; automated
            notification for new matched to your request
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>economy</u> &ndash; 750,00 EUR annual membership fee (three [3]
              months free trial period)
            </li>
          </ul>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Own log in
            and full access to the WorldRing Member Area
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free upload
            of Product details to the WorldRing Marketplace to search potential
            buyers within <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            the WorldRing Network
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free upload
            of query for business contacts to the WorldRing Marketplace to
            search for potential &nbsp;
            <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            suppliers
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Number of
            maximum Marketplace uploads at the same time: 5
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the company information of your match
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the international WorldRing-Agent contact list
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Invitation
            to the annual Meeting of all WorldRing Members
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coverage by
            the WorldRing Security Fund* (WSF) up to 5.000,- EUR against fraud
            and <br /> insolvency of other WorldRing Members.
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>business</u> &ndash; 1.500,00 EUR annual membership fee (three
              [3] months free trial period)
            </li>
          </ul>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Own log in
            and full access to the WorldRing Member Area
          </p>
          <p>
            -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free
            upload of Product details to the WorldRing Marketplace to search
            potential buyers within <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            the WorldRing Network
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free upload
            of query for business contacts to the WorldRing Marketplace to
            search for potential <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            suppliers
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Number of
            maximum Marketplace uploads at the same time: 10
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the company information of your match
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the international WorldRing-Agent, Consultant &amp; Customer
            contact list
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free
            advertising pack I (Company Logo in sidebar on marketplace)&nbsp;
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Invitation
            to the annual Meeting of all WorldRing Members
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free credit
            information about your new potential business partner**
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coverage by
            the WorldRing Security Fund* (WSF) of WorldRing up to 10.000,- EUR
            against <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            fraud and insolvency of WorldRing Members.
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of the
            WorldRing supply chain management software (if offered by your
            designated agent)
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <u>first</u> &ndash; 2.500,00 EUR annual membership fee (four [4]
              months free trial period)
            </li>
          </ul>
          <p>
            -&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Own
            log in and full access to the WorldRing Member Area
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free upload
            of Product details to the WorldRing Marketplace to search potential
            buyers within <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            the WorldRing Network
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free upload
            of query for business contacts to the WorldRing Marketplace to
            search for potential <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            suppliers
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Number of
            maximum Marketplace uploads at the same time: unlimited
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the company information of your match
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Full access
            to the international WorldRing-Agent, Consultant &amp; Customer
            contact list
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free
            advertising pack II (company logo displayed and top search results)
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Invitation
            to the annual Meeting of all WorldRing Members
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Free credit
            information about your new potential business partner**
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coverage by
            the WorldRing Security Fund* (WSF) of WorldRing up to 25.000,- EUR
            against <br />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            fraud and insolvency of WorldRing Members.
          </p>
          <p>
            - &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Use of the
            WorldRing supply chain management software (if offered by your
            designated agent)
            <br />{" "}
          </p>
          <p>
            *WSF &ndash; refer to the rules &amp; regulations of the WSF for
            full coverage!
          </p>
          <p>
            ** WorldRing uses the service of an international Database of Credit
            information. WR can only provided credit information, if these
            information are available in the Database. WR is not responsible for
            the actuality and correctness of the provided information.&nbsp;
          </p>
          <p>&nbsp;</p>
          <ul>
            <li>
              All memberships come with a free trial period as shown in the
              overview of membership levels. The free trial period can only be
              used once for each Company.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              After the date of expiry of the free trial period, the user
              account will be blocked, if the annual membership fee has not been
              paid. Effective date is the date of the arriving of the money on
              the account. All user details will remain saved.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The membership level can be upgraded at any time during the active
              period of the Member.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>WorldRing offers the following payment methods:</li>
          </ul>
          <p>&nbsp;</p>
          <p>- Credit card</p>
          <p>- PayPal</p>
          <p>- Invoice</p>
          <p>&nbsp;</p>
          <ul>
            <li>
              <strong>Subscription &amp; cancellation terms:</strong>
            </li>
          </ul>
          <p>
            <strong>
              Your subscription begins as soon as your initial choice of paid
              membership has been made and the terms of use has been accepted.
              You will be charged, in one lump sum, the annual rate stated at
              the time of purchase, plus applicable taxes.{" "}
              <u>
                Your subscription will automatically renew on your annual
                renewal date until you cancel
              </u>
              . You authorize us to store your payment method(s) and to
              automatically charge your payment method(s) every year until you
              cancel.
            </strong>
          </p>
          <p>
            <strong>
              We will automatically charge you the then-current rate for your
              plan, plus applicable taxes (such as VAT or GST if the rate does
              not include it), every year upon renewal until you cancel. We may
              change your plan&rsquo;s rate each annual renewal term, and we
              will notify you of any rate change with the option to cancel. If
              the applicable VAT or GST rate (or other included tax or duty)
              changes during your one-year term, we will accordingly adjust the
              tax-inclusive price for your plan mid-term on your next billing
              date.
            </strong>
          </p>
          <p>
            <strong>
              If your primary payment method fails, you authorize us to charge
              any other payment method in your account. If you have not provided
              us a backup payment method(s) and you fail to provide payment, or
              if all payment methods in your account fail, we may suspend your
              subscription.{" "}
            </strong>
          </p>
          <p>
            <strong>
              For European Economic Area customers, your bank may require you to
              authenticate your initial purchase using a password, a one-time
              code sent to your mobile number, or biometric recognition. When
              you authenticate, you also authorize us to charge your payment
              method for your additional purchases without providing us further
              payment information or other instructions (i.e., we will initiate
              future payments independently). Such additional purchases may
              occur when we automatically charge your payment method in
              connection with a recurring subscription or when you upgrade your
              membership level.
            </strong>
          </p>
          <p>
            <strong>
              <u>Cancellation Terms</u>
            </strong>
            <strong>
              <br /> You can cancel your subscription anytime via your WorldRing
              member profile or by contacting Customer Support @{" "}
            </strong>
            <a href="mailto:info@worldring.org">
              <strong>info@worldring.org</strong>
            </a>
          </p>
          <ol start="4">
            <li>
              <strong>
                <u>MEMBER RESPONSIBILITIES</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member hereby represents, warrants and agrees to (a) provide
              true, accurate, current and complete information about itself and
              its business references as may be required by WorldRing and (b)
              maintain and promptly amend all information to keep it true,
              accurate, current and complete. To the extent permitted under
              applicable laws, each Member hereby grants an irrevocable,
              perpetual, worldwide and royalty-free, sub-licensable (through
              multiple tiers) license to WorldRing to display and use all
              information provided by such Member in accordance with the
              purposes set forth in this Agreement and to exercise the
              copyright, publicity, and database rights you have in such
              material or information, in any media now known or not currently
              known. Information that is protected under data protection laws
              will only be used and kept in compliance with those laws.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member hereby represents, warrants and agrees that the use by
              such Member of the Service and the Sites shall not:
              <br /> <br /> a) contain fraudulent information or make fraudulent
              offers of items or involve the sale or attempted sale of
              counterfeit or stolen items or items whose sales and/or marketing
              is prohibited by applicable law, or otherwise promote other
              illegal activities;
              <br /> <br /> b) be part of a scheme to defraud other Members or
              other users of the Sites or for any other unlawful purpose;
              <br /> <br /> c) infringe or otherwise abet or encourage the
              infringement or violation of any third party's copyright, patent,
              trademarks, trade secret or other proprietary right or rights of
              publicity and privacy or other legitimate rights;
              <br /> <br /> d) impersonate any person or entity, misrepresent
              yourself or your affiliation with any person or entity;
              <br /> <br /> e) violate any applicable law, statute, ordinance or
              regulation (including without limitation those governing export
              control, consumer protection, unfair competition,
              anti-discrimination or false advertising);
              <br /> <br /> f) contain information that is defamatory,
              libellous, unlawfully threatening or unlawfully harassing;
              <br /> <br /> g) contain information that is obscene or contain or
              infer any pornography or sex-related merchandising or any other
              content or otherwise promotes sexually explicit materials or is
              otherwise harmful to minors;
              <br /> <br /> h) promote discrimination based on race, sex,
              religion, nationality, disability, sexual orientation or age;
              <br /> <br /> i) contain any material that constitutes
              unauthorized advertising or harassment (including but not limited
              to spamming), invades anyone's privacy or encourages conduct that
              would constitute a criminal offence, give rise to civil liability,
              or otherwise violate any law or regulation;
              <br /> <br /> j) involve attempts to copy, reproduce, exploit or
              expropriate WorldRing's various proprietary directories, databases
              and listings;
              <br /> <br /> k) involve any computer viruses or other destructive
              devices and codes that have the effect of damaging, interfering
              with, intercepting or expropriating any software or hardware
              system, data or personal information; and
              <br /> <br /> l) involve any scheme to undermine the integrity of
              the computer systems or networks used by WorldRing and/or any user
              of the Service and no Member shall attempt to gain unauthorized
              access to such computer systems or networks;
              <br /> <br /> m) link directly or indirectly to or include
              descriptions of goods or services or other materials that violate
              any law or regulation or are prohibited under this Agreement or
              the Terms of Use; or
              <br /> <br /> n) otherwise create any liability for WorldRing or
              its affiliates.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member represents, warrants and agrees that with regards to
              information about or posted on behalf of any business referee, it
              has obtained all necessary consents, approvals and waivers from
              such referee to (a) act as such Member's business referee; (b) to
              post and publish their contact details and information, reference
              letters and comments on their behalf; and (c) that third parties
              may contact such business referees to support claims or statements
              made about the Member. Each Member further warrants that all
              reference letters and comments are true and accurate and hereby
              waives all requirements for such Member's consent to be obtained
              before third parties may contact the business referees.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Member shall not take any action which may undermine the integrity
              of WorldRing's feedback system, such as leaving positive feedback
              for himself using secondary Member IDs or through third parties or
              by leaving unsubstantiated negative feedback for another Member.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member acknowledges and agrees that WorldRing reserves the
              right to, but shall not be required to actively monitor nor
              exercise any editorial control whatsoever over the content of any
              message or other material or information created, obtained or
              accessible through the Service. WorldRing does not endorse, verify
              or otherwise certify the contents of any comments or other
              material or information made by any Member. Each Member is solely
              responsible for the contents of their communications and may be
              held legally liable or accountable for the content of their
              comments or other material or information.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member represents, warrants and agrees that it has obtained
              all necessary third party licenses and permissions and shall be
              solely responsible for ensuring that any material or information
              it posts on the Sites or provides to WorldRing or authorizes
              WorldRing to display does not violate the copyright, patent,
              trademark, trade secrets or any other personal or proprietary
              rights of any third party or is posted with the permission of the
              owner(s) of such rights. Each Member further represents, warrants
              and agrees that it has the right and authority to sell, distribute
              or offer to sell or distribute the products described in the
              material or information it posts on the Sites or provides to
              WorldRing or authorizes WorldRing to display.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If any Member breaches the representations, warranties and
              covenants of paragraphs 3.1, 3.2, 3.3, 3.4, 3.5 or 3.6 above, or
              if WorldRing has reasonable grounds to believe that such Member is
              in breach of such representations, warranties and covenants, or if
              upon complaint or claim from any other Member or third party,
              WorldRing has reasonable grounds to believe that such Member has
              wilfully or materially failed to perform its contract with such
              third party including without limitation where a Member who
              supplies products or services using the Sites and Services has
              failed to deliver any items ordered by such third party after
              receipt of the purchase price, or where such Member has delivered
              the items that materially fail to meet the terms and descriptions
              outlined in its contract with such third party, or if WorldRing
              has reasonable grounds to believe that such Member has used a
              stolen credit card or other false or misleading information in any
              online transaction, WorldRing has the right to suspend or
              terminate the Service and all Member Benefits with respect to such
              Member without compensation and without liability for any losses
              or damages arising out of or in connection with such suspension or
              termination, and restrict or refuse any and all current or future
              use of the Service or any other services that may be provided by
              WorldRing. Further, WorldRing reserves the right in its sole
              discretion to place restrictions on the number of product listings
              that a Member can post on the Sites for such duration as WorldRing
              may consider appropriate, and to remove any material it reasonably
              believes that is unlawful, could subject WorldRing to liability,
              violates this Agreement or the Terms of Use or is otherwise found
              inappropriate in WorldRing's opinion. WorldRing reserves the right
              to cooperate fully with governmental and regulatory authorities,
              law enforcement bodies, private investigators and/or injured third
              parties in the investigation of any suspected criminal or civil
              wrongdoing. Further, to the extent permitted under applicable laws
              and policies, WorldRing may disclose the Member's identity,
              contact information and/or information regarding the Member's
              account(s), transactions or activities carried out on or via the
              Sites, if requested by a government, regulatory or law enforcement
              body or an injured third party, or as a result of a subpoena or
              other legal action, and WorldRing shall not be liable for damages
              or results thereof and Member agrees not to bring any action or
              claim against WorldRing for such disclosure. In connection with
              any of the foregoing, WorldRing may suspend or terminate the
              Account of any Member as WorldRing deems appropriate in its sole
              discretion.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member agrees to indemnify WorldRing, its employees, agents
              and representatives and to hold them harmless, from any and all
              losses, claims and liabilities (including legal costs on a full
              indemnity basis) which may arise from its submissions, posting of
              materials or deletion thereof, from such Member's use of the
              Service or from such Member's breach of this Agreement or the
              Terms of Use. Each Member further agrees that WorldRing is not
              responsible, and shall have no liability to it or anyone else for
              any material posted by such Member or third parties, including
              fraudulent, untrue, misleading, inaccurate, defamatory, offensive
              or illicit material and that the risk of damage from such material
              rests entirely with each Member. WorldRing reserves the right; at
              its own expense, to assume the exclusive defence and control of
              any matter otherwise subject to indemnification by the Member, in
              which event the Member shall cooperate with WorldRing in asserting
              any available defences.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ul>
            <li>
              Each Member is obliged to pay the agreed annual membership fee in
              time to keep access and use of the Service
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol start="5">
            <li>
              <strong>
                <u>USE OF DISCUSSION BOARDS ON THE SITE</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing provides its Members use of discussion boards on the
              Sites free of charge to promote and encourage open, honest and
              respectful communication between all of our Members.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member acknowledges that all data, text, software, music,
              sound, photographs, graphics, video, messages or other materials
              ("Content"), whether publicly posted or privately transmitted
              through a discussion board on the Sites, are the sole
              responsibility of such Member from whom the Content originated.
              This means that the posting Member, and not WorldRing, is entirely
              responsible for all Content that is uploaded or posted via our
              discussion boards on the Sites. WorldRing does not control the
              Content posted via discussion boards and therefore does not
              guarantee the accuracy, integrity or quality of such Content.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing reserves the right to, but shall have no obligation to,
              delete or edit any postings in its sole discretion without prior
              notice. WorldRing may but shall not be obliged to monitor posting
              activities of any Member who is in breach of this Agreement and
              may restrict their ability to post messages on the discussion
              boards on the Sites. Under no circumstances will WorldRing be
              liable in any way for any Content, including (without limitation)
              any errors or omissions in any Content, or for any loss or damage
              of any kind incurred as a result of the use of the discussion
              boards by such Member. Each Member agrees to evaluate and bear all
              risks associated with the use of any Content including any
              reliance on its accuracy or completeness. Each Member understands
              that by using the WorldRing discussion boards on the Sites, such
              Member may be exposed to Content that is offensive, indecent or
              objectionable.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Without prejudice to each Member's responsibilities under clause 3
              of this Agreement, each Member agrees not to use the discussion
              boards on the Sites to:
              <br /> <br /> a) upload, post or e-mail any Content that is
              unlawful, harmful, threatening, abusive, harassing, tortuous,
              defamatory, vulgar, obscene, libellous, invasive of another's
              privacy, hateful, or racially, ethnically or otherwise
              objectionable;
              <br /> <br /> b) harm minors in any way;
              <br /> <br /> c) impersonate any person or entity, falsely state
              or otherwise misrepresent your affiliation with a person or entity
              or disguise the origin of any Content;
              <br /> <br /> d) "stalk" or otherwise harass another;
              <br /> <br /> e) to use the personal data about other users for
              purposes unrelated to the discussion board or to disclose personal
              data on the discussion board about third parties without their
              authorization
              <br /> <br /> f) upload, post or e-mail any Content that you do
              not have a right to transmit under any law or under contractual or
              fiduciary relationships;
              <br /> <br /> g) upload, post or e-mail any Content that infringes
              any intellectual property rights or other legitimate rights of any
              party;
              <br /> <br /> h) upload, post or e-mail any unsolicited or
              unauthorised advertising, promotional materials, "junk mail",
              "spam", "chain letters", or any other form of solicitation;
              <br /> <br /> i) upload, post or e-mail any Content that contains
              computer viruses or any other computer code, files or programs
              designed to interrupt, destroy or limit the functionality of any
              computer software, hardware or telecommunications equipment;{" "}
              <br /> <br /> j) upload, post or e-mail any Content that contains
              a complaint regarding WorldRing's services or refers to such a
              complaint on the Sites or to any other Members; any such complaint
              must be directed to the customer service e-mail on the Sites; or
              <br /> <br /> k) violate any applicable national or internal laws
              or regulations.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member acknowledges that WorldRing does not pre-screen
              Content but that WorldRing shall have the right (though not the
              obligation) in its sole discretion to move, modify or remove any
              Content that is posted or
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              To the extent permitted under applicable laws, each Member grants
              to WorldRing a perpetual, world-wide, royalty-free irrevocable,
              non-exclusive licence (including the right to sub-license through
              multiple tiers) to use, reproduce, modify, adapt, publish,
              translate, create derivative works from, distribute, perform and
              display any Content (in whole or part) such Member uploaded,
              posted or supplied to WorldRing for posting on the Sites and/or to
              incorporate such Content in other works in any form, media or
              technology now known or developed. Information that is protected
              under data protection laws will only be used and kept in
              compliance with those laws.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Each Member shall indemnify and hold WorldRing and its
              subsidiaries, affiliates, employees, officers, agents or partners
              harmless from and against any direct or indirect loss or damage
              (including consequential loss and loss of profits, goodwill or
              business opportunities) arising from any third party claim in
              relation to any Content such Member uploaded, posted or e-mailed
              on or through the discussion boards on the Sites, such Member's
              use of the discussion boards on the Sites, or such Member's breach
              of the provisions set out in clause 4.4.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              On being made aware of any such breaches, WorldRing may ban,
              delete or prohibit any Content that relates to those breaches or
              that WorldRing in its sole discretion consider to be harmful to
              the public or the rights of WorldRing or any of its affiliates,
              licensors, partners or Members.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing reserves the right to take whatever action it deems
              necessary to prevent a Member's breach of clause 5.4 including the
              following:
              <br /> <br /> a) issue a warning letter to the relevant Member
              (where the breaches are deemed by WorldRing to be minor); or{" "}
              <br /> <br /> b) ban the relevant Member from discussion boards on
              the Sites (where the breaches are deemed by WorldRing to be
              major).
              <br /> <br /> All incidents will be logged and WorldRing's
              decision shall be final in all such cases.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              All information and/or other Content posted on the Sites by the
              WorldRing service team or by Members or third party partners is
              supplied for information purposes only and shall not under any
              circumstances be construed as legal and/or business advice or a
              legal opinion. Members are encouraged to seek independent
              professional advice in such situations.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol start="6">
            <li>
              <strong>
                <u>LIMITATION OF LIABILITY</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICE IS PROVIDED ON
              AN "AS IS" AND "AS AVAILABLE" BASIS, AND WORLDRING HEREBY
              EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
              INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF CONDITION, QUALITY,
              DURABILITY, PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR
              FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. ALL SUCH
              WARRANTIES, REPRESENTATIONS, CONDITIONS, UNDERTAKINGS AND TERMS
              ARE HEREBY EXCLUDED.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, WORLDRING MAKES NO
              REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY, ACCURACY,
              RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR CURRENTNESS OF
              ANY INFORMATION PROVIDED ON OR THROUGH THE SITES.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Any material downloaded or otherwise obtained through the access
              to or use of the Sites or Service is done at each Member's sole
              discretion and risk and each Member is solely responsible for any
              damage to its computer system or any loss of data that results
              from the download of any such material. No advice or information,
              whether oral or written, obtained by any Member from WorldRing or
              through or from the Service shall create any warranty not
              expressly stated in this Agreement.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The Sites may make available to User services or products provided
              by independent third parties.&nbsp; No warranty or representation
              is made with regard to such services or products.&nbsp; In no
              event shall WorldRing or its affiliates be held liable for any
              such services or products.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Under no circumstances shall WorldRing be held liable for an delay
              or failure or disruption of the Service resulting directly or
              indirectly from acts of nature, forces or causes beyond its
              reasonable control, including without limitation, Internet
              failures, computer, telecommunications or any other equipment
              failures, electrical power failures, strikes, labor disputes,
              riots, insurrections, civil disturbances, shortages of labor or
              materials, fires, flood, storms, explosions, Acts of God, war,
              governmental actions, orders of domestic or foreign courts or
              tribunals or non-performance of third parties.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              To the extent permitted by law, WorldRing shall not be liable for
              any special, direct, indirect, punitive, incidental or
              consequential damages or any damages whatsoever (including but not
              limited to damages for loss of profits or savings, business
              interruption, loss of information), whether in contract,
              negligence, tort or otherwise or any other damages resulting from
              any of the following:
              <br /> <br /> a) the use or the inability to use the Service;
              <br /> <br /> b) any defect in goods, samples, data, information
              or services purchased or obtained from a Member or a third-party
              service provider through the Sites;
              <br /> <br /> c) unauthorized access by third parties to data or
              private information of any Member;
              <br /> <br /> d) statements or conduct of any user of the Sites;
              or
              <br /> <br /> e) any other matter relating to the Sites or Service
              however arising, including negligence.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol start="7">
            <li>
              <strong>
                <u>INTELLECTUAL PROPERTY RIGHTS</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing is the sole owner or lawful licensee of all the rights
              to the Service. The Service embodies trade secrets and
              intellectual property rights protected under worldwide copyright
              and other laws. All title, ownership and intellectual property
              rights in the Service shall remain with WorldRing, its affiliates
              or licensors, as the case may be. All rights not otherwise claimed
              under this Agreement or by WorldRing are hereby reserved.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              "WORLDRING" and related icons and logos are registered trademarks
              or trademarks or service marks of -WestRing international trading
              &amp; consulting GmbH and its affiliates in various jurisdictions
              and are protected under applicable copyright, trademark and other
              proprietary rights laws. The unauthorized copying, modification,
              use or publication of these marks is strictly prohibited.
            </li>
          </ul>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <ol start="8">
            <li>
              <strong>
                <u>GENERAL</u>
              </strong>
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>
              This Agreement and the Terms of Use constitute the entire
              agreement between the Member and WorldRing with respect to and
              governs the use of the Service, superseding any prior written or
              oral agreements in relation to the same subject matter herein.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing and the Member are independent contractors, and no
              agency, partnership, joint venture, employee-employer or
              franchiser-franchisee relationship is intended or created by this
              Agreement.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              If any provision of this Agreement is held to be invalid or
              unenforceable, such provision shall be struck and the remaining
              provisions shall be enforced.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              Headings are for reference purposes only and in no way define,
              limit, construe or describe the scope or extent of such section.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing's failure to enforce any right or failure to act with
              respect to any breach by a Member under this Agreement will not
              waive that right nor waive WorldRing's right to act with respect
              with subsequent or similar breaches.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              WorldRing shall have the right to assign this Agreement (including
              all of its rights, titles, benefits, interests, and obligations
              and duties in this Agreement) to any person or entity (including
              any affiliates of WorldRing). The Member may not assign, in whole
              or part, this Agreement to any person or entity.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              THIS AGREEMENT SHALL BE GOVERNED BY THE LAWS OF THE FEDERAL
              REPUBLIC OF GERMANY
            </li>
          </ul>

          <ul style={{ marginTop: "70px" }}>
            <strong>
              <u>Privacy statement – data protection</u>
            </strong>
          </ul>
          <ol start="1">
            <li>
              <strong>Data protection at a glance</strong>
            </li>
            <p>
              <br />
              General remarks
              <br />
              <br />
              The following information provides a simple overview of what your
              personal data is passes when you visit this website. Personal data
              are all data with which you can be personally identified. For
              detailed information on data protection you will find our privacy
              statement under this document.
              <br />
              <br />
              Collection of data on this website
              <br />
              <br />
              Who is responsible for collecting data on this website?
              <br />
              <br />
              The data processing on this website is carried out by the website
              provider. His contact details see the imprint of this website.
              <br />
              <br />
              How do we collect your data?
              <br />
              <br />
              On the one hand, your data are collected by communicating them to
              us. This may include, for example:
            </p>
          </ol>
          <ul>
            <li>
              <strong>Trade data that you enter in a contact form.</strong>
            </li>
            <p>
              <br />
              Other data will be automatically or with your consent when
              visiting the website through our IT systems recorded. These are
              mainly technical data (e.g. web browsers, operating system or time
              page views). This data will be collected automatically as soon as
              you enter this website.
              <br />
              <br />
              What is the purpose of your data?
              <br />
              <br />
              Part of the data is collected to ensure that the website is made
              available correctly. Other Data can be used to analyse your user
              behaviour.
              <br />
              <br />
              What rights do you have in relation to your data?
              <br />
              <br />
              You have the right at any time to obtain, free of charge,
              information on the origin, recipient and purpose of your stored
              personal data. You also have the right to rectify or To request
              the deletion of such data. If you have given your consent to data
              processing, you can withdraw this consent at any time for the
              future. You also have the right to: to request the restriction of
              the processing of your personal data in certain circumstances.
              <br />
              <br />
              You also have the right to lodge a complaint with the competent
              supervisory authority. If you have any questions about data
              protection, you can do so at any time at the following address:
              info@worldring.org
            </p>
          </ul>
          <br />
          <ol start="2">
            <li>
              <strong>Hosting and Content Delivery Networks (CDN)</strong>
            </li>
            <p>
              <br />
              External hosting
              <br />
              <br />
              This website is hosted by an external service provider (Hoster).
              The personal data which collected on this website will be stored
              on the hoster’s servers. This may be done by:
            </p>
          </ol>
          <ul>
            <li>
              IP addresses, contact requests, meta- and communication data,
              contract data, contact details;
            </li>

            <li>
              IP addresses, contact requests, meta- and communication data,
              contract data, contact details;
            </li>
          </ul>
          <ul>
            <p>
              The hoster will be used for the performance of the contract
              vis-à-vis our potential and existing customers (Article 6(1)(b)
              GDPR) and in the interests of safe, fast and efficient Provision
              of our online offer by a professional provider (Article 6(1)(f)
              GDPR). Our hoster will process your data only to the extent
              necessary to fulfil his/her obligations necessary and follow our
              instructions with regard to these data.
              <br />
              <br />
              We use the following hosters:
              <br />
              <br />
              1 & 1 IONOS SE
              <br />
              Elgendorfer Str. 57
              <br />
              56410 Montabaur
              <br />
              <br />
              Conclusion of a contract for the processing of contracts
              <br />
              <br />
              In order to ensure data-protection-compliant processing, we have a
              contract for:
              <br />
              <br />
              Contract processing closed with our host.
              <br />
            </p>
          </ul>
          <br />
          <ol start="3">
            <li>
              <strong>General Instructions and Mandatory Information</strong>
            </li>
            <p>
              <br />
              External hosting
              <br />
              <br />
              The operators of these pages take the protection of your personal
              data very seriously. We are dealing with your personal data
              confidential and in accordance with data protection law; and this
              privacy statement. When you use this website, various personal
              data are collected. Personal data are data that allow you to be
              personally identified. The present Privacy statement explains
              which data we collect and what we use for. It also explains how
              and for what purpose. Please note that data transmission on the
              Internet (e.g. in the case of e-mail communication) may have
              security flaws. Full protection of the data against access by
              third parties is not possible
              <br />
              <br />
              <strong>Note on the responsible body</strong>
              <br />
              <br />
              The Controller on this website is:
              <br />
              <br />
              Westring international trading & consulting GmbH
              <br />
              Am Herrenlandpark 9<br />
              41379 Brüggen
              <br />
              Telephone: + 49 2163 888 14 60
              <br />
              E-mail: Info@westringtc.de
              <br />
              <br />
              The responsible body is the natural or legal person who, alone or
              jointly with others, through the purposes and means of processing
              personal data (e.g. names, e-mail addresses, etc.) decided.
              <br />
              <br />
              Data retention period
              <br />
              <br />
              Unless a more specific retention period has been specified in this
              privacy statement, your personal data in us until the purpose for
              which the data are processed ceases to exist. If you make a
              justified request for deletion or withdraw consent to data
              processing your data will be deleted unless we have other legally
              permissible reasons for storing your personal data (e.g. tax or
              commercial retention periods); in the latter case, the
              cancellation shall take place after those reasons have ceased to
              exist.
              <br />
              <br />
              Note on the transfer of data to the US
              <br />
              <br />
              Our website includes tools from companies based in the US. When
              these tools are active and can provide your personal data to the
              US servers of the companies concerned to be passed on. Please note
              that the US is not a safe third country within the meaning of EU
              data protection law for the purposes of this Regulation. US
              companies are obliged to provide personal data to:
              <br />
              <br />
              To issue security authorities without you being able to take legal
              action against them as a person concerned. It cannot therefore be
              ruled out that U.S. authorities (e.g. secret services) might use
              them on US servers process, analyse and permanently store existing
              data for monitoring purposes. As we do not influence these
              processing activities.
              <br />
              <br />
              Withdrawal of your consent to data processing.
              <br />
              Many data processing operations are possible only with your
              explicit consent. You can withdraw consent that has already been
              given at any time. The legality of the measures taken prior to
              revocation Data processing shall not be affected by revocation.
              <br />
              <br />
              Right to object to the collection of data in specific cases and
              to:
              <br />
              <br />
              Direct mail (Art. 21 GDPR)
              <br />
              <br />
              IF THE DATA PROCESSING IS CARRIED OUT ON THE BASIS OF ARTICLE
              6(1)(C) E OR F GDPR THEY SHALL AT ANY TIME HAVE THE RIGHT, FOR
              REASONS RELATING TO THEIR PARTICULAR CIRCUMSTANCES, TO WHERE THE
              PROCESSING OF THEIR PERSONAL DATA GIVES RISE TO A SITUATION WHERE
              TO LODGE AN APPEAL; THE SAME APPLIES TO A MEASURE BASED ON THOSE
              PROVISIONS PROFILING. THE LEGAL BASIS ON WHICH A PROCESSING
              OPERATION IS BASED;
              <br />
              <br />
              READ THIS PRIVACY STATEMENT IF THEY LODGE AN APPEAL, WILL WE NO
              LONGER PROCESS YOUR PERSONAL DATA, UNLESS WE CAN HAVE COMPELLING
              LEGITIMATE GROUNDS FOR PROCESSING PROVIDE EVIDENCE WHICH OVERRIDES
              THEIR INTERESTS, RIGHTS AND FREEDOMS OR WHICH PROCESSING FOR THE
              INVOCATION, EXERCISE OR DEFENCE OF LEGAL CLAIMS (OPPOSITION UNDER
              ARTICLE 21(1) GDPR). THEIR PERSONAL DATA ARE PROCESSED FOR DIRECT
              MARKETING PURPOSES THEY SHALL HAVE THE RIGHT TO OBJECT TO THE
              PROCESSING AT ANY TIME
              <br />
              <br />
              PERSONAL DATA CONCERNED FOR THE PURPOSE OF SUCH ADVERTISING TO BE
              LODGED; THIS ALSO APPLIES TO PROFILING IN SO FAR AS SUCH DIRECT
              MARKETING IN LINKED. IF THEY OBJECT, THEIR PERSONAL DATA WILL
              SUBSEQUENTLY NO LONGER USED FOR DIRECT MARKETING PURPOSES
              (OPPOSITION UNDER ARTICLE 21(2) OF THE GDPR).
              <br />
              <br />
              Right of appeal to the competent supervisory authority
              <br />
              <br />
              In the event of an infringement of the GDPR, data subjects have
              the right to appeal against Supervisory authority, in particular
              in the Member State of habitual residence, of their place of work
              or the place of the suspected infringement. The right of appeal
              shall be without prejudice to administrative or judicial remedies.
              <br />
              <br />
              Right to data portability
              <br />
              <br />
              You have the right to data that we have based on your consent or
              in the performance of a contract process automatically, per se or
              to a third party, in a commonly used, machine-readable format to
              be handed over. Provided that you transfer the data directly to
              another controller this shall be done only to the extent
              technically feasible.
              <br />
              <br />
              SSL or TLS encryption
              <br />
              <br />
              This page uses this page for security reasons and to protect the
              transmission of confidential content, such as Example of orders or
              requests you send to us as a page operator, an SSL or TLS
              encryption. An encrypted connection can be seen from the fact that
              the address line of the browser of Change “http://” to “” and use
              the lock icon in your browser line.
              <br />
              <br />
              If the SSL or TLS encryption is enabled, the data you send to us
              will not be able to be read by third parties Access, erasure and
              rectification. You are entitled at any time, within the limits of
              the applicable legal provisions, to free of charge access to your
              personal data stored, their origin and recipients and the purpose
              of the data processing and, where appropriate, the right to
              rectify or erase such data. To that end, and if you have any
              further questions about personal data, please contact the
              following address: info@worldring.org
              <br />
              <br />
              Right to restriction of processing
              <br />
              <br />
              You have the right to request restriction of the processing of
              your personal data. Please do not hesitate to contact us at the
              address given in the press. The law there is a restriction of
              processing in the following cases:
            </p>
          </ol>
          <ul>
            <li>
              If you contest the accuracy of your personal data held by us, we
              need time to verify this. For the duration of the test, you are
              entitled to request restriction of the processing of your personal
              data
            </li>
            <li>
              If the processing of your personal data was unlawful, you can
              require restriction of data processing instead of erasure.
            </li>
            <li>
              If we no longer need your personal data, but to exercise it you
              need to defend or assert legal claims, you have the right to
              replace the obtain the restriction of the processing of your
              personal data
            </li>
            <li>
              If you have lodged an objection under Article 21(1) of the GDPR, a
              balance must be struck between your and our interests. As long as
              it is not known who’s interests above all, you have the right to
              restrict the processing of your personal data to be demanded.
            </li>
            <br />
            <p>
              If you have restricted the processing of your personal data, you
              may with the exception of storage – only with your consent or for
              invocation, exercise or Defending legal claims or protecting the
              rights of another natural or legal person or for reasons of
              important public interest of the European Union; or processed by a
              Member State.
            </p>
          </ul>
          <br />
          <ol start="4">
            <li>
              <strong>Collection of data on this website</strong>
            </li>
            <br />
            <p>
              Cookies
              <br />
              <br />
              Our websites use cookies. Cookies are small text files and address
              that do not cause damage to their terminal equipment. They will
              either be held temporarily for the duration of a meeting. (Session
              cookies) or permanently (permanent cookies) stored on your device.
              Session cookies will be automatically deleted at the end of your
              visit. Permanent cookies remain on your device saved until you
              delete it yourself or automatically delete it by your web browser.
              In some cases, third party cookies can also be stored on your
              device if you enter our side (third party cookies). These allow us
              or you to use certain third party services (e.g. cookies for
              processing payment services). Cookies have different functions.
              Many cookies are technically necessary as certain website
              functions would not function without them (e.g. shopping basket
              function or display of videos). Other cookies are used to evaluate
              user behaviour or to display advertisements. Cookies needed to
              perform the electronic communication process (necessary cookies)
              or provide some of the functionalities you want (functional
              cookies, e.g. basket function) or to optimise the website (e.g.
              cookies to measure the web audience) are required to be stored on
              the basis of Article 6(1)(f) of the GDPR, provided that no other
              Legal basis. The website provider has a legitimate interest in
              storing cookies to ensure that their services are technically
              accurate and optimised. Provided that Consent to the storage of
              cookies has been sought, the storage of the relevant Cookies
              solely on the basis of this consent (Article 6(1)(a) GDPR); The
              consent is revocable at any time. You can set your browser so that
              you can be informed about the setting of cookies. Allow cookies
              only on a case-by-case basis, exclude the acceptance of cookies
              for specific cases or generally and automatically delete the
              cookies when the browser is closed. At the Deactivation of cookies
              may limit the functionality of this website. If cookies are used
              by third parties or for analytical purposes, we will inform
              separately in the context of this privacy statement and, if
              necessary, request consent.
              <br />
              <br />
              Contact form
              <br />
              <br />
              If you send us questions via the contact form, you will find your
              information from the request form including the contact details
              you provide for the processing of the request and saved for
              follow-up questions. We do not provide these data without your
              further consent. These data will be processed on the basis of
              Article 6(1)(b) of the GDPR, provided that your request is
              accompanied by related to the performance of a contract or for the
              implementation of pre-contractual measures (Goods) above. In all
              other cases, the processing is based on our legitimate interest in
              the effective handling of enquiries addressed to us (Article
              6(1)(f) GDPR) or on your request Consent (Article 6(1)(a) GDPR) if
              requested. The data you entered in the contact form will remain
              with us until you delete request to withdraw your consent to
              storage or remove the purpose for data storage (e.g. after the
              processing of your request has been completed). Mandatory legal
              provisions —in particular, retention periods remain unaffected.
              <br />
              <br />
              Request by e-mail, telephone or fax
              <br />
              <br />
              If you contact us by e-mail, telephone or fax, your request,
              including all of them, will personal data emerging (name, request)
              for the purpose of handling your request saved and processed by
              us. If you do not give your consent we will not pass on those
              details to others. These data will be processed on the basis of
              Article 6(1)(b) of the GDPR, provided that your request is
              accompanied by related to the performance of a contract or for the
              implementation of pre-contractual measures (Goods) above. In all
              other cases, the processing is based on our legitimate interest in
              the effective handling of enquiries addressed to us (Article
              6(1)(f) GDPR) or on your request Consent (Article 6(1)(a) GDPR) if
              requested. The data you sent to us by contacting us will remain
              with us until you delete request to withdraw your consent to
              storage or remove the purpose for data storage (e.g. after your
              request has been completed). Mandatory legal provisions — in
              particular, statutory retention periods remain unaffected
            </p>
          </ol>

          <br />
          <ol start="5">
            <li>
              <strong>Analytical tools and advertising</strong>
            </li>
            <br />
            <p>
              Wordpress Statistics
              <br />
              <br />
              This website uses WordPress Statistics to provide statistical
              analysis of visitors’ access. The supplier is the Automattic Inc.,
              60 29th Street #343, San Francisco, CA 94110-4929, USA. Wordpress
              Statistics uses technologies to recognise the user for the purpose
              of Enable analysis of user behaviour (e.g. cookies or device
              fingerprinting). Wordpress Statistics analysis of, inter alia, log
              files (referr., IP address, browser, etc.), the origin of website
              visitors (country, city) and what actions they have undertaken on
              the page (e.g. clicks, views, downloads). In this way collected
              information on the use of this website is stored on servers in the
              US. Your IP address will be anonymised after processing and before
              storage. This analytical tool is used on the basis of Article
              6(1)(f) GDPR. The Website operators have a legitimate interest in
              anonymous analysis of user behaviour in order to optimise both its
              website and its advertising. Provided that consent is given has
              been consulted (e.g. consent to store cookies), the processing is
              carried out solely on the basis of Article 6(1)(a) of the GDPR;
              Consent may be revoked at any time.
            </p>
          </ol>

          <br />
          <ol start="6">
            <li>
              <strong>Analytical tools and advertising</strong>
            </li>
            <br />
            <p>
              Newsletter data
              <br />
              <br />
              If you wish to obtain the newsletter on the website, we need an
              E-mail address and information allowing us to verify that you are
              the holder of the indicated e-mail address and agree to receive
              the newsletter. Additional Data are not collected or only
              collected on a voluntary basis. We use these data only for sending
              the requested information and not communicating it to third
              parties. Data entered in the newsletter application form shall be
              processed only on the basis for your consent (Article 6(1)(a)
              GDPR). The consent given to the storage of the data, the e-mail
              address and their use to send the newsletter can be used at any
              time revoked, for example via the ‘exit’ link in the newsletter.
              The legality of the measures already taken. The revocation shall
              be without prejudice to data processing operations. The data you
              have deposited with us for the purpose of reference to the
              newsletter will be sent by us up to your Download from the
              newsletter at our premises and/or the newsletter service provider
              and saved in accordance with Removal of the newsletter from the
              newsletter distribution list. Data used for other purposes in we
              are not affected by this. Once you have been removed from the
              newsletter distribution list, your e-mail address will be sent to
              us or the Newsletter service providers may be stored in a
              blacklist in order to prevent future mailings. The blacklist is
              only used for this purpose and not combined with other data. This
              serves both your interest and our interest in complying with the
              legal requirements when sending newsletters (legitimate interest
              within the meaning of Article 6(1)(f) GDPR). The Storage in the
              blacklist is not limited in time. They may object to storage,
              provided that. Your interests outweigh our legitimate interest.
            </p>
          </ol>

          <br />
          <ol start="7">
            <li>
              <strong>Plugins and tools</strong>
            </li>
            <br />
            <p>
              Vimeo
              <br />
              <br />
              This website uses the plugins of the Vimeo video portal. The
              supplier is Vimeo Inc., 555 West 18th Street, New York, New York
              10011, USA. If you visit one of our Vimeo video pages, you will
              connect to the Vimeo servers. The Vimeo server will be informed
              which of our pages you have visited. Vimeo also gets your IP
              address. This also applies if you are not at Vimeo are logged in
              or have no account with Vimeo. The information collected by Vimeo
              will be sent to the Vimeo server in the US. If you are logged in
              to your Vimeo account, allow Vimeo to browse your browsing
              behaviour directly assign a personal profile. You can prevent this
              by using your Vimeo account log out <br />
              <br />
              Vimeo Cookies or similar Detection technologies (e.g. device
              fingerprinting). Vimeo is used in the interest of an attractive
              presentation of our online offers. This constitutes a legitimate
              interest within the meaning of Article 6(1)(f) of the GDPR.
              Provided that a corresponding consent has been sought, processing
              is carried out solely on the basis of Article 6(1)(a) GDPR;
              Consent may be revoked at any time. For more information on how to
              handle user data, see Vimeo’s privacy statement under:
              <br />
              <br />
              https://vimeo.com/privacy.
              <br />
              <br />
              Source: e-recht24.de
              <br />
              <br />
              <strong>Imprint</strong>
              <br />
              <br />
              Information according to § 5 TMG:
              <br />
              <br />
              Westring international trading & consulting GmbH
              <br />
              am Herrenlandpark 9<br />
              41379 Brüggen
              <br />
              <br />
              Represented by:
              <br /> René Lankes
              <br />
              <br /> Contact: <br />
              Phone: +49 216388814 60
              <br />
              E-Mail: info[at]westringtc.de
              <br />
              <br /> Registration:
              <br /> Commercial register: Krefeld
              <br /> Registernumber: HRB14056
              <br />
              <br /> VAT-ID:
              <br /> according to §27 a Umsatzsteuergesätz:
              <br /> DE285703537 <br />
              <br />
              Reference for pictures and graphics:
              <br /> http://www.shutterstock.de
            </p>
          </ol>

          {/* */}
        </div>
      </div>
    );
  }
}

export default MembershipAgreement;
