import React from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import axios from "axios";
import {base_url, countryList} from "../services/const";
import {NotificationManager} from "react-notifications";

class PublicContactUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            message: "",
            priority: "",
            fname: "",
            lname: "",
            companyName: "",
            city: "",
            country: "",
            email: "",
            website: ""
        };
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onSubmitForm() {
        let data = new URLSearchParams();
        data.append("message", this.state.message);
        data.append("company_name", this.state.companyName);
        data.append("fname", this.state.fname);
        data.append("lname", this.state.lname);
        data.append("city", this.state.city);
        data.append("country", this.state.country);
        data.append("email", this.state.email);
        data.append("website", this.state.website);
        data.append("subject", this.state.subject);
        axios
            .request({
                method: "post",
                data: data,
                url: `${base_url}/contact/getintouch`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
            })
            .then((ret) => {
                if(ret.data.status === 204 || ret.data.status === 200) {
                    NotificationManager.success('Message Sent', 'Success');
                    this.props.onHide();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                size={"lg"}
                onHide={this.props.onHide}
                className="ContactUsModal"
            >
                <Modal.Header className="border-0" closeButton>
                    <h3>Contact us</h3>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <div>
                        <p className="mt-2">
                        If you do not yet have a login and if you are interested to learn more about that unique network and the possibilities that it offers to you, please send us a message using the form below. We will get back to you right away!
                        </p>
                    </div>
                    <form>
                        <div className="row">
                            <div className="form-group col-lg-6 ">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="First Name"
                                    defaultValue={this.state.fname}
                                    onChange={(e) => this.setState({fname: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Last Name"
                                    defaultValue={this.state.lname}
                                    onChange={(e) => this.setState({lname: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Company Name"
                                    defaultValue={this.state.companyName}
                                    onChange={(e) => this.setState({companyName: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="City"
                                    defaultValue={this.state.city}
                                    onChange={(e) => this.setState({city: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <select
                                    className="form-control"
                                    defaultValue={this.state.country}
                                    onChange={(e) => this.setState({country: e.target.value})}
                                >
                                    <option value={null}>Country</option>
                                    {
                                        countryList.map((item, i) => {
                                            return <option key={i} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Email"
                                    defaultValue={this.state.email}
                                    onChange={(e) => this.setState({email: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Website"
                                    defaultValue={this.state.website}
                                    onChange={(e) => this.setState({website: e.target.value})}
                                />
                            </div>
                            <div className="form-group col-lg-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Subject"
                                    defaultValue={this.state.subject}
                                    onChange={(e) => this.setState({subject: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                placeholder="Message.."
                                rows={5}
                                defaultValue={this.state.message}
                                onChange={(e) => this.setState({message: e.target.value})}
                            >{}</textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>
                        Close
                    </Button>
                    <Button variant="dark" onClick={this.onSubmitForm}>
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let userRole = state.login.level;
    return {
        userRole,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PublicContactUs)
);
