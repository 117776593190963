import React from "react";
import { brandLogoPath } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  checkFileIfExist,
  fileLabels,
} from "../../../../services/globalFunctions";
import { brandLogo } from "../../../../assets/brand";
import { avatarFallback } from "../../../../assets/icons";

export const TmpOrJsMainContact = ({ companyData }) => {
  console.log("companyDATA", companyData);
  const isInviterCompanyExist = !!companyData.inviter.companies.length;
  const isInviterCompanyContactsExist =
    isInviterCompanyExist && !!companyData.inviter.companies[0].contacts.length;
  return (
    <div
      className="company-profile-box box"
      style={{ paddingTop: 15, marginBottom: 0 }}
    >
      <div className="company-contact">
        <p className="subsection text-uppercase">Main contact</p>
        <div className="row">
          <div className="col-lg-4">
            <img
              style={{
                width: "100%",
              }}
              src={
                (isInviterCompanyExist && companyData.inviter.wp_logo) ||
                avatarFallback
              }
              alt="..."
            />
          </div>
          <div className="company-contact-list col-lg-7">
            <p className="company-contact-list-name">
              <span
                className={
                  isInviterCompanyContactsExist &&
                  companyData.inviter.companies[0].contacts[0].title
                    ? ""
                    : "d-none"
                }
              >
                {isInviterCompanyContactsExist &&
                  companyData.inviter.companies[0].contacts[0].title}
              </span>
              {companyData.inviter.first_name} {companyData.inviter.last_name}
            </p>

            <p
              className={
                "font-weight-light " +
                (isInviterCompanyContactsExist &&
                companyData.inviter.companies[0].contacts[0].job_title
                  ? ""
                  : "d-none")
              }
            >
              {isInviterCompanyContactsExist &&
                companyData.inviter.companies[0].contacts[0].job_title}
            </p>

            <p
              className={
                "company-contact-list-phone " +
                (companyData.inviter.phone ? "" : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-1" />{" "}
              {companyData.inviter.phone}
            </p>

            <p
              className={
                "company-contact-list-phone " +
                (isInviterCompanyContactsExist &&
                companyData.inviter.companies[0].contacts[0].mobile
                  ? ""
                  : "d-none")
              }
              style={{ fontSize: "14px" }}
            >
              <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />{" "}
              {isInviterCompanyContactsExist &&
                companyData.inviter.companies[0].contacts[0].mobile}
            </p>
            <a
              onClick={() =>
                (window.location.href = `mailto:${companyData.inviter.email}`)
              }
            >
              <div></div>
              <p
                className={
                  "company-contact-list-email d-inline-flex " +
                  (companyData.inviter.email ? "" : "d-none")
                }
                style={{ fontSize: "14px" }}
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-2 mt-1" />
                <span style={{ wordBreak: "break-word" }}>
                  {companyData.inviter.email}
                </span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
