import {HS_CODE_OFFERING, HS_CODE_SEARCHING, HAVE_HS_CODE, CLEAR_PROPS} from "../actions/actionTypes";

const INITIAL_STATE = {
    offering: [],
    searching: [],
    haveHsCode: 'pending'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HS_CODE_OFFERING:
            return Object.assign({}, state, {
                offering: action.payload,
                // haveHsCode: true
            });
        case HS_CODE_SEARCHING:
            return Object.assign({}, state, {
                searching: action.payload,
                // haveHsCode: true
            });
        case HAVE_HS_CODE:
            return Object.assign({}, state, {
                haveHsCode: action.payload
            });
        case CLEAR_PROPS:
            return Object.assign({}, state, {
                offering: [],
                searching: [],
                haveHsCode: 'pending'
            });
        default:
            return state;
    }
}
