import {HAVE_HS_CODE, HS_CODE_OFFERING, HS_CODE_SEARCHING} from "./actionTypes";

export const hsCodesOffering = (data) => {
    return (dispatch) => {
        dispatch(setHsCodeOffering(data));
    }
};

export const haveHsCode = (data) => {
    return (dispatch) => {
        dispatch(setHaveHsCode(data));
    }
};

export const hsCodesSearching = (data) => {
    return (dispatch) => {
        dispatch(setHsCodeSearching(data));
    }
};

function setHsCodeOffering(res) {
    return {
        type: HS_CODE_OFFERING,
        payload: res
    }
}

function setHsCodeSearching(res) {
    return {
        type: HS_CODE_SEARCHING,
        payload: res
    }
}

function setHaveHsCode(res) {
    return {
        type: HAVE_HS_CODE,
        payload: res
    }
}
