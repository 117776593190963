import React from "react";
import { Button, Modal } from "react-bootstrap";
import { userLevelColors } from "../../services/globalFunctions";
import { connect } from "react-redux";

class WelcomeVideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: true,
    };
    this.onClickES = this.onClickES.bind(this);
  }

  onClickES() {}

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="WelcomeVideoModal"
      >
        <Modal.Header
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            Introduction to Jumpseat
          </h3>
        </Modal.Header>
        <Modal.Body>
          {/*<label style={{color: 'blue', cursor: 'pointer', marginRight: '30px', float: 'right', textDecoration: 'underline'}}>{this.state.video ? 'ES' : 'EN'}</label>*/}
          <img
            alt={"..."}
            style={{
              cursor: "pointer",
              marginRight: "25px",
              marginBottom: "10px",
              float: "right",
              outline: !this.state.video ? "2px solid black" : "",
            }}
            onClick={() => this.setState((prevState) => ({ video: false }))}
            width={35}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg"
          />
          <img
            alt={"..."}
            style={{
              cursor: "pointer",
              marginRight: "25px",
              marginBottom: "10px",
              float: "right",
              outline: this.state.video ? "2px solid black" : "",
            }}
            onClick={() => this.setState((prevState) => ({ video: true }))}
            width={35}
            height={20}
            src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg"
          />
          <div className="p-4">
            <iframe
              style={{ height: "400px", width: "100%", border: 0 }}
              src={
                this.state.video
                  ? require("../../assets/WorldRingIntoEN.mp4")
                  : require("../../assets/WorldRingIntroES.mp4")
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.props.onHide} className="mr-4">
            Skip
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  return {
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideoModal);
