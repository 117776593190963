import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faImage } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { toBase64, userLevelColors } from "../../services/globalFunctions";
import { NotificationManager } from "react-notifications";

class PnSFormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      description: "",
      image: null,
      existingImageThumbnail: null,
      newImageThumbnail: null,
    };
    this.setDataToFields = this.setDataToFields.bind(this);
    this.onChangeImage = this.onChangeImage.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.companyServices !== this.props.companyServices) {
      this.setDataToFields();
    }
  }

  componentDidMount() {
    this.setDataToFields();
  }

  setDataToFields() {
    this.props.companyServices.map((item, i) => {
      if (this.props.active === item.pns_id) {
        this.setState({
          productName: item.name,
          description: item.description,
          existingImageThumbnail: item.image,
        });
      }
      return true;
    });
  }

  async onChangeImage(e) {
    if ((e.target.files[0].size / 1024 / 1024).toFixed(4) > 5) {
      NotificationManager.error("File size too large. Max size 5mb.", "Error");
    } else {
      this.setState({ image: e.target.files[0] }, () =>
        this.props.onChangeImage(this.state.image, this.props.active)
      );
      let logo = {
        mime: e.target.files[0].type,
        filename: e.target.files[0].name,
      };
      await toBase64(e.target.files[0]).then((res) => {
        logo.data = res;
        this.setState({ newImageThumbnail: logo, showFileError: false });
      });
    }
  }

  render() {
    let { props } = this;
    return (
      <div className="row">
        <div className="col-lg-4">
          <div className="file-upload-container-contact-person">
            <div className="file-upload-content">
              <div className={this.state.newImageThumbnail ? "d-none" : ""}>
                {this.state.existingImageThumbnail ? (
                  this.state.existingImageThumbnail.data ? (
                    <img
                      style={{ height: "65px" }}
                      src={`data:image/jpeg;base64,${this.state.existingImageThumbnail.data}`}
                      alt={"..."}
                    />
                  ) : (
                    <FontAwesomeIcon size={"3x"} icon={faImage} />
                  )
                ) : (
                  <FontAwesomeIcon size={"3x"} icon={faImage} />
                )}
              </div>
              <div className={this.state.newImageThumbnail ? "" : "d-none"}>
                {this.state.newImageThumbnail ? (
                  <img
                    style={{ height: "65px" }}
                    src={`${this.state.newImageThumbnail.data}`}
                    alt={"..."}
                  />
                ) : (
                  <FontAwesomeIcon size={"3x"} icon={faImage} />
                )}
              </div>
              <p className="title pt-2 mb-1">Drag & Drop</p>
              <p className="sub-title mb-3">
                your product or service image here
              </p>
              {!this.state.newImageThumbnail && (
                <p className="sub-title mt-1">max 5mb</p>
              )}
              <br />
              &nbsp;
            </div>
            <input
              style={{ padding: "130px 0px 12px 23%" }}
              type="file"
              id="file-upload"
              className={
                "choose-file-input " + (this.state.image ? "" : "file-selected")
              }
              onChange={this.onChangeImage}
              accept=".png, .jpeg, .jpg"
            />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="form-group">
            <input
              defaultValue={this.state.productName}
              type="text"
              className={
                "form-control " + userLevelColors(this.props.level).className
              }
              placeholder="Enter product/service name"
              onChange={(e) =>
                this.setState({ productName: e.target.value }, () =>
                  props.onChangeName(props.active, this.state.productName)
                )
              }
            />
          </div>

          <div className="form-group">
            <textarea
              rows={3}
              defaultValue={this.state.description}
              className={
                "form-control " + userLevelColors(this.props.level).className
              }
              placeholder="Enter product/service description"
              onChange={(e) =>
                this.setState({ description: e.target.value }, () =>
                  props.onChangeDescription(
                    props.active,
                    this.state.description
                  )
                )
              }
            />
          </div>
        </div>
        <hr className="seperator" style={{ width: "95%" }} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  return {
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PnSFormComponent);
