import React from "react";

export const DemoStep4 = (props) => {
    return (
        <div className="DemoStep4 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/step_4.png')} style={{height: '450px'}} alt={'...'} />
            <p className="mt-4">Add a hero image to brighten the visual appeal of your Worldring Marketplace profile. Please make sure the image follows our guidlines and is in an ultrawide format, i.e. 1920x300 for optimal results.</p>
        </div>
    )
};
