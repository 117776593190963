import axios from "axios";
import { base_url } from "./const";
import React from "react";
import moment from "moment";
import { filePathToGlobal } from "../utils/file";

export function JsEncrypt() {
  var k = 13;

  this.encode = function (data) {
    var enc = "";

    for (var i = 0; i < data.length; i++) {
      // create block
      var a = data.charCodeAt(i);
      // bitwise XOR
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }

    return btoa(enc);
  };

  this.decode = function (data) {
    data = atob(data);

    var enc = "";

    for (var i = 0; i < data.length; i++) {
      // create block
      var a = data.charCodeAt(i);
      // bitwise XOR
      var b = a ^ k;
      enc = enc + String.fromCharCode(b);
    }

    return enc;
  };
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const uploadImages = (image, token) => {
  axios
    .request({
      method: "post",
      url: `${base_url}/upload/image`,
      data: image,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

export function request(endpoint, dataIn) {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, dataIn)
      .then((res) => {
        if (!res.data.error) {
          resolve(res.data);
        } else {
          reject("error");
        }
      })
      .catch((err) => reject(err));
  });
}

export function pdfRequest(endpoint, dataIn) {
  return new Promise((resolve, reject) => {
    axios
      .post(endpoint, dataIn)
      .then((res) => {
        if (!res.data.error) {
          resolve(res.data);
        } else {
          reject("error");
        }
      })
      .catch((err) => reject(err));
  });
}
export async function uploadImage(url, image) {
  return await request(url, image);
}

export async function uploadPdf(url, file) {
  return await pdfRequest(url, file);
}

export async function getHSCodes(code) {
  let proxyUrl = "https://cors-anywhere.herokuapp.com/",
    targetUrl = `https://www.tariffnumber.com/api/v1/cnSuggest?term=${code}`;
  return await fetch(proxyUrl + targetUrl)
    .then((blob) => blob.json())
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return e;
    });
}

export function filterCountry(list, country) {
  let arr = [];
  list.map((item) => {
    if (item.hq_country === country) {
      arr.push(item);
    }
  });
  return arr;
}

export function getCountries(list) {
  let arr = [];
  for (let i = 0; i < list.length; i++) {
    let country = list[i].hq_country;
    arr.push(country);
  }
  return arr.sort();
}

export function getCountriesFromCompaniesList(companies) {
  const countryNames = companies.flatMap((company) =>
    company.addresses.map((address) => address.country_name)
  );

  return [...new Set(countryNames)];
}

export function filterCompaniesByCountry(companies, countryName) {
  return companies.filter((company) =>
    company.addresses.some((address) => address.country_name === countryName)
  );
}

export function getCountriesFromNameCompaniesList(companies) {
  const countryNames = companies.flatMap((company) =>
    company.addresses.map((address) => {
      const name = address.name;
      if (!name) {
        return "";
      }
      const nameArr = name.split(",");
      if (nameArr && nameArr.length) {
        return nameArr[nameArr.length - 1];
      }
      return "";
    })
  );

  return [...new Set(countryNames)];
}

export function filterCompaniesByNameCountry(companies, countryName) {
  return companies.filter((company) =>
    company.addresses.some((address) => address.name.includes(countryName))
  );
}

export function deletePnS(pnsId, token) {
  return new Promise((resolve, reject) => {
    axios
      .request({
        method: "delete",
        url: `${base_url}/company/pns/${pnsId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((err) => {
        reject(err);
        console.log(err);
      });
  });
}

export const translateData = (membership) => {
  switch (membership) {
    case "first_class":
      return "First Class";
    case "tmp":
      return "Temp";
    case "jumpseat":
      return "Jumpseat";
    case "founding_member":
      return "Founding Member";
    case "business":
      return "Business Member";
    case "economy":
      return "Economy Member";
    default:
      return membership;
  }
};

export const userLevelColors = (membership) => {
  switch (membership) {
    case "jumpseat":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Jumpseat",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "tmp":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Temp",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "accepted":
      return {
        iconColor: "#000",
        font: "#000",
        bg: "#C3C3C3",
        others: "#000",
        buttonFont: "#fff",
        buttonBg: "#000",
        label: "Jumpseat",
        icon: "jumpseat-wh.svg",
        className: " jumpseat-member ",
        background: "rgba(195,195,195,0.2)",
      };
    case "economy":
      return {
        iconColor: "#1e345a",
        font: "#1e345a",
        bg: "#fff",
        others: "#fff",
        buttonFont: "#fff",
        buttonBg: "#1e345a",
        label: "Economy Class",
        icon: "economy-wh.svg",
        className: " economy-member ",
        background: "#fff",
      };
    case "business":
      return {
        iconColor: "#fff",
        font: "#fff",
        bg: "#1e345a",
        others: "#1e345a",
        buttonFont: "#1e345a",
        buttonBg: "#fff",
        label: "Business Class",
        icon: "business-wh.svg",
        className: " business-member ",
        background: "rgba(30,52,90,0.2)",
      };
    case "first_class":
      return {
        iconColor: "#fff",
        font: "#fff",
        bg: "#d0ac51",
        others: "#d0ac51",
        buttonFont: "#d0ac51",
        buttonBg: "#fff",
        label: "First Class",
        icon: "first-wh.svg",
        className: " first-class-member ",
        background: "rgba(208,172,81,0.2)",
      };
    case "founding_member":
      return {
        iconColor: "#FFFFFF",
        font: "#e2b007",
        bg: "#000",
        others: "#000",
        buttonFont: "#000",
        buttonBg: "#FFFFFF",
        label: "Founding Member",
        icon: "FoundingMember.png",
        className: " founding-member ",
        background: "rgba(0,0,0,0.2)'",
      };
    case "member":
      return {
        iconColor: "#FFFFFF",
        font: "#C0C0C0",
        bg: "#000",
        others: "#000",
        buttonFont: "#000",
        buttonBg: "#FFFFFF",
        label: "Member",
        icon: "AgentLevel.png",
        className: " founding-member ",
        background: "rgba(0,0,0,0.2)'",
      };
    case "admin":
      return {
        iconColor: "#C2B59B",
        font: "#C2B59B",
        bg: "#4C0013",
        others: "#4C0013",
        buttonBg: "#C2B59B",
        buttonFont: "#4C0013",
        label: "Admin",
        icon: "admin-gold.svg",
        className: " admin-member ",
        background: "rgba(76,0,19,0.2)",
      };
    case "trading_company":
      return {
        iconColor: "#FFFFFF",
        font: "#FFFFFF",
        bg: "#004aad",
        others: "#004aad",
        buttonBg: "#FFFFFF",
        buttonFont: "#004aad",
        label: "Trading Company",
        icon: "AgentLevel.png",
        className: " trading-company ",
        background: "rgba(0,74,173,0.2)",
      };
    default:
      return {
        iconColor: "#C3C3C3",
        font: "#ffffff",
        bg: "#000",
        others: "#000",
        buttonBg: "#000",
        buttonFont: "#C3C3C3",
        label: "Agent",
        icon: "AgentLevel.png",
        className: " agent-member ",
        background: "rgba(0,0,0,0.2)",
      };
  }
};

export const paymentButtons = (userLevel) => {
  switch (userLevel) {
    case "first_class":
      return {
        first: { label: "Contact us", value: 1 },
        second: { label: "Contact us", value: 1 },
        third: { label: "Your current plan", value: null },
      };
    case "business":
      return {
        first: { label: "Contact us", value: 1 },
        second: { label: "Your current plan", value: null },
        third: { label: "Upgrade plan", value: "P-2848939415498134DL5ENOHQ" },
      };
    case "economy":
      return {
        first: { label: "Your current plan", value: null },
        second: { label: "Upgrade plan", value: "P-25Y283259U926511LL5ENNJY" },
        third: { label: "Upgrade plan", value: "P-2848939415498134DL5ENOHQ" },
      };
    default:
      return {
        first: { label: "Choose economy", value: "P-9UB28031CL483774HL5ENL6I" },
        second: {
          label: "Choose business",
          value: "P-25Y283259U926511LL5ENNJY",
        },
        third: {
          label: "Choose first class",
          value: "P-2848939415498134DL5ENOHQ",
        },
      };
  }
};
export const ButtonSpinner = (props) => {
  return (
    <div
      style={{
        height: "1.3em",
        width: "1.3em",
        color: userLevelColors(props.level).bg,
      }}
      className={
        "spinner-border " +
        (props.showLoading ? "" : " d-none ") +
        (props.level ? "" : "text-light")
      }
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  );
};

export const SearchSpinner = (props) => {
  return (
    <div
      className={
        "col-lg-12 text-center spinner-stuff mt-5 pb-5 " +
        (props.showLoading ? "" : "d-none")
      }
    >
      <div
        style={{
          height: "4em",
          width: "4em",
          color: userLevelColors(props.level).bg,
        }}
        className={"spinner-border " + (props.level ? "" : "text-primary")}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export const JSMatchesSearchSpinner = (props) => {
  return (
    <div
      className={
        "col-lg-12 text-center spinner-stuff mt-5 pb-5 " +
        (props.showLoading ? "" : "d-none")
      }
    >
      <div style={{ marginBottom: 40, fontSize: 16, fontWeight: "bold" }}>
        We are looking for matches to your search/offer on the WorldRing
        Network.
      </div>
      <div
        style={{
          height: "4em",
          width: "4em",
          color: userLevelColors(props.level).bg,
        }}
        className={"spinner-border " + (props.level ? "" : "text-primary")}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
      <div style={{ marginTop: 40 }}>
        Please allow up to 60 seconds for this process to complete.
      </div>
    </div>
  );
};

export const reorderAdminPage = (data) => {
  let sortedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    for (let k in row) {
      let newRow = {
        name: row.name,
        country: row.country,
        membership: row.membership,
        onlineSince: row.onlineSince,
        aic: row.aic,
        type: row.type,
        hsCode: row.hsCode,
        details: row.details,
        targetGroup: row.target_group,
        specification: row.specification,
        enteredByAg: row.enteredByAg,
      };
      sortedData.push(newRow);
    }
  }
  return sortedData;
};

export const reorderSalesPage = (data, level) => {
  let sortedData = [];
  for (let i = 0; i < data.length; i++) {
    let row = data[i];
    let newRow = {
      country: row.country,
      type: row.type,
      hsCode: row.hsCode,
      details: row.details,
      targetGroup: row.target_group,
      specification: row.specification,
      membership: row.membership,
      onlineSince: row.onlineSince,
      [level === "trading_company" ? "customer" : "aic"]:
        level === "trading_company" ? row.customer : row.aic,
      number: row.number,
    };
    sortedData.push(newRow);
  }
  return sortedData;
};

export const PnSLimit = (level) => {
  switch (level) {
    case "first_class":
      return 20;
    case "business":
      return 10;
    case "economy":
      return 5;
    default:
      return 20;
  }
};

export const formatDateToDDMMYYYY = (dateString) => {
  const date = moment(dateString);

  if (date.isValid()) {
    return date.format("DD/MM/YYYY");
  } else {
    return dateString;
  }
};
export const removeDuplicates = (array) => [...new Set(array)];

export const checkFileIfExist = (files, label) => {
  if (!files) return "";
  if (!files.length) {
    return "";
  }

  const filePath = files
    .map((f) => filePathToGlobal(f))
    .find((f) => f.label === label);

  if (!filePath) return "";

  return filePath.path;
};

export const fileLabels = {
  company: {
    logo: "logo",
    header: "header",
    mainContact: "main_contact",
  },
  product: {
    img: "image",
    pdf: "document",
  },
};

export const checkLocalStorage = () => {
  const currentLocalStorage = localStorage.getItem("persist:wrl-marketplace");
  if (!currentLocalStorage) return false;

  const parsedLocalStorage = JSON.parse(currentLocalStorage);
  if (!parsedLocalStorage) return false;

  const loginPart = parsedLocalStorage.login;
  if (!loginPart) return false;

  const parsedLoginPart = JSON.parse(loginPart);
  if (!parsedLoginPart) return false;

  return parsedLoginPart.isLoggedIn;
};

export const levelByTitle = (title) => {
  switch (title) {
    case "economy":
      return 2;
    case "business":
      return 3;
    case "first_class":
      return 4;
    default:
      return 2;
  }
};
