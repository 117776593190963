import React from "react";
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../services/globalFunctions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class SuccessModalMyMatchesJumpseat extends React.Component{
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal show={this.props.show} size={'md'} onHide={this.props.onHide}>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>Success</h3>
                </Modal.Header>
                <Modal.Body>
                    Your request for contact details has been successfully sent to your match.  You will be notified, once your match has signed up and shared all contact details with you.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} onClick={this.props.onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level,
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SuccessModalMyMatchesJumpseat)
);
