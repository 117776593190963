import React from "react";

export const DeveloperMode = () => {
  const handleClick = () => {
    localStorage.setItem("developerMode", "yes");
  };
  return (
    <div
      style={{
        display: "flex",
        height: "100dvh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <p>Developer Mode</p>
      <p>Do you want to activate marketplace login?</p>
      <button onClick={handleClick}>Activate</button>
    </div>
  );
};
