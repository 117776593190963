import React, { useState, useEffect } from "react";
import { Router, Route, withRouter, Switch } from "react-router-dom";
import Navbar from "../../components/Navbar";

import {
  JumpseatRoutes,
  PrivateRoute,
  UserRoutes,
} from "./components/ProtectedRoutes";

import {
  AdminPage,
  AgentSearch,
  Blogs,
  ClientsMatches,
  CommoditiesSearch,
  CompanyProfile,
  Footer,
  Impressum,
  Login,
  MembershipAgreement,
  MyClients,
  MyCustomers,
  MyMatches,
  MyProducts,
  Newsfeed,
  NotFound,
  PrivacyPolicy,
  ProductDetails,
  ResetPassword,
  SalesPage,
  SearchResult,
  TradingCompanySalesPage,
  UserProfile,
  AgentDashboard,
  DeveloperMode,
} from "../../layouts";

import {
  JumpSeatLogin,
  JumpseatHsCodes,
  JumpseatInvt,
  JumpseatMatches,
  PaymentPage,
  Registration,
} from "../../layouts/Jumpseat";

import { connect, useSelector } from "react-redux";
import { updateRoutes } from "../../store/actions/breadcrumbsAction";
import { includeRoutes, routesNames } from "../const";
import customHistory from "../history";
import { CookieAgreement } from "./components/CookieConsent";
import { urls } from "./utils/urls";

const Routes = ({ updateRoutes, routes }) => {
  customHistory.listen((route) => {
    const { pathname } = route;
    for (const key in includeRoutes) {
      if (pathname.includes(key)) {
        updateRoutes({ name: includeRoutes[key], route: pathname }, routes);
        return;
      }
    }
    if (routesNames[pathname]) {
      updateRoutes({ name: pathname, route: pathname }, routes);
    }
    window.scrollTo(0, 0);
  });

  const history = window.location.href;

  const isFooterShowed = !history.includes(urls.myCustomers);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const [, setKey] = useState(0);

  useEffect(() => {
    setKey((currentKey) => currentKey + 1);
  }, [isLoggedIn]);

  return (
    <Router history={customHistory}>
      <>
        {/* <InactivityCheckProvider key={key} isLoggedIn={isLoggedIn} /> */}
        {/*{isMaintenanceShowed && <Route component={withRouter(Maintenance)} />}*/}
        <Route component={withRouter(Navbar)} />
        <Switch>
          <PrivateRoute
            exact
            path={urls.membership.payment}
            component={withRouter(PaymentPage)}
          />
          <JumpseatRoutes
            exact
            path={urls.jumpseat.newsFeed}
            component={withRouter(Newsfeed)}
          />
          <JumpseatRoutes
            exact
            path={urls.jumpseat.registration}
            component={withRouter(Registration)}
          />
          <JumpseatRoutes
            exact
            path={urls.jumpseat.myMatches}
            component={withRouter(JumpseatMatches)}
          />
          <JumpseatRoutes
            exact
            path={urls.jumpseat.hsCodes}
            component={withRouter(JumpseatHsCodes)}
          />
          <UserRoutes exact path={urls.dashboard} component={AgentDashboard} />
          <UserRoutes
            exact
            path={urls.search.id}
            component={withRouter(SearchResult)}
          />
          <UserRoutes
            exact
            path={urls.newsFeed}
            component={withRouter(Newsfeed)}
          />
          <UserRoutes
            exact
            path={urls.companyProfile.id}
            component={withRouter(CompanyProfile)}
          />
          <UserRoutes
            exact
            path={urls.companyProfile.user.id}
            component={withRouter(UserProfile)}
          />
          <UserRoutes
            exact
            path={urls.jumpseat.invite}
            component={withRouter(JumpseatInvt)}
          />
          <UserRoutes
            exact
            path={urls.myCustomers}
            component={withRouter(MyCustomers)}
          />
          <UserRoutes
            exact
            path={urls.myProducts}
            component={withRouter(MyProducts)}
          />
          <UserRoutes
            exact
            path={urls.commoditiesSearch}
            component={withRouter(CommoditiesSearch)}
          />
          <UserRoutes
            exact
            path={urls.product.detail.id}
            component={withRouter(ProductDetails)}
          />
          <UserRoutes
            exacth
            path={urls.myClients}
            component={withRouter(MyClients)}
          />
          <UserRoutes
            exact
            path={urls.myMatches}
            component={withRouter(MyMatches)}
          />
          <UserRoutes
            exact
            path={urls.clientMatches}
            component={withRouter(ClientsMatches)}
          />
          <UserRoutes
            exact
            path={urls.agent.search}
            component={withRouter(AgentSearch)}
          />
          <UserRoutes
            exact
            path={urls.salesPage}
            component={withRouter(SalesPage)}
          />
          <UserRoutes
            exact
            path={urls.salesSupport}
            component={withRouter(TradingCompanySalesPage)}
          />
          <UserRoutes
            exact
            path={urls.adminPage}
            component={withRouter(AdminPage)}
          />
          <UserRoutes
            exact
            path={urls.blog.newsFeed}
            component={withRouter(Blogs)}
          />
          <Route exact path={"/"} component={withRouter(Login)} />
          <Route
            exact
            path={"/developer"}
            component={withRouter(DeveloperMode)}
          />

          <Route exact path={urls.join} component={withRouter(JumpSeatLogin)} />
          <Route
            exact
            path={urls.impressum}
            component={withRouter(Impressum)}
          />
          <Route
            exact
            path={urls.membership.agreement}
            component={withRouter(MembershipAgreement)}
          />
          <Route
            exact
            path={urls.privacyPolicy}
            component={withRouter(PrivacyPolicy)}
          />
          <Route
            exact
            path={urls.resetPassword.token}
            component={withRouter(ResetPassword)}
          />
          <Route path="*" component={NotFound} />
        </Switch>
        {isFooterShowed && <Footer />}
      </>
      <CookieAgreement />
    </Router>
  );
};

const mapStateToProps = (state) => {
  let routes = state.breadcrumbs.routes;
  return {
    routes,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateRoutes: (route, routes) => dispatch(updateRoutes(route, routes)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
