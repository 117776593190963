import React from "react";

export const FooterCard = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div className="footercard box" style={{width: "100%"}}>
                    <div className="row" style={{alignItems: "center"}}>
                        <div className="col-lg-7">
                            <h3 className="text-uppercase">The leading worldwide business network!</h3>
                            <p className="pt-2">
                                WorldRing is the leading global business network for small and medium sized
                                enterprises. With it's global reachout and partners in nearly every country of the
                                world, WorldRing is able to serve your needs on global sourcing and sales.
                            </p>
                        </div>
                        <div className="col-lg-5">
                            <img src={require('./../assets/images/WR_resized.png')} className="" alt='...'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
