import React from 'react';

const NotFound = (props) => {
    return (
        <div className="NotFoundContainer page-container">
            <div className="page-header"></div>
            <div className="container text-center mt-5">
                <h1>404 :(</h1>
                <h3 className="font-weight-light my-4">We couldn't find what you're looking for</h3>
                <input type="button" onClick={() => props.history.goBack()} className="btn-wlr btn" value="Go Back" />
            </div>
        </div>
    )
};

export default NotFound;
