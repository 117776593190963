import React, { forwardRef, useEffect, useState } from "react";
import styles from "./card.module.scss";
import "./flip-transition.scss";
import classNames from "classnames";

const Card = forwardRef(function Card(
  { handleFlipCard, front, back, showFront },
  ref
) {
  const [isFrontShowed, setIsFrontShowed] = useState(showFront);

  useEffect(() => {
    setTimeout(() => {
      setIsFrontShowed(showFront);
    }, 150);
  }, [showFront]);

  const isBackShowed = !isFrontShowed;

  return (
    <div className={styles.card}>
      <div
        ref={ref}
        className={classNames(styles.back, { [styles.showed]: isBackShowed })}
        style={{ backfaceVisibility: isBackShowed ? "visible" : "hidden" }}
      >
        {back}
      </div>
      {isFrontShowed && (
        <div className={styles.front}>
          {React.cloneElement(front, { handleFlipCard: handleFlipCard })}
        </div>
      )}
    </div>
  );
});

export default Card;
