import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  PnSLimit,
  toBase64,
  uploadImage,
  uploadPdf,
  userLevelColors,
} from "../../../services/globalFunctions";
import { PnSFormComponent } from "../../CompanyProfileComponents/PnSFormComponent";
import { connect } from "react-redux";
import OSFormComponent from "../../CompanyProfileComponents/OSFormComponent";
import axios from "axios";
import { base_url } from "../../../services/const";
import { NotificationManager } from "react-notifications";

class SearchingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productImage: [],
      servicesToShow:
        this.props.searches.length > 0 ? this.props.searches : [1],
      valObj: {},
    };
    this.onClickAddMoreFields = this.onClickAddMoreFields.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeSpecs = this.onChangeSpecs.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.onChangeFileName = this.onChangeFileName.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
    this.handleServicesImage = this.handleServicesImage.bind(this);
    this.addNewSearches = this.addNewSearches.bind(this);
    this.updateSearches = this.updateSearches.bind(this);
    this.handlePdfUpload = this.handlePdfUpload.bind(this);
    this.onChangeHsCode = this.onChangeHsCode.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.searches !== prevProps.searches) {
      this.setState({
        servicesToShow:
          this.props.searches.length > 0 ? this.props.searches : [1],
      });
    }
  }

  onClickAddMoreFields() {
    if (this.state.servicesToShow.length < PnSLimit(this.props.level)) {
      this.setState({ servicesToShow: this.state.servicesToShow.concat([1]) });
    } else {
      NotificationManager.error(
        "Your maximum allowed number of listed products has been reached. Please upgrade your membership to add more.",
        "Error"
      );
    }
  }

  onChangeName(key, val) {
    let obj = this.state.valObj;
    obj[key] ? (obj[key].name = val) : (obj[key] = { name: val });
    this.setState({ valObj: obj });
  }

  onChangeDescription(key, val) {
    let obj = this.state.valObj;
    obj[key] ? (obj[key].description = val) : (obj[key] = { description: val });
    this.setState({ valObj: obj });
  }

  onChangeTarget(key, val) {
    let obj = this.state.valObj;
    obj[key] ? (obj[key].target = val) : (obj[key] = { target: val });
    this.setState({ valObj: obj });
  }

  onChangeSpecs(key, val) {
    let obj = this.state.valObj;
    obj[key]
      ? (obj[key].specification = val)
      : (obj[key] = { specification: val });
    this.setState({ valObj: obj });
  }

  onChangeFileName(key, val) {
    let obj = this.state.valObj;
    obj[key] ? (obj[key].fileName = val) : (obj[key] = { fileName: val });
    this.setState({ valObj: obj });
  }

  onChangeHsCode(key, val) {
    let obj = this.state.valObj;
    obj[key] ? (obj[key].hsCode = val) : (obj[key] = { hsCode: val });
    this.setState({ valObj: obj });
  }

  async onClickSubmit() {
    let arr = [];
    for (let k = 0; k < this.state.servicesToShow.length; k++) {
      arr.push(this.state.servicesToShow[k].pns_id);
    }
    for (let i in this.state.valObj) {
      if (arr.includes(parseInt(i))) {
        this.state.valObj[i]
          ? await this.updateSearches(this.state.valObj[i], i)
          : void 0;
      } else {
        this.state.valObj[i]
          ? await this.addNewSearches(this.state.valObj[i])
          : void 0;
      }
    }
  }

  async handlePdfUpload(file, key) {
    let formData = new FormData();
    formData.append("pdf", file);
    let pdf_id = await uploadPdf(formData, this.props.token);
    let obj = this.state.valObj;
    obj[key] ? (obj[key].pdf = pdf_id) : (obj[key] = { pdf: pdf_id });
    this.setState({ valObj: obj });
  }

  async handleServicesImage(file, key) {
    let formData = new FormData();
    formData.append("image", file);
    let image_id = await uploadImage(formData, this.props.token);
    let obj = this.state.valObj;
    obj[key] ? (obj[key].image = image_id) : (obj[key] = { image: image_id });
    this.setState({ valObj: obj });
  }

  addNewSearches(obj) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      obj.image ? params.append("company_ps_image_id", obj.image) : void 0;
      params.append("type", "search");
      obj.name ? params.append("name", obj.name) : void 0;
      obj.description ? params.append("description", obj.description) : void 0;
      obj.target ? params.append("target_group", obj.target) : void 0;
      obj.specification
        ? params.append("specification", obj.specification)
        : void 0;
      obj.pdf ? params.append("company_ps_file_id", obj.pdf) : void 0;
      obj.hsCode ? params.append("hs_codes_id", obj.hsCode[0].id) : void 0;
      axios
        .request({
          method: "put",
          url: `${base_url}/company/pns/${this.props.companyId}`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            NotificationManager.success("Product and Services Added");
            this.props.onHide();
            this.props.getPnS();
            resolve(res);
          } else {
            NotificationManager.error("Something went wrong. Try again");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  updateSearches(obj, id) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      obj.image ? params.append("company_ps_image_id", obj.image) : void 0;
      params.append("type", "search");
      obj.name ? params.append("name", obj.name) : void 0;
      obj.description ? params.append("description", obj.description) : void 0;
      obj.target ? params.append("target_group", obj.target) : void 0;
      obj.specification
        ? params.append("specification", obj.specification)
        : void 0;
      obj.pdf ? params.append("company_ps_file_id", obj.pdf) : void 0;
      obj.hsCode ? params.append("hs_codes_id", obj.hsCode[0].id) : void 0;
      axios
        .request({
          method: "post",
          url: `${base_url}/company/pns/${this.props.companyId}/${id}`,
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${this.props.token}`,
          },
        })
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            NotificationManager.success("Product and Services Updated");
            this.props.onHide();
            this.props.getPnS();
            resolve(res);
          } else {
            NotificationManager.error("Something went wrong. Try again");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        size={"lg"}
        onHide={this.props.onHide}
        className="CompanyServicesModal"
      >
        <Modal.Header
          closeButton
          style={{ background: userLevelColors(this.props.level).bg }}
        >
          <h3 style={{ color: userLevelColors(this.props.level).font }}>
            Detailed Searches
          </h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            Here you have the possibility to show a range of products and
            services in detail to your fellow network members. You can upload
            pictures for each item to make it highly visible. Use the "+" to add
            more products and services.
          </p>
          {this.state.servicesToShow.map((i, k) => {
            if (i.name || i.hsCode || i === 1) {
              return (
                <OSFormComponent
                  onChangeName={this.onChangeName}
                  onChangeDescription={this.onChangeDescription}
                  id={i.pns_id ? i.pns_id : k}
                  key={k}
                  companyServices={this.props.searches}
                  active={i.pns_id ? i.pns_id : k}
                  onChangeTarget={this.onChangeTarget}
                  onChangeSpecs={this.onChangeSpecs}
                  onChangeFileName={this.onChangeFileName}
                  onChangeImage={this.handleServicesImage}
                  onChangePdf={this.handlePdfUpload}
                  onChangeHsCode={this.onChangeHsCode}
                />
              );
            }
          })}
          <div
            className={
              "w-100 text-center add-more-btn " +
              userLevelColors(this.props.level).className
            }
          >
            <span onClick={this.onClickAddMoreFields}>
              <FontAwesomeIcon icon={faPlus} size={"3x"} />
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.props.onHide}
          >
            Close
          </Button>
          <Button
            variant="dark"
            style={{
              background: userLevelColors(this.props.level).buttonBg,
              color: userLevelColors(this.props.level).buttonFont,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            onClick={this.onClickSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  let level = state.login.level;
  let token = state.login.token;
  return {
    level,
    token,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SearchingModal);
