import axios from "axios";
import { base_url } from "../services/const";
import { QueryClient } from "react-query";
import { NotificationManager } from "react-notifications";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const baseURL = base_url;
const headers = { "Content-Type": "application/x-www-form-urlencoded" };
const authHeaders = { ...headers };

const axiosDef = axios.create({
  baseURL,
  headers,
});

const axiosAuth = axios.create({
  baseURL,
  headers: authHeaders,
});
const _formatValidationErrors = (errors) => {
  if (typeof errors === "string") {
    return errors;
  }
  let errorMessages = [];
  for (let field in errors) {
    if (errors.hasOwnProperty(field)) {
      let fieldErrors = errors[field].join(" ");
      errorMessages.push(`${field}: ${fieldErrors}`);
    }
  }
  return errorMessages.join(" ");
};
const isResponseError = (response, isSilent = true) => {
  const res = response.data;
  const isError = !!res.hasOwnProperty("error");
  if (
    isError &&
    typeof res.error === "string" &&
    res.error.includes("Unauthenticated.")
  ) {
    if (!isSilent) NotificationManager.error("Unauthenticated");
    return true;
  }
  if (!isError) return false;
  const errorMessage = _formatValidationErrors(res.error);
  if (isError) {
    console.error("Response.Error:", errorMessage);
    if (!isSilent) NotificationManager.error(errorMessage);
  }
  return !!errorMessage;
};
export { axiosDef, axiosAuth, isResponseError };
