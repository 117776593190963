import React from "react";
import {authenticateUser} from "../store/actions/loginAction";
import {connect} from "react-redux";
import PageHeader from "../components/PageHeader";
import axios from 'axios';
import {base_url, countryList} from "../services/const";
import {SearchResultListItem} from "../components/SearchResultListItem";
import {filterCountry, SearchSpinner} from "../services/globalFunctions";

class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchVal: this.props.match.params.searchVal,
            searchResult: [],
            showLoading: false
        };
        this.getSearchResult = this.getSearchResult.bind(this);
        this.onChangeCountryFilter = this.onChangeCountryFilter.bind(this);
    }

    componentDidMount() {
        this.getSearchResult();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.searchVal !== this.state.searchVal) {
            this.setState({searchVal: this.props.match.params.searchVal}, () => this.getSearchResult());
        }
    }

    getSearchResult() {
        this.setState({showLoading: true});
        axios.request({
            method: 'get',
            url: `${base_url}/company/search/${this.state.searchVal}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        })
            .then((ret) => {
                if (ret.status === 200) {
                    this.setState({
                        searchResult: ret.data.data.companies,
                        showLoading: false
                    });
                } else {
                    this.setState({showLoading: false});
                }
            })
            .catch((err) => {
                this.setState({showLoading: false});
                console.log(err);
            })
    }

    onChangeCountryFilter(e) {
        if(e.target.value === "") {
            this.getSearchResult();
        } else {
            this.setState({searchResult: filterCountry(this.state.searchResult, e.target.value)});
        }
    }

    render() {
        return (
            <div className="page-container SearchResultPage">
                <div className="mb-5 page-header">
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 pl-0">
                            <h4 className="font-weight-light">Search Result</h4>
                        </div>
                        <div className="col-lg-8 col-sm-12 col-sm-12 row">
                            <div className="col-4 ml-auto">
                                <select
                                    onChange={this.onChangeCountryFilter}
                                    className="form-control border-right-0 border-left-0 border-top-0 text-uppercase"
                                    style={{borderRadius: 0}}>
                                    <option value={''}>Country</option>
                                    {
                                        countryList.map((item, i) => {
                                            return <option key={i} value={item}>{item}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {/*<div className="col-4">*/}
                            {/*    <select*/}
                            {/*        className="form-control border-right-0 border-left-0 border-top-0 text-uppercase"*/}
                            {/*        style={{borderRadius: 0}}>*/}
                            {/*        <option>Industry</option>*/}
                            {/*        <option>2</option>*/}
                            {/*        <option>3</option>*/}
                            {/*        <option>4</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <SearchSpinner showLoading={this.state.showLoading} />
                    <div className={"mt-5 " + (this.state.showLoading ? "d-none" : "")}>
                        {
                            this.state.searchResult.length > 0 ?
                                this.state.searchResult.map((item, i) => {
                                    return <SearchResultListItem user={this.props.level} {...item} key={i}/>
                                }) :
                                <div className="searchresult w-100 row p-3 mb-4 position-relative col-lg-12">
                                    <div
                                        className="col-lg-12"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <div className="col-lg-2">
                                            <img
                                                style={{
                                                    height: "70px",
                                                    width: "auto",
                                                    objectFit: "contain",
                                                    maxWidth: '200px'
                                                }}
                                                src={require('../assets/images/WorldRing.png')}

                                                alt="..."
                                            />
                                        </div>
                                        <div className="col-lg-6 ml-3">
                                            No results found.
                                        </div>
                                        <div className="col-lg-2 ml-3"></div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let isLoggedIn = state.login.isLoggedIn;
    let token = state.login.token;
    let level = state.login.level;
    return {
        isLoggedIn, token, level
    };
};

const mapDispatchToProps = dispatch => ({
    authenticateUser: (data) => dispatch(authenticateUser(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
