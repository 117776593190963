import React from "react";
import { connect } from "react-redux";
import NewInviteModal from "../../components/Jumpseat/NewInviteModal";
import axios from "axios";
import { base_url } from "../../services/const";
import { NotificationManager } from "react-notifications";
import { userLevelColors } from "../../services/globalFunctions";
import moment from "moment";

class JumpseatInvt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: null,
      showInviteModal: false,
    };
    this.getTableData = this.getTableData.bind(this);
    this.onClickResendInvite = this.onClickResendInvite.bind(this);
    this.NoInviteFallback = this.NoInviteFallback.bind(this);
  }

  componentDidMount() {
    this.getTableData();
  }

  getTableData(d) {
    let data =
      this.props.level === "admin"
        ? {
            cols: ["Company", "Name", "Sent on", "Status", "AIC", "Country"],
            rows: [],
          }
        : {
            cols: ["Company", "Name", "Sent on", "Status"],
            rows: [],
          };
    if (d) {
      data.rows.push(d);
    }
    this.setState({ tableData: data });
    axios
      .request({
        method: "get",
        url: `${base_url}/agent/myinvites`,
        //url: `${base_url}/agent/myclients`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200) {
          ret.data.data.map((item) => {
            this.props.level === "admin"
              ? data.rows.push([
                  item.company_name,
                  item.fname + " " + item.lname,
                  moment(item.date).format("ll"),
                  item.status,
                  item.AIC,
                  item.country,
                  { companyId: item.company_id },
                ])
              : data.rows.push([
                  item.company_name,
                  item.fname + " " + item.lname,
                  moment(item.date).format("ll"),
                  item.status,
                  { companyId: item.company_id },
                ]);
          });
          this.setState({ tableData: data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onClickResendInvite(companyId) {
    //post('jumpseat/resend/{company_id]
    let params = new URLSearchParams();
    params.append("message", "");
    axios
      .request({
        method: "post",
        data: params,
        url: `${base_url}/jumpseat/resend/${companyId}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${this.props.token}`,
        },
      })
      .then((ret) => {
        if (ret.data.status === 200) {
          NotificationManager.success("Invitation resent", "Success");
        } else {
          NotificationManager.error("Something went wrong", "Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  NoInviteFallback() {
    return (
      <div
        className="text-center position-absolute w-100"
        style={{ marginTop: "45px", left: 0 }}
      >
        <p>No invites sent. Send your first invite.</p>
        <input
          style={{
            background: userLevelColors(this.props.level).buttonBg,
            color: userLevelColors(this.props.level).buttonFont,
            borderColor: userLevelColors(this.props.level).buttonFont,
          }}
          type="button"
          className="btn btn-wlr mt-4"
          value="Send your first invitation"
          onClick={() => this.setState({ showInviteModal: true })}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="mt-5 JumpseatInviteContainer">
        <div className="d-flex justify-content-between mb-5 align-items-center">
          <p className="m-0">
            Get an overview over the status of invitations, invite new customers
            or resend invitations.
          </p>
          <input
            style={{
              background: userLevelColors(this.props.level).buttonFont,
              color: userLevelColors(this.props.level).buttonBg,
              borderColor: userLevelColors(this.props.level).buttonFont,
            }}
            type="button"
            className="btn btn-wlr"
            value="Create a new invitation"
            onClick={() => this.setState({ showInviteModal: true })}
          />
        </div>
        <div className="box" style={{ padding: "30px", minHeight: "310px" }}>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="pl-2 m-0">Jumpseat Invitations</h5>
          </div>
          <table className={"table mt-4 table-borderless "}>
            <thead>
              <tr className="col-lg-12">
                {this.state.tableData
                  ? this.state.tableData.cols.map((item, i) => {
                      return (
                        <th
                          key={i}
                          scope="col"
                          style={
                            item === "Sent on" ? { minWidth: "125px" } : {}
                          }
                        >
                          {item}
                        </th>
                      );
                    })
                  : void 0}
              </tr>
            </thead>
            <tbody>
              {this.state.tableData
                ? this.state.tableData.rows.length > 0
                  ? this.state.tableData.rows.map((item, i) => {
                      return (
                        <tr className="col-lg-12" key={i}>
                          {item.map((el, i) => {
                            if (el === null || el === undefined) {
                              return <td key={i}></td>;
                            }
                            if (typeof el === "object") {
                              if (el) {
                                if (el.companyId && item.includes("open")) {
                                  return (
                                    <td key={i} className="text-right">
                                      <input
                                        style={{
                                          background: userLevelColors(
                                            this.props.level
                                          ).buttonBg,
                                          color: userLevelColors(
                                            this.props.level
                                          ).buttonFont,
                                          borderColor: userLevelColors(
                                            this.props.level
                                          ).buttonFont,
                                          marginRight: "22px",
                                        }}
                                        key={i}
                                        type="button"
                                        value="Resend Invitation"
                                        onClick={() =>
                                          this.onClickResendInvite(el.companyId)
                                        }
                                        className="btn btn-wlr"
                                      />
                                    </td>
                                  );
                                }
                              }
                            } else {
                              return <td key={i}>{el}</td>;
                            }
                          })}
                        </tr>
                      );
                    })
                  : this.NoInviteFallback()
                : this.NoInviteFallback()}
            </tbody>
          </table>
        </div>
        <NewInviteModal
          show={this.state.showInviteModal}
          onHide={() => this.setState({ showInviteModal: false })}
          getTableData={this.getTableData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let token = state.login.token;
  let companyIds = state.login.company_ids;
  let level = state.login.level;
  return {
    token,
    companyIds,
    level,
  };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(JumpseatInvt);
