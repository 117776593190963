import React from "react";
import styles from "./index.module.scss";
import { ThreeDotsContainer } from "./ThreeDotsContainer";

export const ExpandedContainer = ({
  row,
  isRowExpanded,
  selectedRows,
  handleSelect,
  isInvitation,
  isProductPage,
}) => {
  if (!isRowExpanded) return <></>;

  let matches = row.original.pairings;

  return matches.map((match, index) => {
    const isSelected = selectedRows.map((row) => row.id).includes(match.id);

    return (
      <tr key={`${row.id}-match-${index}`} className={styles.matchRow}>
        <td className={styles.matchCheckbox}>
          <input
            onClick={() => handleSelect(match)}
            style={{
              width: 15,
              height: 15,
              cursor: "pointer",
            }}
            type="checkbox"
            checked={isSelected}
          />
        </td>

        {row.cells.map((cell, cellIndex) => {
          return (
            <td {...cell.getCellProps()} key={cellIndex}>
              <div>{match[cell.column.id]}</div>
            </td>
          );
        })}
        <ThreeDotsContainer
          isInvitation={isInvitation}
          isProductPage={isProductPage}
          row={row}
        />
      </tr>
    );
  });
};
