import React, { useCallback } from "react";
import { debounce } from "../../utils/functions/debounce";
import { SelectInput } from "./components/SelectInput";

const searchIcon = require("../../assets/icons/searchIcon.svg");

export const FilterGroup = ({
  styles,
  selectValue,
  selectOptions,
  setSelectValue,
  searchValue,
  setSearchValueForRequst,
  setSearchValue,
  onlySearch = false,
}) => {
  const debouncedSetSearchValue = useCallback(
    debounce(setSearchValueForRequst, 300),
    [setSearchValueForRequst]
  );
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    debouncedSetSearchValue(value);
  };

  return (
    <div className={styles.filterGroup}>
      {!onlySearch && (
        <div className={styles.filterGroupBottom}>
          <SelectInput
            placeHolder="User level1"
            value={selectValue}
            options={selectOptions}
            setValue={setSelectValue}
          />
        </div>
      )}
      <div className={styles.filterGroupTop}>
        <img alt="search icon" src={searchIcon} />
        <input
          value={searchValue}
          onChange={handleSearch}
          className={styles.searchInput}
          placeholder="Search..."
        />
      </div>
      <div style={{ height: 40, display: "flex", alignItems: "center" }}></div>
    </div>
  );
};
