import React from "react";
import {authenticateUser} from "../../store/actions/loginAction";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {base_url} from "../../services/const";
import {SearchSpinner, userLevelColors} from "../../services/globalFunctions";
import Moment from 'moment';
import ConfirmationModal from "../CompanyProfileModals/ConfirmationModal";
import {NotificationManager} from "react-notifications";

class CommentsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            showLoading: false,
            commentToDelete: null,
            confirmationModal: false
        };
        this.getComments = this.getComments.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.commentBody = this.commentBody.bind(this);
        this.getUserNameInitials = this.getUserNameInitials.bind(this);
        this.onClickLike = this.onClickLike.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show !== prevProps.show && this.props.show) {
            this.getComments();
        }

        if(this.props.commentAdded !== prevProps.commentAdded && this.props.commentAdded && this.props.show) {
            this.getComments();
        }
    }

    getComments() {
        this.state.comments.length > 0 ? void 0 : this.setState({showLoading: true});
        axios.request({
            method: 'GET',
            url: `${base_url}/newsfeed/comment/${this.props.postId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(res => {
            this.setState({showLoading: false});
            if(res.data.status === 200 && res.data.data) {
                this.setState({comments: res.data.data});
            }
        }).catch(err => {

        })
    }

    onClickDelete(e) {
        this.setState({confirmationModal: true, commentToDelete: e});
    }

    deleteComment() {
        axios.request({
            method: 'DELETE',
            url: `${base_url}/newsfeed/comment/${this.state.commentToDelete}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(res => {
            if(res.data.status === 200) {
                this.setState({confirmationModal: false});
                NotificationManager.success('Comment deleted.', 'Success');
                this.props.getNewsfeed();
                this.getComments();
            } else {
                NotificationManager.error('Something went wrong. Try again.', 'Error');
            }
        }).catch(err => {

        })
    }

    commentBody(content) {
        let contentC = content;
        if(new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(contentC)) {
            let urlRE= new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+");
            let url = contentC.match(urlRE)[0];
            if(!(/^http:\/\//.test(url)) && !(/^https:\/\//.test(url))) {
                let urlWithHttp = "http://" + url;
                contentC = contentC.replace(url, `<a href="${urlWithHttp}" target="_blank">${url}</a>`)
            } else {
                contentC = contentC.replace(url, `<a href="${url}" target="_blank">${url}</a>`)
            }
        }
        return contentC;
    }

    getUserNameInitials(string) {
        let names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    onClickLike(commendId) {
        axios.request({
            method: 'PUT',
            url: `${base_url}/newsfeed/comment/like/${commendId}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(res => {
            if(res.data.status === 200) {
                this.getComments();
            }
        }).catch(err => {

        })
    }

    render() {
        return (
            <div className="mt-2 CommentWrapper position-relative">
                <SearchSpinner level={this.props.level} showLoading={this.state.showLoading} />
                {
                    this.state.comments.length > 0 ? this.state.comments.map((item, i) => {
                        return (
                        <div key={i} className="px-4 py-3 position-relative comment-item row col-lg-12 mx-0">
                            <div className="col-lg-1 px-0">
                                <Link to={`/company-profile/${item.user.company_id}`}><div className={"name-initials " + (item.user.company_logo.data ? "d-none" : "")}>
                                    {this.getUserNameInitials(item.user.company)}
                                </div>
                                <img className={item.user.company_logo.data ? "" : "d-none"} style={{width: '80px'}} src={`data:image/jpeg;base64,${item.user.company_logo.data}`} alt={'...'} /></Link>
                            </div>
                            <div className="col-lg-10 ml-2 mt-2">
                                <h5><Link style={{color: 'black'}} to={`/company-profile/${item.user.company_id}`}>{item.user.company}</Link></h5>
                                <p className="mb-0"><span dangerouslySetInnerHTML={{__html: this.commentBody(item.content)}} /></p>
                            </div>
                            <p className="mt-3 mb-0">
                                <small>{Moment(item.created).format('LLL')}</small>
                                <small style={{cursor: 'pointer'}} onClick={this.onClickLike.bind(null, item.comment_id)}>
                                    <img src={require('../../assets/icons/like.png')} alt='...' style={{width: '25px', marginTop: '-10px'}} className="ml-2" /><span>{item.likes}</span>
                                </small>
                                <small className={item.user.email === this.props.userEmail ? "" : "d-none"}>
                                    <a className="ml-3 " onClick={this.onClickDelete.bind(null, item.comment_id)} style={{textDecoration: 'underline', color: '#4dabf7', cursor: 'pointer'}}>
                                        Delete
                                    </a>
                                </small>
                            </p>
                        </div>
                        )
                    }) : ""
                }
                <ConfirmationModal
                    show={this.state.confirmationModal}
                    onHide={() => this.setState({confirmationModal: false})}
                    itemToDelete={"this comment"}
                    onClickDelete={this.deleteComment}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let level = state.login.level;
    let userEmail = state.login.email;
    return {
        token, level, userEmail
    };
};

const mapDispatchToProps = (dispatch) => ({
    authenticateUser: (data) => dispatch(authenticateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsList);
