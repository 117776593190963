import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {ButtonSpinner, SearchSpinner, toBase64, uploadImage, userLevelColors} from "../services/globalFunctions";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage} from "@fortawesome/free-solid-svg-icons";
import {base_url, countryList} from "../services/const";
import {NotificationManager} from "react-notifications";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import axios from "axios";
import {stateFromHTML} from "draft-js-import-html";
const INITIAL_STATE = {
    logoThumbnail: null,
    showFileError: false,
    companyLogo: null,
    editorState: EditorState.createEmpty(),
    heroImage: null,
    heroImageId: null,
    companyName: '',
    postTitle: '',
    companyLogoId: null,
    country: '',
    city: '',
    postText: '',
    isLoading: false,
    fetchingData: false
};

class NewBlogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = INITIAL_STATE;
        this.handleCompanyProfileUpload = this.handleCompanyProfileUpload.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
        this.handleHeroImageUpload = this.handleHeroImageUpload.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.getPostToEdit = this.getPostToEdit.bind(this);
        this.updatePost = this.updatePost.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.show !== prevProps.show && this.props.show && this.props.blogToEdit) {
            this.setState({fetchingData: true});
            this.getPostToEdit(this.props.blogToEdit);
        }

        if(this.props.show !== prevProps.show && !this.props.show) {
            this.setState(INITIAL_STATE);
        }
    }

    async handleCompanyProfileUpload(e) {
        let file = e.target.files[0];
        if(((file.size/1024)/1024).toFixed(4) > 5) {
            NotificationManager.error('File size too large', 'Error');
        } else {
            let logo = {
                mime: e.target.files[0].type,
                filename: e.target.files[0].name
            };
            await toBase64(e.target.files[0]).then(res => {
                logo.data = res;
                this.setState({logoThumbnail: logo, showFileError: false});
                // this.props.onChangeHeroImage(logo);
            });
            let formData = new FormData();
            formData.append("image", file);
            let image_id = await uploadImage(formData, this.props.token);
            this.setState({companyLogo: file, showFileError: false, companyLogoId: image_id});
        }
    }

    onChangeEditor(editorState) {
        this.setState({editorState}, () => {
            let htmlDesc = stateToHTML(editorState.getCurrentContent());
            this.setState({postText: htmlDesc});
            // this.props.onChange(htmlDesc);
        });
    }

    async handleHeroImageUpload(e) {
        let file = e.target.files[0];
        if(((e.target.files[0].size/1024)/1024).toFixed(4) > 5) {
            NotificationManager.error('File size too large', 'Error');
        } else {
            this.setState({image: e.target.files[0]});
            let logo = {
                mime: e.target.files[0].type,
                filename: e.target.files[0].name
            };
            await toBase64(e.target.files[0]).then(res => {
                logo.data = res;
                console.log(logo.data);
                this.setState({heroImage: logo, showFileError: false});
                // this.props.onChangeHeroImage(logo);
            });
            let formData = new FormData();
            formData.append("image", file);
            let image_id = await uploadImage(formData, this.props.token);
            this.setState({heroImageId: image_id, showFileError: false});
        }
    }

    onClickSave() {
        this.setState({isLoading: true});
        let formData = new URLSearchParams();
        formData.append('hero_image_id', this.state.heroImageId);
        formData.append('company_logo_id', this.state.companyLogoId);
        formData.append('company_name', this.state.companyName);
        formData.append('company_country', this.state.country);
        formData.append('company_city', this.state.city);
        formData.append('title', this.state.postTitle);
        formData.append('text', this.state.postText);
        axios.request({
            method: 'put',
            url: `${base_url}/newsfeed`,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((ret) => {
            this.setState({isLoading: false});
            if(ret.data.status === 200) {
                NotificationManager.success('Blog created', 'Success');
                this.props.getUpdatedTableData();
                this.props.onHide();
            } else {
                if(Object.keys(ret.data.errors).length > 0) {
                    Object.keys(ret.data.errors).map((item) => {
                        NotificationManager.error(ret.data.errors[item], 'Error');
                    })
                }
            }
        })
    }

    getPostToEdit(id) {
        axios.request({
            method: "GET",
            url: `${base_url}/newsfeed`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((ret) => {
            this.setState({fetchingData: false});
            if(ret.data.status === 200) {
                ret.data.data.map((item) => {
                    if(item.newsfeed_id === id) {
                        item.company_logo.data = 'data:image/png;base64, ' + item.company_logo.data;
                        item.hero_image.data = 'data:image/png;base64, ' + item.hero_image.data;
                        const stateFromH = stateFromHTML(item.text);
                        this.setState({
                            logoThumbnail: item.company_logo,
                            companyName: item.company_name,
                            country: item.company_country,
                            postText: item.text,
                            postTitle: item.title,
                            city: item.company_city,
                            heroImage: item.hero_image,
                            editorState: EditorState.createWithContent(stateFromH)
                        });
                    }
                })
            }
        })
    }

    updatePost() {
        this.setState({isLoading: true});
        let formData = new URLSearchParams();
        this.state.heroImageId ? formData.append('hero_image_id', this.state.heroImageId) : void 0;
        this.state.companyLogoId ? formData.append('company_logo_id', this.state.companyLogoId) : void 0;
        formData.append('company_name', this.state.companyName);
        formData.append('company_country', this.state.country);
        formData.append('company_city', this.state.city);
        formData.append('title', this.state.postTitle);
        formData.append('text', this.state.postText);
        axios.request({
            method: 'post',
            url: `${base_url}/newsfeed/${this.props.blogToEdit}`,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then((ret) => {
            this.setState({isLoading: false});
            if(ret.data.status === 200) {
                NotificationManager.success('Blog updated', 'Success');
                this.props.getUpdatedTableData();
                this.props.onHide();
            } else {
                if(Object.keys(ret.data.errors).length > 0) {
                    Object.keys(ret.data.errors).map((item) => {
                        NotificationManager.error(ret.data.errors[item], 'Error');
                    })
                }
            }
        })
    }

    render() {
        const options = {
            options: ['inline', 'blockType', 'list', 'history'],
            inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']
            }
        };
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className='NewBlogModal'>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>New Blog Post</h3>
                </Modal.Header>
                <Modal.Body>
                    <SearchSpinner showLoading={this.state.fetchingData} level={this.props.level} />
                    <div className={(this.state.fetchingData ? "d-none" : "")}>
                    <div className={"row "}>

                        <div className={"col-lg-4 "} >
                            <div className="file-upload-container">
                                <div className="file-upload-content">
                                    <div>
                                        {
                                            this.state.logoThumbnail ? <img style={{height: '65px'}} src={`${this.state.logoThumbnail.data}`} alt={'...'} /> :
                                                <FontAwesomeIcon size={'3x'} icon={(faImage)}/>
                                        }
                                    </div>
                                    <p className="title pt-3 mb-1">Drag & Drop</p>
                                    <p className="sub-title mb-1">Your company logo goes here.</p>
                                    <p className="sub-title mb-1">
                                        150x150px or 300x75px
                                    </p>
                                    <p className="sub-title mb-1">.jpg or .png</p>
                                    <p className="sub-title mb-1">max 5mb</p>
                                    <br/>
                                </div>
                                <input
                                    style={this.state.logoThumbnail ? {padding: '200px 0px 12px 23%'} : {padding: '175px 0px 12px 23%'}}
                                    type="file"
                                    id="file-upload"
                                    className={
                                        "choose-file-input " +
                                        (this.state.companyLogo ? " " : "file-selected ")
                                    }
                                    onChange={this.handleCompanyProfileUpload}
                                    accept=".png, .jpeg, .jpg"
                                />
                            </div>
                        </div>
                        <div className="details-form col-lg-8">
                            <div className="col-lg-12 pl-0 mb-4"><h6>Post Details</h6></div>
                            <form>
                                <div className="row">
                                    <div className="form-group col-lg-12">
                                        <input
                                            type="text"
                                            defaultValue={this.state.companyName}
                                            className={"form-control " + (userLevelColors(this.props.level).className)}
                                            onChange={(e) => this.setState({companyName: e.target.value})}
                                            placeholder="Company Name"
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <input
                                            type="text"
                                            defaultValue={this.state.city}
                                            className={"form-control " + (userLevelColors(this.props.level).className)}
                                            onChange={(e) => this.setState({city: e.target.value})}
                                            placeholder="City"
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <select
                                            value={this.state.country}
                                            className={"form-control " + (userLevelColors(this.props.level).className)}
                                            onChange={(e) => this.setState({country: e.target.value})}
                                            >
                                            <option value={null}>Country</option>
                                            {
                                                countryList.map((item, i) => {
                                                    return <option key={i} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr/>
                    <div className="file-upload-container">
                        <div className="file-upload-content">
                            <div>
                                {
                                    this.state.heroImage ? <img style={{height: '65px'}} src={`${this.state.heroImage.data}`} alt={'...'} /> :
                                        <FontAwesomeIcon size={'3x'} icon={(faImage)}/>
                                }
                            </div>
                            <h4 className="title pt-3 mb-1">Drag & Drop</h4>
                            <h6 className="sub-title mb-3">
                                Post hero image goes here
                            </h6>
                            <br/>
                            <small className={'file-formats mt-3 mb-0 '}>
                                {this.state.heroImage ? this.state.heroImage.filename : "(.PNG or .JPG)"}
                            </small>
                            &nbsp;
                            <small className={'text-danger ' + (this.state.showFileError ? '' : 'd-none')}>
                                Select a file to import
                            </small>
                        </div>
                        <input
                            type="file"
                            id="file-upload"
                            className={'choose-file-input ' + (this.state.heroImage ? '' : 'file-selected')}
                            onChange={this.handleHeroImageUpload}
                            accept=".png, .jpeg, .jpg"
                        />
                        <div className="choose-file-btn">
                            <button className="btn inverse-btn-secondary">Choose File</button>
                        </div>
                    </div>
                    <div className="form-group my-4">
                        <input
                            defaultValue={this.state.postTitle}
                            type="text"
                            className={"form-control " + (userLevelColors(this.props.level).className)}
                            onChange={(e) => this.setState({postTitle: e.target.value})}
                            placeholder="Post title"
                        />
                    </div>
                    <div className="form-group" style={{border: '1px solid #ebebeb'}}>
                        <Editor
                            editorState={this.state.editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.onChangeEditor}
                            toolbar={options}
                        />
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide} style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} variant="dark" className={"btn-wlr"}>
                        Cancel
                    </Button>
                    <Button disabled={this.state.isLoading} variant="primary" style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}} className={"ml-2 btn-wlr"} onClick={this.props.blogToEdit ? this.updatePost : this.onClickSave} >
                        {
                            this.state.isLoading ? <ButtonSpinner showLoading={this.state.isLoading} /> : this.props.blogToEdit ? "Update" : "Save"
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    let level = state.login.level;
    return {
        token, companyIds, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NewBlogModal);
