import React from "react";

export const DemoStep6 = (props) => {
    return (
        <div className="DemoStep4 text-center DemoStep">
            <img src={require('../../assets/welcomeModal/step_6.png')} style={{width: '450px'}} alt={'...'} />
            <p className="mt-4">Use our HS Code database to correctly identify your product category. You may add a title, description, target group and specifications (i.e. ISO Standards) to enhance the visibilty of your product. You may upload a pdf, such as a brochure or specsheet to further help potential matches in finding you and your great products and help the network in finding your perfect match. <br /><br /> You may upload a pdf, such as brochure or spreadsheet as well.</p>
        </div>
    )
};
