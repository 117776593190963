import {
  CLEAR_PROPS,
  LOGIN_AUTHENTICATION,
  UPDATE_NEW_MATCHES_COUNT,
  UPDATE_USER_DATA,
  UPDATE_USER_LEVEL,
} from "../actions/actionTypes";
import AuthLoader from "../../components/AuthLoader";
import { filePathToGlobal } from "../../utils/file";

const INITIAL_STATE = {
  isLoggedIn: false,
  user_id: "",
  email: "",
  fname: "",
  lname: "",
  level: "",
  token: "",
  company_ids: "",
  group: "",
  ag_contact: {},
  company_logo: [],
  files: [],
  new_matches: 0,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_AUTHENTICATION:
    case UPDATE_USER_DATA:
      const payload = action.payload;
      return Object.assign({}, state, {
        isLoggedIn: !!action.payload.id,
        email: action.payload.email,
        fname: action.payload.first_name,
        lname: action.payload.last_name,
        level: payload.user_level ? payload.user_level.code : null,
        token: action.payload.token,
        user_id: action.payload.id,
        company_ids: Array.isArray(payload.companies)
          ? payload.companies.map((c) => c.id)
          : [], //action.payload.company_ids,
        group: payload.role ? payload.role.code : "", //action.payload.group,
        ag_contact:
          action.payload.role.code === "customer" && action.payload.inviter
            ? {
                id: action.payload.inviter.id,
                phone: action.payload.inviter.phone,
                email: action.payload.inviter.email,
                name: action.payload.inviter.first_name,
                logo: action.payload.inviter.companies.length ? action.payload.inviter.companies[0].wp_logo : "",
              }
            : {}, //action.payload.ag_contact,
        company_logos: [], // action.payload.company_logos,
        company_logo:
          action.payload.companies[0].files.length &&
          action.payload.companies[0].files.find(
            (f) => f.label === "company_logo"
          )
            ? action.payload.companies[0].files.find(
                (f) => f.label === "company_logo"
              ).path
            : "",
        first_login: action.payload.first_login,
        files: Array.isArray(action.payload.files)
          ? action.payload.files.map((f) => filePathToGlobal(f))
          : [],
      });
    case CLEAR_PROPS:
      new AuthLoader().logout();
      return Object.assign({}, state, {
        isLoggedIn: false,
        email: "",
        fname: "",
        lname: "",
        level: "",
        token: "",
        user_id: "",
        company_ids: "",
        group: "",
        ag_contact: {},
        company_logo: [],
        files: [],
      });
    case UPDATE_USER_LEVEL:
      return Object.assign({}, state, {
        level: action.payload.level,
        fname: action.payload.fname,
        lname: action.payload.lname,
        first_login: action.payload.firstLogin,
      });
    case UPDATE_NEW_MATCHES_COUNT:
      const matchesCount = action.payload;
      return Object.assign({}, state, { new_matches: matchesCount });
    default:
      return state;
  }
};
