import React from 'react';
import {SearchSpinner, userLevelColors} from "../services/globalFunctions";
import {connect} from "react-redux";
import moment from "moment";
import NewBlogModal from "./NewBlogModal";
import axios from "axios";
import {base_url} from "../services/const";
import ConfirmationModal from "../components/CompanyProfileModals/ConfirmationModal";
import {NotificationManager} from "react-notifications";

class Blogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: null,
            newBlogModal: false,
            blogToEdit: null,
            itemToDel: null,
            showConfirmationModal: false,
            titleToDelete: '',
            isFetching: false
        };
        this.showNewBlogModal = this.showNewBlogModal.bind(this);
        this.getData = this.getData.bind(this);
        this.onClickEditPost = this.onClickEditPost.bind(this);
        this.getTableData = this.getTableData.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    componentDidMount() {
        this.getTableData();
    }

    async getTableData(d) {
        this.setState({isFetching: true});
        let data = {
            cols: ['Post Title', 'Company', 'Posted On'],
            rows: [
            ]
        };
        if(d) {
            data.rows.push(d);
        }
        let tableData = await this.getData();
        if(tableData.length > 0) {
            tableData.map((item) => {
                data.rows.push([item.title, item.company_name, moment(item.created).format('LLL'), {postId: item.newsfeed_id}])
            })
        } else {
            console.log("hit");
        }
        this.setState({tableData: data});
    }

    getData() {
        return new Promise((resolve, reject) => {
            axios.request({
                method: 'get',
                url: `${base_url}/newsfeed`,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Bearer ${this.props.token}`
                }
            }).then((ret) => {
                this.setState({isFetching: false});
                if(ret.data.status === 200) {
                    resolve(ret.data.data);
                } else {
                    resolve([]);
                }
            })
        })
    }

    showNewBlogModal() {
        this.setState({newBlogModal: true, blogToEdit: null});
    }

    onClickEditPost(id) {
        this.setState({blogToEdit: id, newBlogModal: true});
    }

    onClickDelete(id, title) {
        this.setState({itemToDel: id, showConfirmationModal: true, titleToDelete: title})
    }

    deleteItem() {
        axios.request({
            method: "delete",
            url: `${base_url}/newsfeed/${this.state.itemToDel}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Bearer ${this.props.token}`
            }
        }).then(async (ret)=> {
            if(ret.data.status) {
                NotificationManager.success('Blog post deleted', 'Success');
                this.setState({showConfirmationModal: false});
                await this.getTableData();
            } else {
                NotificationManager.error('Something went wrong. Try again.', 'Error');
            }
        })
    }

    render() {
        return (
            <div className="page-container BlogContainer">
                <div className={"page-header mb-5 "}></div>
                <div className="container">
                    <div className="col-lg-12 row" style={{marginBottom: "30px", padding: "30px"}}>
                        <div className="col-lg-6">
                            <h4>Blog Posts</h4>
                            <p>
                                Write a new blog post for the WorldRing newsfeed
                            </p>
                        </div>
                        <div className="col-lg-6 text-right">
                            <input
                                style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                                type="button"
                                className="btn btn-wlr mt-4"
                                value="Create a new blog post"
                                onClick={this.showNewBlogModal}
                            />
                        </div>
                    </div>
                    <div className="box" style={{padding: "30px", minHeight: '300px'}}>
                        <h5 className="pl-2">Blog Posts</h5>
                        <SearchSpinner showLoading={this.state.isFetching} level={this.props.level} />
                        <table className={"table mt-4 table-borderless " + (this.state.isFetching ? "d-none" : "")}>
                            <thead>
                            <tr className="col-lg-12">
                                {this.state.tableData
                                    ? this.state.tableData.cols.map((item, i) => {
                                        return (
                                            <th key={i} scope="col">
                                                {item}
                                            </th>
                                        );
                                    })
                                    : void 0}
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.tableData
                                ? this.state.tableData.rows.length > 0 ? this.state.tableData.rows.map((item, i) => {
                                        return (
                                            <tr className="col-lg-12" key={i}>
                                                {item.map((el, i) => {
                                                    if(el === null || el === undefined) {
                                                        return <td key={i}>
                                                        </td>
                                                    }
                                                    if(typeof el === 'object') {
                                                        if(el) {
                                                            if(el.postId) {
                                                                return <td key={i} className="text-right"><input style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont, marginRight: '22px'}} key={i} type="button" value="Edit Post" onClick={() => this.onClickEditPost(el.postId)} className="btn btn-wlr" /><input  key={i+1} type="button" value="Delete Post" onClick={() => this.onClickDelete(el.postId, item[0])} className="btn btn-danger" /></td>
                                                            }
                                                        }
                                                    } else  {
                                                        return (
                                                            <td key={i}>
                                                                {el}
                                                            </td>
                                                        );
                                                    }

                                                })}
                                            </tr>
                                        );
                                    })
                                    :
                                    <p className="text-center mt-5 position-absolute" style={{left: '50%', transform: 'translate(-70%)'}}>
                                        No blog post added yet.<br />
                                        <input
                                            style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                                            type="button"
                                            className="btn btn-wlr mt-4"
                                            value="Create your first blog post"
                                            onClick={this.showNewBlogModal}
                                        />
                                    </p>
                                :
                                <p className="text-center mt-5 position-absolute" style={{left: '50%', transform: 'translate(-70%)'}}>
                                    No blog post added yet.
                                    <input
                                        style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, borderColor: userLevelColors(this.props.level).buttonFont}}
                                        type="button"
                                        className="btn btn-wlr mt-4"
                                        value="Create your first blog post"
                                        onClick={this.showNewBlogModal}
                                    />
                                </p>
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <NewBlogModal show={this.state.newBlogModal} onHide={() => this.setState({newBlogModal: false, blogToEdit: null})} blogToEdit={this.state.blogToEdit} getUpdatedTableData={this.getTableData} />
                <ConfirmationModal
                    show={this.state.showConfirmationModal}
                    onHide={() => this.setState({showConfirmationModal: false})}
                    onClickDelete={this.deleteItem}
                    itemToDelete={this.state.titleToDelete}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let token = state.login.token;
    let companyIds = state.login.company_ids;
    let level = state.login.level;
    return {
        token, companyIds, level
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
