import React from "react";
import {Button, Modal} from "react-bootstrap";
import {userLevelColors} from "../../services/globalFunctions";

class PaypalModalNotification extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className='HeroImageModal'>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}}>Subscription</h3>
                </Modal.Header>
                <Modal.Body>
                    By choosing the PayPal payment option, your account will <strong><u>NOT</u></strong> be charged till the trial period expires. <br />
                    After the trial period is expired, your account will be charged and you will receive a proper invoice with all related details.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, border: `1px solid ${userLevelColors(this.props.level).buttonFont}`}} onClick={this.props.onHide}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default PaypalModalNotification;
