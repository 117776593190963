import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faImage} from "@fortawesome/free-solid-svg-icons";
import {userLevelColors} from "../../services/globalFunctions";
import {connect} from "react-redux";

class ConfirmationModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} size={'lg'} onHide={this.props.onHide} className='HeroImageModal' style={{marginTop:'18%'}}>
                <Modal.Header closeButton style={{background: userLevelColors(this.props.level).bg}}>
                    <h3 style={{color: userLevelColors(this.props.level).font}} className="invisible">Warning</h3>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete <strong>{this.props.itemToDelete}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.onClickDelete}>
                        Yes
                    </Button>
                    <Button variant="dark" style={{background: userLevelColors(this.props.level).buttonBg, color: userLevelColors(this.props.level).buttonFont, border: `1px solid ${userLevelColors(this.props.level).buttonFont}`}} onClick={this.props.onHide}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    let level = state.login.level;
    return {
        level
    }
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);

