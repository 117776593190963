import React from 'react';

class Impressum extends React.Component {
    render() {
        return (
            <div className="privacy-policy-container page-container">
                <div className="page-header mb-5"></div>
                <div className="container">
                    Impressum <br /><br />

                    Angaben gemäß § 5 TMG:<br /><br />

                    Westring international trading &amp; consulting GmbH<br />
                    Herrenlandpark 9<br />
                    41379 Brüggen<br /><br />

                    Vertreten durch:<br />
                    René Lankes<br /><br />

                    Kontakt:<br />
                    Telefon: 021638881460<br />
                    Telefax: 021638881460<br />
                    E-Mail: info[at]westringtc.de<br /><br />

                    Registereintrag:<br />
                    Eintragung im Handelsregister.<br />
                    Registergericht:Krefeld<br />
                    Registernummer: 14056<br /><br />

                    Umsatzsteuer-ID:<br />
                    Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
                    DE285703537<br />

                    Quellenangaben für die verwendeten Bilder und Grafiken:<br />
                    http://www.shutterstock.de<br />

                    Quelle: http://www.e-recht24.de<br /><br />
                    <h4 className="mt-5">Website Terms and Conditions of Use</h4>
                    <h4>1. Terms</h4>
                    By accessing this Website, accessible from <span class="highlight preview_website_url">Website.com</span>, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
                    <h4>2. Use License</h4>
                    Permission is granted to temporarily download one copy of the materials on <span class="highlight preview_company_name">Company Name</span>'s Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                    <ul>
                        <li>modify or copy the materials;</li>
                        <li>use the materials for any commercial purpose or for any public display;</li>
                        <li>attempt to reverse engineer any software contained on <span class="highlight preview_company_name">Company Name</span>'s Website;</li>
                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                    </ul>
                    This will let <span class="highlight preview_company_name">Company Name</span> to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.
                    <h4>3. Disclaimer</h4>
                    All the materials on <span class="highlight preview_company_name">Company Name</span>'s Website are provided “as is”. <span class="highlight preview_company_name">Company Name</span> makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, <span class="highlight preview_company_name">Company Name</span> does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
                    <h4>4. Limitations</h4>
                    <span class="highlight preview_company_name">Company Name</span> or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on <span class="highlight preview_company_name">Company Name</span>'s Website, even if <span class="highlight preview_company_name">Company Name</span> or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
                    <h4>5. Revisions and Errata</h4>
                    The materials appearing on <span class="highlight preview_company_name">Company Name</span>'s Website may include technical, typographical, or photographic errors. <span class="highlight preview_company_name">Company Name</span> will not promise that any of the materials in this Website are accurate, complete, or current. <span class="highlight preview_company_name">Company Name</span> may change the materials contained on its Website at any time without notice. <span class="highlight preview_company_name">Company Name</span> does not make any commitment to update the materials.
                    <h4>6. Links</h4>
                    <span class="highlight preview_company_name">Company Name</span> has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by <span class="highlight preview_company_name">Company Name</span> of the site. The use of any linked website is at the user's own risk.
                    <h4>7. Site Terms of Use Modifications</h4>
                    <span class="highlight preview_company_name">Company Name</span> may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
                    <h4><strong>8. Governing Law</strong></h4>
                    Any claim related to <span class="highlight preview_company_name">Company Name</span>'s Website shall be governed by the laws of <span class="highlight preview_country">Country</span>without regards to its conflict of law provisions.

                    <strong>Privacy policy</strong>

                    <strong>1. Data protection</strong>

                    We have written this data protection explanation (version 01.02.2020-311148603) to explain to you according to the default of the data protection-basic order (EU) in 2016/679 which information we collect as we use data and which possibilities you have as a visitor of this web page.

                    <strong>2. Automatic data storage</strong>

                    If you visit nowadays web pages, certain information is automatically provided and stored, thus also on this web page.

                    If you visit our web page as well as right now, our web server stores (computer on him this web page is stored) automatically data like

                    the address (URL) of the called web page
                    Browser and browser version
                    the used operating system
                    the address (URL) of the before visited side (Referrer URL)
                    the host name and the IP address of the device from which is accessed
                    Date and time
                    in files (web server log file).

                    As a rule web server log files are stored two weeks and are extinguished afterwards automatically. We do not transmit these data, nevertheless, can not exclude that these data are seen by the presentation by illegal behaviour.
                    The legal basis consists in the fact according to the article 6 paragraph 1 and the following DSGVO (legality of the processing) that legitimate interest insists on allowing the perfect company of this web page by grasping of web server log files.

                    <strong>3. Storage of personal data</strong>

                    The personal data which you transmit to us on this website electronically as for example name, e-mail address, address or other personal information within the scope of the transmission of a form or comments in the blog, are used by us together with the time and the IP address only for the purpose given in each case, are certainly kept and not transmitted into three parts.

                    We use your personal data therefore only for the communication with those visitors who wish contact expressly and for the winding up of the services offered on this web page and products. We do not transmit your personal data without approval, nevertheless, can not exclude that these data are seen by the presentation by illegal behaviour.

                    If you send us personal data by e-mail – therefore off this web page – we can guarantee no sure transference and the protection of your data. We recommend you never to transmit confidential data uncoded by e-mail.

                    The legal basis consists in the fact according to the article 6 paragraph 1 a DSGVO (legality of the processing) that you give us the approval of the processing of the data given by you. They can revoke this approval any time – shapeless e-mail is sufficient, you find our contact data in the imprint.

                    <strong>4. Rights according to data protection reason order</strong>

                    The following rights are entitled according to the regulations of the DSGVO basically to you:

                    Surely on correction (article 16 DSGVO)
                    Surely on deletion („Surely on Vergessenwerden“) (article 17 DSGVO)
                    Surely on restriction of the processing (article 18 DSGVO)
                    Surely on notification – obligation to notify in connection with the correction or deletion of personal data or the restriction of the processing (article 19 DSGVO)
                    Surely on data transferability (article 20 DSGVO)
                    Contradiction right (article 21 DSGVO)
                    Surely, not exclusively on an automated processing — including Profiling — to be subjected being based decision (article 22 DSGVO)
                    If you think that the processing of your data offends against the data protection right or, otherwise, your data protection-juridical claims have been injured in a manner, you can turn to the federal representative for the data protection and the freedom of information (BfDI).

                    <strong>5. TLS encoding with https</strong>

                    We use to transfer https around data listening-certainly on the Internet (data protection by technology creation article 25 paragraph 1 DSGVO). By the application of TLS (transport Layer Security), to an encoding protocol to the sure data transfer on the Internet we can guarantee the protection of confidential data. They recognise the use of this security of the data transfer by the small castle symbol on the top left in the browser and the use of the pattern https (instead of http) as a part of our Internet address.
                </div>
            </div>
        )
    }
}

export default Impressum;
